import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserServiceService } from 'src/app/_services/users/user-service.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  @Input() user: any;
  @Output() close = new EventEmitter<void>();

  selectedOption: string = 'Datos del usuario';
  selectedRole: string;
  selectedContrato: string;
  isModalVisible: boolean = false;

  constructor(private userService: UserServiceService) {
    this.selectedRole = this.roles[0];
    this.selectedContrato = this.contratos[0];
  }

 


  utilidades = [
    { nombre: 'Utilidades envios', utilidad: '', porcentaje: '' },
    { nombre: 'Utilidades empaques', utilidad: '', porcentaje: '' },
    { nombre: 'Utilidades servicios', utilidad: '', porcentaje: '' },
    { nombre: 'Utilidades recargas', utilidad: '', porcentaje: '' },
    { nombre: 'Utilidades reparto multimarca', utilidad: '', porcentaje: '' }
  ];

  roles = [
    'Administradorlocker', 'Administrativo', 'Almighty', 'Basic', 'Cajero', 'Check',
    'Cliente corporativo', 'Cliente licenciatario', 'Comercial ventas', 'Developer',
    'Inversionista', 'Lite', 'Litecliente licenciatario', 'Locker', 'Operativo', 'Plus',
    'Prueba de roles'
  ];

  contratos = [
    'Tradicional - contrato tipo 1', 'Intermediato - contrato tipo 2'
  ];

  users: { name: string }[] = []; // Aquí puedes poner la lista de usuarios registrados
  searchText: string = '';
  usuarios = {
    image: null,
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    postalCode: ''
  };
   

  ngOnInit(): void {
    this.getUserPercentageForEnvios();
  }
  selectOption(option: string): void {
    this.selectedOption = option;
  }

  onSaveChanges(): void {
    console.log('Changes saved', this.user);
    this.close.emit();
  }

  onSearch(): void {

  }

  get filteredUsers(): { name: string }[] {
    return this.users.filter(user => user.name.toLowerCase().includes(this.searchText.toLowerCase()));
  }
  showModal(): void {
    this.isModalVisible = true;
  }

  hideModal(): void {
    this.isModalVisible = false;
  }

  confirmContract(): void {
    
    this.hideModal();
  }


  getUserPercentageForEnvios(): void {
    this.userService.getUserPercentage().subscribe(
      response => {
        console.log('si se pudo padre:', response); 
  
        if (response && response.data && response.data.$numberDecimal !== undefined) {
          const envios = this.utilidades.find(u => u.nombre === 'Utilidades envios');
          if (envios) {
            envios.porcentaje = response.data.$numberDecimal; 
          }
        } else {
          console.warn('No se encontró el campo "porcentaje" en la respuesta', response);
        }
      },
      error => {
        console.error('Error al obtener el porcentaje del usuario:', error);
      }
    );
  }
  
  
  
}
