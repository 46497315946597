<div class="flex flex-col items-center justify-center h-screen bg-white">
    <img src="../../../assets/images/LogotipoA.png" alt="Logotipo" class="mb-8" style="max-width: 530.09px; height: auto;" />
    <h1 class="text-[111px] font-extrabold text-dagblue mb-6">¡BIENVENIDO!</h1>
    <p class="text-center text-base text-black mb-4">
        Somos una empresa mexicana que ofrece soluciones innovadoras<br/>
        para mensajería local y envíos nacionales e internacionales,<br/>
        brindando eficiencia y valor a nuestros clientes.
    </p>
    <button [routerLink]="['/login']" class="px-8 py-4 w-[260px] bg-dagpk shadow-lg uppercase text-white font-bold rounded-full hover:bg-[#af3f23] duration-200">
        Entrar
    </button>
</div>

