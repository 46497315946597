import { Component } from '@angular/core';

interface Locker {
  id: number;
  lockerNumber: number;
  lockerSize: string;
  lockerPrice: number;
}

interface NewLocker {
  id: number;
  nombre: string;
  apellido: string;
  telefono: string;
  correo: string;
}

@Component({
  selector: 'app-admin-lockers',
  templateUrl: './admin-lockers.component.html',
  styleUrls: ['./admin-lockers.component.scss']
})
export class AdminLockersComponent {
  lockers: Locker[] = [
    { id: 1, lockerNumber: 1, lockerSize: 'S', lockerPrice: 10 },
    // Add more lockers here
  ];

  showAddLocker = false;
  newLocker: NewLocker = { id: 0, nombre: '', apellido: '', telefono: '', correo: '' };

  openAddLocker() {
    this.newLocker = { id: 0, nombre: '', apellido: '', telefono: '', correo: '' };
    this.showAddLocker = true;
  }

  closeAddLocker() {
    this.showAddLocker = false;
  }

  addLocker() {
    // Implement the logic to add the new locker
    console.log('New locker:', this.newLocker);
    // After adding, close the modal
    this.closeAddLocker();
  }
}