<div class="w-full">
    <div class="p-2">        
        <div class="flex flex-col justify-between items-start py-2 overflow-x-auto">
            <div class="flex py-4 gap-2 hover:cursor-pointer hover:bg-slate-300 rounded-lg">                                
                <div class="min-w-[230px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase"> # </span>
                    <p class="text-sm"> 
                        <span class="font-bold uppercase"> Dagpacket Code</span>: 1713291764790
                        <span class="font-bold"> Track N°</span>: 051232830                    
                        <span class="font-bold">Comp. ID</span>: 051232830 </p>
                </div>
                <div class="min-w-[70px] flex justify-center items-center gap-2">
                    <img src="../../../assets/images/Icon Dagpacket.png" alt="" class="w-16 object-fit">
                </div>
                <div class="min-w-[220px] flex flex-col">
                    <span class="text-dagblue font-bold uppercase">Fecha</span>
                    <p>Enero 24, 2024 a las 2:40pm</p>
                </div>
                <div class="min-w-[300px] flex justify-center items-center gap-3">
                    <img src="https://imgs.search.brave.com/z-3_VMVbo5k1c4nYN-MGQSo7jUle-HYqNAS5ED1Soag/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9kMnFw/MHNpb3RsYTc0Ni5j/bG91ZGZyb250Lm5l/dC9pbWcvdXNlLWNh/c2VzL3Byb2ZpbGUt/cGljdHVyZS90ZW1w/bGF0ZV8wLmpwZw" alt="" class="rounded-full w-12 h-12">                
                    <div class="flex flex-col">
                    <span class="font-bold">Alexei Palacios</span>
                    <span class="text-sm">alexepalacios12@gmail.com</span>
                    </div>
                </div>
                <div class="min-w-[120px] flex flex-col items-center font-bold uppercase text-dagblue">
                    <span>Costo</span>
                    <span class="text-emerald-700 font-bold">$250.00</span>
                </div>
                <div class="min-w-[120px] flex flex-col items-center font-bold uppercase text-dagblue">
                    <span>Utilidad</span>
                    <span class="text-emerald-700 font-bold">+ $100.00</span>
                </div>
                <div class="min-w-[180px] flex flex-col items-center font-bold uppercase text-dagblue">
                    <span>U.Licenciatarios</span>
                    <span class="text-emerald-700 font-bold">+ $224.00</span>
                </div>
                <div class="min-w-[120px] flex flex-col items-center font-bold uppercase text-dagblue">
                    <span>U. DAGPACKET</span>
                    <span class="text-emerald-700 font-bold">+ $224.00</span>
                </div>
                <div class="min-w-[120px] flex flex-col items-center uppercase text-dagblue">
                    <span class="font-bold">Origen</span>
                    <span class="">Zapopan</span>
                </div>
                <div class="min-w-[120px] flex flex-col items-center uppercase text-dagblue">
                    <span class="font-bold">Destino</span>
                    <span class="">Las Vegas</span>
                </div>
                <div class="min-w-[120px] flex flex-col font-bold items-center text-dagblue">
                    <span class="uppercase">Envio</span>
                    <span class="bg-gradient-to-r from-orange-700 to-dagpk px-2 rounded-lg text-white w-auto">Enviado</span>
                </div>
                <div class="min-w-[120px] flex flex-col font-bold items-center text-dagblue">
                    <span class="uppercase">Pago</span>
                    <span class="bg-gradient-to-r from-emerald-500 to-emerald-700 px-2 rounded-lg text-white w-auto">Pagado</span>
                </div>
            </div>        
            <div class="flex py-4 gap-2 hover:cursor-pointer hover:bg-slate-300 rounded-lg">                                                   
                <div class="min-w-[70px] flex justify-center items-center gap-2">
                    <img src="../../../assets/images/Icon Dagpacket.png" alt="" class="w-16 object-fit">
                </div>
                <div class="min-w-[220px] flex flex-col">
                    <span class="text-dagblue font-bold uppercase">Fecha</span>
                    <p>Enero 24, 2024 a las 2:40pm</p>
                </div>
                <div class="min-w-[300px] flex justify-center items-center gap-3">
                    <img src="https://imgs.search.brave.com/z-3_VMVbo5k1c4nYN-MGQSo7jUle-HYqNAS5ED1Soag/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9kMnFw/MHNpb3RsYTc0Ni5j/bG91ZGZyb250Lm5l/dC9pbWcvdXNlLWNh/c2VzL3Byb2ZpbGUt/cGljdHVyZS90ZW1w/bGF0ZV8wLmpwZw" alt="" class="rounded-full w-12 h-12">                
                    <div class="flex flex-col">
                    <span class="font-bold">Alexei Palacios</span>
                    <span class="text-sm">alexepalacios12@gmail.com</span>
                    </div>
                </div>
                <div class="min-w-[120px] flex flex-col items-center font-bold uppercase text-dagblue">
                    <span>Costo</span>
                    <span class="text-emerald-700 font-bold">$250.00</span>
                </div>
                <div class="min-w-[120px] flex flex-col items-center font-bold uppercase text-dagblue">
                    <span>Utilidad</span>
                    <span class="text-emerald-700 font-bold">+ $100.00</span>
                </div>
                <div class="min-w-[180px] flex flex-col items-center font-bold uppercase text-dagblue">
                    <span>U.Licenciatarios</span>
                    <span class="text-emerald-700 font-bold">+ $224.00</span>
                </div>
                <div class="min-w-[120px] flex flex-col items-center font-bold uppercase text-dagblue">
                    <span>U. DAGPACKET</span>
                    <span class="text-emerald-700 font-bold">+ $224.00</span>
                </div>
                <div class="min-w-[120px] flex flex-col items-center uppercase text-dagblue">
                    <span class="font-bold">Origen</span>
                    <span class="">Zapopan</span>
                </div>
                <div class="min-w-[120px] flex flex-col items-center uppercase text-dagblue">
                    <span class="font-bold">Destino</span>
                    <span class="">Las Vegas</span>
                </div>
                <div class="min-w-[120px] flex flex-col font-bold items-center text-dagblue">
                    <span class="uppercase">Envio</span>
                    <span class="bg-gradient-to-r from-orange-700 to-dagpk px-2 rounded-lg text-white w-auto">Enviado</span>
                </div>
                <div class="min-w-[120px] flex flex-col font-bold items-center text-dagblue">
                    <span class="uppercase">Pago</span>
                    <span class="bg-gradient-to-r from-emerald-500 to-emerald-700 px-2 rounded-lg text-white w-auto">Pagado</span>
                </div>
            </div>     
            <div class="flex py-4 gap-2 hover:cursor-pointer hover:bg-slate-300 rounded-lg">                                                    
                <div class="min-w-[70px] flex justify-center items-center gap-2">
                    <img src="../../../assets/images/Icon Dagpacket.png" alt="" class="w-16 object-fit">
                </div>
                <div class="min-w-[220px] flex flex-col">
                    <span class="text-dagblue font-bold uppercase">Fecha</span>
                    <p>Enero 24, 2024 a las 2:40pm</p>
                </div>
                <div class="min-w-[300px] flex justify-center items-center gap-3">
                    <img src="https://imgs.search.brave.com/z-3_VMVbo5k1c4nYN-MGQSo7jUle-HYqNAS5ED1Soag/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9kMnFw/MHNpb3RsYTc0Ni5j/bG91ZGZyb250Lm5l/dC9pbWcvdXNlLWNh/c2VzL3Byb2ZpbGUt/cGljdHVyZS90ZW1w/bGF0ZV8wLmpwZw" alt="" class="rounded-full w-12 h-12">                
                    <div class="flex flex-col">
                    <span class="font-bold">Alexei Palacios</span>
                    <span class="text-sm">alexepalacios12@gmail.com</span>
                    </div>
                </div>
                <div class="min-w-[120px] flex flex-col items-center font-bold uppercase text-dagblue">
                    <span>Costo</span>
                    <span class="text-emerald-700 font-bold">$250.00</span>
                </div>
                <div class="min-w-[120px] flex flex-col items-center font-bold uppercase text-dagblue">
                    <span>Utilidad</span>
                    <span class="text-emerald-700 font-bold">+ $100.00</span>
                </div>
                <div class="min-w-[180px] flex flex-col items-center font-bold uppercase text-dagblue">
                    <span>U.Licenciatarios</span>
                    <span class="text-emerald-700 font-bold">+ $224.00</span>
                </div>
                <div class="min-w-[120px] flex flex-col items-center font-bold uppercase text-dagblue">
                    <span>U. DAGPACKET</span>
                    <span class="text-emerald-700 font-bold">+ $224.00</span>
                </div>
                <div class="min-w-[120px] flex flex-col items-center uppercase text-dagblue">
                    <span class="font-bold">Origen</span>
                    <span class="">Zapopan</span>
                </div>
                <div class="min-w-[120px] flex flex-col items-center uppercase text-dagblue">
                    <span class="font-bold">Destino</span>
                    <span class="">Las Vegas</span>
                </div>
                <div class="min-w-[120px] flex flex-col font-bold items-center text-dagblue">
                    <span class="uppercase">Envio</span>
                    <span class="bg-gradient-to-r from-orange-700 to-dagpk px-2 rounded-lg text-white w-auto">Enviado</span>
                </div>
                <div class="min-w-[120px] flex flex-col font-bold items-center text-dagblue">
                    <span class="uppercase">Pago</span>
                    <span class="bg-gradient-to-r from-emerald-500 to-emerald-700 px-2 rounded-lg text-white w-auto">Pagado</span>
                </div>
            </div> 
            <div class="flex py-4 gap-2 hover:cursor-pointer hover:bg-slate-300 rounded-lg">                                              
                <div class="min-w-[70px] flex justify-center items-center gap-2">
                    <img src="../../../assets/images/Icon Dagpacket.png" alt="" class="w-16 object-fit">
                </div>
                <div class="min-w-[220px] flex flex-col">
                    <span class="text-dagblue font-bold uppercase">Fecha</span>
                    <p>Enero 24, 2024 a las 2:40pm</p>
                </div>
                <div class="min-w-[300px] flex justify-center items-center gap-3">
                    <img src="https://imgs.search.brave.com/z-3_VMVbo5k1c4nYN-MGQSo7jUle-HYqNAS5ED1Soag/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9kMnFw/MHNpb3RsYTc0Ni5j/bG91ZGZyb250Lm5l/dC9pbWcvdXNlLWNh/c2VzL3Byb2ZpbGUt/cGljdHVyZS90ZW1w/bGF0ZV8wLmpwZw" alt="" class="rounded-full w-12 h-12">                
                    <div class="flex flex-col">
                    <span class="font-bold">Alexei Palacios</span>
                    <span class="text-sm">alexepalacios12@gmail.com</span>
                    </div>
                </div>
                <div class="min-w-[120px] flex flex-col items-center font-bold uppercase text-dagblue">
                    <span>Costo</span>
                    <span class="text-emerald-700 font-bold">$250.00</span>
                </div>
                <div class="min-w-[120px] flex flex-col items-center font-bold uppercase text-dagblue">
                    <span>Utilidad</span>
                    <span class="text-emerald-700 font-bold">+ $100.00</span>
                </div>
                <div class="min-w-[180px] flex flex-col items-center font-bold uppercase text-dagblue">
                    <span>U.Licenciatarios</span>
                    <span class="text-emerald-700 font-bold">+ $224.00</span>
                </div>
                <div class="min-w-[120px] flex flex-col items-center font-bold uppercase text-dagblue">
                    <span>U. DAGPACKET</span>
                    <span class="text-emerald-700 font-bold">+ $224.00</span>
                </div>
                <div class="min-w-[120px] flex flex-col items-center uppercase text-dagblue">
                    <span class="font-bold">Origen</span>
                    <span class="">Zapopan</span>
                </div>
                <div class="min-w-[120px] flex flex-col items-center uppercase text-dagblue">
                    <span class="font-bold">Destino</span>
                    <span class="">Las Vegas</span>
                </div>
                <div class="min-w-[120px] flex flex-col font-bold items-center text-dagblue">
                    <span class="uppercase">Envio</span>
                    <span class="bg-gradient-to-r from-orange-700 to-dagpk px-2 rounded-lg text-white w-auto">Enviado</span>
                </div>
                <div class="min-w-[120px] flex flex-col font-bold items-center text-dagblue">
                    <span class="uppercase">Pago</span>
                    <span class="bg-gradient-to-r from-emerald-500 to-emerald-700 px-2 rounded-lg text-white w-auto">Pagado</span>
                </div>
            </div> 
        </div>
    </div>
</div>