import { Component, OnInit } from '@angular/core';
import { EmidaService } from 'src/app/_services/Emida/emida.service';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2';

interface ExtraReference {
  Mandatory: string;
  ReferenceName: string;
  FieldType: string;
  LengthMin: string;
  LengthMax: string;
  Prefix: string;
  ToolTip: string;
  URLImage: string;
}

interface ReferenceParameter {
  ReferenceName: string;
  FieldType: string;
  LengthMin: string;
  LengthMax: string;
  Prefix: string;
  ToolTip: string;
  URLImage: string;
  ExtraReference1: ExtraReference;
}

interface Service {
  FlowType: string;
  ProductCategory: string;
  ProductSubCategory: string;
  CarrierName: string;
  ProductId: string;
  ProductName: string;
  ProductUFee: string;
  CurrencyCode: string;
  Amount: string;
  AmountMin: string;
  AmountMax: string;
  PaymentType: string;
  ReferenceParameters: {
    [key: string]: ReferenceParameter | ReferenceParameter[];
  };
}

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html'
})
export class ServicesComponent implements OnInit {
  categories: string[] = [];
  selectedCategory: string = '';
  services: { [key: string]: Service[] } = {};
  
  // Declarar las nuevas propiedades
  commission: number = 0;
  totalAmount: number = 0;

  showPaymentModal: boolean = false;
  selectedService: Service | null = null;
  selectedPrice: number = 0;
  referenceValues: { [key: string]: string } = {};

  alertMessage: string = '';
  showAlert: boolean = false;
  isSuccess: boolean = false;

  constructor(private emidaService: EmidaService) {}

  ngOnInit() {
    this.loadAllServices();
  }

  loadAllServices() {
    forkJoin({
      recharges: this.emidaService.getEmidaServices(),
      payments: this.emidaService.getEmidaPaymentService()
    }).subscribe(
      ({ recharges, payments }) => {
        this.organizeServices([...recharges, ...payments]);
      },
      error => {
        console.error('Error fetching services:', error);
        this.showAlertMessage('Error al cargar los servicios. Por favor, intente de nuevo.', false);
      }
    );
  }

  organizeServices(data: Service[]) {
    this.services = {};
    data.forEach(service => {
      const category = service.ProductCategory;
      if (!this.services[category]) {
        this.services[category] = [];
        this.categories.push(category);
      }
      this.services[category].push(service);
    });
    if (this.categories.length > 0) {
      this.selectedCategory = this.categories[0];
    }
  }

  selectCategory(category: string): void {
    this.selectedCategory = category;
  }

  openPaymentModal(service: Service): void {
    this.selectedService = service;
    this.selectedPrice = service.Amount ? parseFloat(service.Amount) : 0;
    this.referenceValues = {};
    this.showPaymentModal = true;
  }

  cancelPayment(): void {
    this.showPaymentModal = false;
    this.selectedService = null;
    this.selectedPrice = 0;
    this.referenceValues = {};
  }

  confirmPayment(): void {
    console.log('Reference1 value:', this.referenceValues['Reference1']);
    
    if (!this.validateReferences()) {
      this.showAlertMessage('Por favor, complete todos los campos requeridos correctamente.', false);
      return;
    }
  
    // Continúa con la transacción si la validación es exitosa
    const paymentData = {
      productId: this.selectedService?.ProductId,
      accountId: this.referenceValues['Reference1'], // Verifica si Reference1 está correctamente lleno
      amount: this.selectedPrice.toString(),
      referenceData: this.referenceValues
    };
  
    if (this.selectedService?.FlowType === 'A') {
      this.emidaService.doRecharge(paymentData).subscribe(
        (response: any) => {
          // console.log('Recharge response:', response.result.PinDistSaleResponse.ResponseCode);
          const responseCode = response.result.PinDistSaleResponse.ResponseCode;
          const specificReason = response.result.PinDistSaleResponse.ReasonCode || ''; 

          // if (responseCode === '51') {
          //   Swal.fire({
          //     title: 'Operación fallida',
          //     text: 'La recarga no se ha procesado correctamente.',
          //     icon: 'error',
          //     confirmButtonText: 'Aceptar'
          //   });
          //   this.showPaymentModal = false;
          // }else{
          //   Swal.fire({
          //     title: 'Operación exitosa',
          //     text: 'La recarga se ha procesado correctamente.',
          //     icon: 'success',
          //     confirmButtonText: 'Aceptar'
          //   });
          // }


          switch(responseCode) {
            case '00': //  1, 2
              this.showAlertMessage('Transaccion exitosa. Ingresar informacion de seguimiento.', true);
              break;
            case '51': //  3, 4, 5, 6, 12
              if (specificReason === 'Invalid Amount') {
                this.showAlertMessage('Transaccion declinada por monto inválido.', false);
              } else if (specificReason === 'Invalid Product') {
                this.showAlertMessage('Producto inválido. Por favor, actualice su lista de productos.', false);
              } else if (specificReason === 'Insufficient Funds') {
                this.showAlertMessage('Fondos insuficientes. Realice una nueva transacción.', false);
              }
              break;
            case '24': //  13, 14
            case '34': //  13, 14
              this.showAlertMessage('Producto no asignado. Actualice su lista de productos.', false);
              break;
            case '15': //  15, 16
              this.showAlertMessage('Número de cuenta inválido.', false);
              break;
            case '20': //  17
              this.showAlertMessage('Error en la comunicación con el banco.', false);
              break;
            case '26': //  18
              this.showAlertMessage('La transacción fue declinada por seguridad.', false);
              break;
            case '27': //  19
              this.showAlertMessage('Usuario no autorizado.', false);
              break;
            case '28': //  20
              this.showAlertMessage('Transacción no permitida para este comercio.', false);
              break;
            default:
              this.showAlertMessage('Transacción declinada.', false);
              break;
          }
          
        },
        error => {
          this.showAlertMessage('Error al procesar la recarga. Por favor, intente de nuevo.', false);
        }
      );
    }
  }
  
  

  validateReferences(): boolean {
    if (!this.selectedService) return false;

    const { Reference1, Reference2 } = this.selectedService.ReferenceParameters;
    
    if (!this.validateReference(Reference1, 'Reference1')) return false;

    if (Reference2) {
      if (Array.isArray(Reference2)) {
        if (!this.validateReference(Reference2[0], 'Reference2')) return false;
      } else {
        if (!this.validateReference(Reference2, 'Reference2')) return false;
      }
    }

    return true;
  }

  validateReference(ref: ReferenceParameter | ReferenceParameter[], key: string): boolean {
    const value = this.referenceValues[key];  // Toma el valor de la referencia
    console.log(`Validating ${key} with value:`, value);
  
    if (!value) {
      console.log(`${key} is missing or empty`);
      return false;
    }
  
    const referenceParameter = Array.isArray(ref) ? ref[0] : ref;
    const length = value.length;
  
    // Verifica que la longitud del valor esté dentro de los límites permitidos
    const isValidLength = length >= parseInt(referenceParameter.LengthMin) && length <= parseInt(referenceParameter.LengthMax);
    
    console.log(`${key} has valid length:`, isValidLength);
    
    return isValidLength;
  }
  

  getReferenceParameter(service: Service, key: string): ReferenceParameter | undefined {
    const ref = service.ReferenceParameters[key];
    return Array.isArray(ref) ? ref[0] : ref;
  }

  getURLImage(service: Service): string | undefined {
    return this.getReferenceParameter(service, 'Reference1')?.URLImage;
  }

  getToolTip(service: Service): string | undefined {
    return this.getReferenceParameter(service, 'Reference1')?.ToolTip;
  }

  isArray(ref: ReferenceParameter | ReferenceParameter[]): ref is ReferenceParameter[] {
    return Array.isArray(ref);
  }

  showAlertMessage(message: string, isSuccess: boolean) {
    this.alertMessage = message;
    this.isSuccess = isSuccess;
    this.showAlert = true;
    setTimeout(() => {
      this.showAlert = false;
    }, 5000);
  }
}
