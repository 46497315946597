<nav class="navbar fixed top-0 z-50 w-full bg-white">
    <div class="px-3 py-3 lg:px-5 lg:pl-3 flex justify-between items-center relative overflow-hidden">
        <!-- Moto animada -->
        <div class="moto-container">
            <img src="../../../assets/images/Moto_animacion.png" alt="Moto" class="moto-image">
            <div class="smoke"></div>
            <div class="smoke"></div>
            <div class="smoke"></div>
        </div>
        
        <div class="flex items-center">
            <button class="text-dagblue p-2 hover:bg-dagblue hover:text-white rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 5.25h16.5m-16.5 6.75h16.5m-16.5 6.75h16.5" />
                </svg>
            </button>
        </div>
        <div class="flex items-center gap-4">
            <!-- Botón de carrito -->
            <button (click)="toggleCart()" class="nav-icon p-2 hover:bg-dagblue hover:text-white rounded-full relative">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                </svg>
                <span *ngIf="cartItems.length > 0" class="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                    {{cartItems.length}}
                </span>
            </button>
            <!-- Botón de notificaciones -->
            <button class="nav-icon p-2 hover:bg-dagblue hover:text-white rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 17h5l-1.405-1.405A7.956 7.956 0 0017.787 11.5C17.787 7.477 14.31 4 10 4S2.213 7.477 2.213 11.5a7.956 7.956 0 001.192 4.05L2 17h5m6 0v.5a2.5 2.5 0 11-5 0V17m6 0H9" />
                </svg>
            </button>            
            <!-- Botón de cerrar sesión -->
            <button (click)="logout()" class="nav-item flex items-center rounded-lg p-2 hover:bg-dagblue hover:text-white duration-200 gap-2 text-dagblue">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                    <path fill-rule="evenodd" d="M16.5 3.75a1.5 1.5 0 0 1 1.5 1.5v13.5a1.5 1.5 0 0 1-1.5 1.5h-6a1.5 1.5 0 0 1-1.5-1.5V15a.75.75 0 0 0-1.5 0v3.75a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3V5.25a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3V9A.75.75 0 1 0 9 9V5.25a1.5 1.5 0 0 1 1.5-1.5h6ZM5.78 8.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 0 0 0 1.06l3 3a.75.75 0 0 0 1.06-1.06l-1.72-1.72H15a.75.75 0 0 0 0-1.5H4.06l1.72-1.72a.75.75 0 0 0 0-1.06Z" clip-rule="evenodd" />
                </svg>
                <span>Salir</span>
            </button>
        </div>
    </div>
</nav>


<div *ngIf="isCartOpen" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
    <div class="bg-white rounded-xl shadow-2xl w-full max-w-lg mx-4 overflow-hidden">
      <div class="bg-dagblue text-white py-4 px-6 flex justify-between items-center">
        <h2 class="text-2xl font-bold">Carrito de Compras</h2>
        <button (click)="closeCart()" class="text-white hover:text-gray-300 transition duration-300">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
      <div class="p-6">
        <div *ngIf="cartItems.length === 0" class="text-gray-600 text-center py-8">
          <svg class="w-16 h-16 mx-auto mb-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
          </svg>
          <p class="font-medium">No hay envíos pendientes en el carrito.</p>
        </div>
        <ul *ngIf="cartItems.length > 0" class="space-y-4 max-h-64 overflow-y-auto">
          <li *ngFor="let item of cartItems" class="bg-gray-50 rounded-lg overflow-hidden">
            <div class="p-4">
              <div class="flex justify-between items-center mb-2">
                <span class="font-medium text-dagblue">Envío: {{ item.provider }}</span>
                <button class="text-red-500 hover:text-red-700" (click)="removeItem(item)">
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                </button>
              </div>
              <div class="text-sm text-gray-600">
                <p>Origen: {{ item.from.city || 'No especificado' }}</p>
                <p>Destino: {{ item.to.city || 'No especificado' }}</p>
              </div>
            </div>
            <div class="bg-gray-100 px-4 py-2 flex justify-between items-center">
              <span class="text-sm font-bold">ID de envío: {{ item._id }}</span>
              <span class="font-medium">${{ getItemPrice(item) | number:'1.2-2' }}</span>
            </div>
          </li>
        </ul>
      </div>  
      <div class="bg-gray-100 px-6 py-4">
        <div *ngIf="cartItems.length > 0" class="flex justify-between items-center mb-4">
          <span class="font-bold text-lg">Total:</span>
          <span class="font-bold text-lg text-dagblue">${{ getTotalPrice() | number:'1.2-2' }}</span>
        </div>
        <div class="flex justify-between items-center">                    
          <button (click)="closeCart()" class="bg-gray-300 rounded-full hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 transition duration-300">
            Cerrar
          </button> 
          <button *ngIf="cartItems.length > 0" [routerLink]="['/pay-shipments']" class="bg-dagpk hover:bg-dagpk-dark text-white font-bold py-2 px-4 rounded-full transition duration-300">
            Ir a pagar
          </button>
        </div>
      </div>
    </div>
  </div>
  

  <aside id="logo-sidebar" class="fixed top-0 left-0 z-40 w-64 h-screen overflow-y-scroll pt-4 transition-transform -translate-x-full bg-dagblue text-white sm:translate-x-0 rounded-tr-[30px] rounded-br-[30px] pr-0" aria-label="Sidebar">
    <div class="w-full h-auto p-3">
        <!-- Logotipo en la parte superior -->
        <div class="text-center mb-6">
            <a [routerLink]="['/landing']">
                <img src="../../../assets/images/LogotipoM.png" alt="Logotipo" class="mx-auto" style="max-width: 180px; height: auto;" />
            </a>
        </div>
        <div class="py-3 rounded-lg text-center">
            <img *ngIf="user.image; else defaultAvatar" [src]="user.image" alt="User Avatar" class="rounded-full w-24 h-24 mx-auto" />
            <ng-template #defaultAvatar>
                <img src="../../../assets/images/avatar-placeholder.png" alt="User Avatar" class="rounded-full w-24 h-24 mx-auto" />
            </ng-template>
            <div class="user-name mt-3 text-lg font-semibold">{{ user.name }} {{user.surname}}</div>
            <div class="flex flex-col bg-gray-200 rounded-3xl p-2">
                <div class="user-balance mt-1 text-md text-gray-700 font-normal">Saldo envíos: {{shipmentBalance | currency}}</div>            
                <div class="user-balance mt-1 text-md text-gray-700 font-normal">Saldo servicios: {{servicesBalance | currency}}</div>  
                <div class="user-balance mt-1 text-md text-gray-700 font-normal">Saldo recargas: {{recharguesBalance | currency}}</div>  
            </div>
            <a [routerLink]="['/settings']" class="config-link mt-2 inline-block text-sm text-gray-300 hover:text-white font-extrabold">
                <svg class="inline w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                </svg>
                Configuración
            </a>
            <hr class="border-t-2 border-white mt-2"/>
        </div>
    </div>
    <div class="h-full pb-4 overflow-y-auto">
        <ul class="space-y-2 ml-3 font-medium">
            <li class="mt-2">
                <div class="hover:bg-white hover:text-black" [ngClass]="{
                    'bg-white rounded-l-full': isCurrentPage('/landing'),
                    'hover:bg-dagblue-light rounded-full mx-2': !isCurrentPage('/landing')
                    }">
                    <a [routerLink]="['/landing']" 
                       class="flex items-center py-1 hover:text-black duration-100 text-base"
                       [ngClass]="{
                            'px-6 text-black font-semibold': isCurrentPage('/landing'),
                            'px-4 text-white': !isCurrentPage('/landing')
                       }">             
                        <span class="font-bold uppercase">Inicio</span>
                    </a>
                </div>
            </li>
            <!-- Paquetes -->
            <li *ngIf="hasRole('ADMIN') || hasRole('LICENCIATARIO_TRADICIONAL') || hasRole('CAJERO')">
                <div class="px-2 py-2">
                    <button (click)="toggleSection('paquetes')" class="flex items-center w-full text-left">
                        <span class="font-bold uppercase text-sm flex-grow">Paquetes</span>
                        <svg [ngClass]="{'rotate-180': isSectionOpen('paquetes')}" class="w-4 h-4 transition-transform duration-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
                <ul [@sectionAnimation]="isSectionOpen('paquetes') ? 'open' : 'closed'" class="pl-4 overflow-hidden">
                    <li class="mt-2">
                        <div class="hover:bg-white hover:rounded-l-full hover:text-black" [ngClass]="{
                            'bg-white rounded-l-full': isCurrentPage('/quote'),
                            'hover:bg-dagblue-light hover:bg-white hover:text-black': !isCurrentPage('/quote')
                        }">
                            <a [routerLink]="['/quote']" 
                               class="flex items-center py-2 rounded-l-full  hover:text-black pl-8 pr-6 duration-100 text-sm w-full"
                               [ngClass]="{
                                    'px-6 text-black font-semibold': isCurrentPage('/quote'),
                                    'px-4 text-white': !isCurrentPage('/quote')
                               }">                         
                                <span> Cotizar Envio </span>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="hover:bg-white  hover:rounded-l-full hover:text-black" [ngClass]="{
                            'bg-white rounded-l-full': isCurrentPage('/shipments'),
                            'hover:bg-white hover:text-black': !isCurrentPage('/shipments')
                        }">
                            <a [routerLink]="['/shipments']" 
                               class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                               [ngClass]="{
                                    'px-6 text-black font-semibold': isCurrentPage('/shipments'),
                                    'px-4 text-white': !isCurrentPage('/shipments')
                               }">
                                <span>Envios</span>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="hover:bg-white  hover:rounded-l-full hover:text-black" [ngClass]="{
                            'bg-white rounded-l-full': isCurrentPage('/packing'),
                            'hover:bg-white hover:text-black': !isCurrentPage('/packing')
                        }">
                            <a [routerLink]="['/packing']" 
                               class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                               [ngClass]="{
                                    'text-black font-semibold': isCurrentPage('/packing'),
                                    'text-white': !isCurrentPage('/packing')
                               }">
                                <span>Empaques</span>
                            </a>
                        </div>
                    </li>
                </ul>
            </li>
            <!-- Licenciatarios -->
            <li *ngIf="hasRole('ADMIN') || hasRole('LICENCIATARIO_TRADICIONAL') || hasRole('CAJERO')">
                <div class="px-2">
                    <button (click)="toggleSection('licenciatarios')" class="flex items-center w-full text-left">
                        <span class="font-bold uppercase text-sm flex-grow">Licenciatarios</span>
                        <svg [ngClass]="{'rotate-180': isSectionOpen('licenciatarios')}" class="w-4 h-4 transition-transform duration-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
                <ul [@sectionAnimation]="isSectionOpen('licenciatarios') ? 'open' : 'closed'" class="pl-4 overflow-hidden">
                    <li *ngIf="hasRole('ADMIN') || hasRole('LICENCIATARIO_TRADICIONAL')">
                        <div class="hover:bg-white  hover:rounded-l-full hover:text-black" [ngClass]="{
                            'bg-white rounded-l-full': isCurrentPage('/licensees'),
                            'hover:bg-white hover:text-black': !isCurrentPage('/licensees')
                        }">
                            <a [routerLink]="['/licensees']" 
                               class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                               [ngClass]="{
                                    'px-6 text-black font-semibold': isCurrentPage('/licensees'),
                                    'px-4 text-white': !isCurrentPage('/licensees')
                               }">
                                <span>Resumen</span>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="hover:bg-white  hover:rounded-l-full hover:text-black" [ngClass]="{
                            'bg-white rounded-l-full': isCurrentPage('/cashiers'),
                            'hover:bg-white hover:text-black': !isCurrentPage('/cashiers')
                        }">
                            <a [routerLink]="['/cashiers']" 
                               class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                               [ngClass]="{
                                    'px-6 text-black font-semibold': isCurrentPage('/cashiers'),
                                    'px-4 text-white': !isCurrentPage('/cashiers')
                               }">
                                <span>Caja</span>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="hover:bg-white  hover:rounded-l-full hover:text-black" [ngClass]="{
                            'bg-white rounded-l-full': isCurrentPage('/services'),
                            'hover:bg-white hover:text-black': !isCurrentPage('/services')
                        }">
                            <a [routerLink]="['/services']" 
                               class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                               [ngClass]="{
                                    'px-6 text-black font-semibold': isCurrentPage('/services'),
                                    'px-4 text-white': !isCurrentPage('/services')
                               }">
                                <span>Pago de servicios</span>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="hover:bg-white  hover:rounded-l-full hover:text-black" [ngClass]="{
                            'bg-white rounded-l-full': isCurrentPage('/tickets'),
                            'hover:bg-white hover:text-black': !isCurrentPage('/tickets')
                        }">
                            <a [routerLink]="['/tickets']" 
                               class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                               [ngClass]="{
                                    'px-6 text-black font-semibold': isCurrentPage('/tickets'),
                                    'px-4 text-white': !isCurrentPage('/tickets')
                               }">
                                <span>Solicitudes de Cancelacion</span>
                            </a>
                        </div>
                    </li>
                </ul>
            </li>
            <!-- Gestión Administrativa -->
            <li *ngIf="hasRole('ADMIN')">
                <div class="px-2">
                    <button (click)="toggleSection('funciones')" class="flex items-center w-full text-left">
                        <span class="font-bold uppercase text-sm flex-grow">Gestión Administrativa</span>
                        <svg [ngClass]="{'rotate-180': isSectionOpen('funciones')}" class="w-4 h-4 transition-transform duration-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
                <ul [@sectionAnimation]="isSectionOpen('funciones') ? 'open' : 'closed'" class="pl-4 overflow-hidden">
                    <li>
                        <div class="hover:bg-white  hover:rounded-l-full hover:text-black" [ngClass]="{
                            'bg-white rounded-l-full': isCurrentPage('/administracion'),
                            'hover:bg-white hover:text-black': !isCurrentPage('/administracion')
                        }">
                            <a [routerLink]="['/administracion']" 
                               class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                               [ngClass]="{
                                    'px-6 text-black font-semibold': isCurrentPage('/administracion'),
                                    'px-4 text-white': !isCurrentPage('/administracion')
                               }">
                                <span>Dashboard General</span>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="hover:bg-white  hover:rounded-l-full hover:text-black" [ngClass]="{
                            'bg-white rounded-l-full': isCurrentPage('/olimpo'),
                            'hover:bg-white hover:text-black': !isCurrentPage('/olimpo')
                        }">
                            <a [routerLink]="['/olimpo']" 
                               class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                               [ngClass]="{
                                    'px-6 text-black font-semibold': isCurrentPage('/olimpo'),
                                    'px-4 text-white': !isCurrentPage('/olimpo')
                               }">
                                <span>Panel de Adminsitracion</span>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="hover:bg-white  hover:rounded-l-full hover:text-black" [ngClass]="{
                            'bg-white rounded-l-full': isCurrentPage('/displayRefills'),
                            'hover:bg-white hover:text-black': !isCurrentPage('/displayRefills')
                        }">
                            <a [routerLink]="['/displayRefills']" 
                               class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                               [ngClass]="{
                                    'px-6 text-black font-semibold': isCurrentPage('/displayRefills'),
                                    'px-4 text-white': !isCurrentPage('/displayRefills')
                               }">
                                <span>Solcitudes de recarga</span>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="hover:bg-white  hover:rounded-l-full hover:text-black" [ngClass]="{
                            'bg-white rounded-l-full': isCurrentPage('/cash-register-history'),
                            'hover:bg-white hover:text-black': !isCurrentPage('/cash-register-history')
                        }">
                            <a [routerLink]="['/cash-register-history']" 
                               class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                               [ngClass]="{
                                    'px-6 text-black font-semibold': isCurrentPage('/cash-register-history'),
                                    'px-4 text-white': !isCurrentPage('/cash-register-history')
                               }">
                                <span>Cortes de caja</span>
                            </a>
                        </div>
                    </li>
                </ul>
            </li>
            <!-- Lockers -->
            <li *ngIf="hasRole('ADMIN')">
                <div class="px-2">
                    <button (click)="toggleSection('lockers')" class="flex items-center w-full text-left">
                        <span class="font-bold uppercase text-sm flex-grow">Lockers</span>
                        <svg [ngClass]="{'rotate-180': isSectionOpen('lockers')}" class="w-4 h-4 transition-transform duration-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
                <ul [@sectionAnimation]="isSectionOpen('lockers') ? 'open' : 'closed'" class="pl-4 overflow-hidden">
                    <li>
                        <button [routerLink]="['/viewlockers']" 
                                [ngClass]="{
                                    'bg-white text-black': isCurrentPage('/viewlockers'), 
                                    'bg-dagblue-light text-white hover:bg-white hover:text-black': !isCurrentPage('/viewlockers')
                                }" 
                                class="flex pl-2 pt-2 pb-2 rounded-lg duration-100 w-full text-sm">
                            <span class="ms-3 whitespace-nowrap">Estatus de Lockers</span>
                        </button>
                    </li>                    
                </ul>
            </li>
        </ul>
    </div>  
</aside>


