import { Component } from '@angular/core';
import { UserServiceService } from 'src/app/_services/users/user-service.service';

@Component({
  selector: 'app-request-reset',
  templateUrl: './request-reset.component.html',
  styleUrls: ['./request-reset.component.scss']
})
export class RequestResetComponent {
  email: string = '';
  message: string = '';
  error: boolean = false;
  success: boolean = false;
  loading: boolean = false;

  constructor(private authService: UserServiceService) { }

  onSubmit() {
    this.loading = true;
    this.authService.requestPasswordReset(this.email).subscribe(
      response => {
        this.message = 'Se ha enviado un correo con las instrucciones para restablecer tu contraseña.';
        this.error = false;
        this.success = true;
        this.loading = false;
      },
      error => {
        this.message = 'Ha ocurrido un error. Por favor, intenta de nuevo.';
        this.error = true;
        this.success = false;
        this.loading = false;
      }
    );
  }
}