import { Component } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-locker-delivery',
  templateUrl: './locker-delivery.component.html',
  styleUrls: ['./locker-delivery.component.scss']
})
export class LockerDeliveryComponent {

  map_lottie: AnimationOptions = {
    path: '../../../assets/locker_images/map.json', 
  };

}
