import { Component, OnInit } from '@angular/core';
import { EmidaService } from 'src/app/_services/Emida/emida.service';
import { forkJoin } from 'rxjs';

interface ExtraReference {
  Mandatory: string;
  ReferenceName: string;
  FieldType: string;
  LengthMin: string;
  LengthMax: string;
  Prefix: string;
  ToolTip: string;
  URLImage: string;
}

interface ReferenceParameter {
  ReferenceName: string;
  FieldType: string;
  LengthMin: string;
  LengthMax: string;
  Prefix: string;
  ToolTip: string;
  URLImage: string;
  ExtraReference1: ExtraReference;
}

interface Service {
  FlowType: string;
  ProductCategory: string;
  ProductSubCategory: string;
  CarrierName: string;
  ProductId: string;
  ProductName: string;
  ProductUFee: string;
  CurrencyCode: string;
  Amount: string;
  AmountMin: string;
  AmountMax: string;
  PaymentType: string;
  ReferenceParameters: {
    [key: string]: ReferenceParameter | ReferenceParameter[];
  };
}

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html'
})
export class ServicesComponent implements OnInit {
  categories: string[] = [];
  selectedCategory: string = '';
  services: { [key: string]: Service[] } = {};

  showPaymentModal: boolean = false;
  selectedService: Service | null = null;
  selectedPrice: number = 0;
  referenceValues: { [key: string]: string } = {};

  alertMessage: string = '';
  showAlert: boolean = false;
  isSuccess: boolean = false;

  constructor(private emidaService: EmidaService) {}

  ngOnInit() {
    this.loadAllServices();
  }

  loadAllServices() {
    forkJoin({
      recharges: this.emidaService.getEmidaServices(),
      payments: this.emidaService.getEmidaPaymentService()
    }).subscribe(
      ({ recharges, payments }) => {
        this.organizeServices([...recharges, ...payments]);
      },
      error => {
        console.error('Error fetching services:', error);
        this.showAlertMessage('Error al cargar los servicios. Por favor, intente de nuevo.', false);
      }
    );
  }

  organizeServices(data: Service[]) {
    this.services = {};
    data.forEach(service => {
      const category = service.ProductCategory;
      if (!this.services[category]) {
        this.services[category] = [];
        this.categories.push(category);
      }
      this.services[category].push(service);
    });
    if (this.categories.length > 0) {
      this.selectedCategory = this.categories[0];
    }
  }

  selectCategory(category: string): void {
    this.selectedCategory = category;
  }

  openPaymentModal(service: Service): void {
    this.selectedService = service;
    this.selectedPrice = service.Amount ? parseFloat(service.Amount) : 0;
    this.referenceValues = {};
    this.showPaymentModal = true;
  }

  cancelPayment(): void {
    this.showPaymentModal = false;
    this.selectedService = null;
    this.selectedPrice = 0;
    this.referenceValues = {};
  }

  confirmPayment(): void {
    if (!this.validateReferences()) {
      this.showAlertMessage('Por favor, complete todos los campos requeridos correctamente.', false);
      return;
    }
  
    const paymentData = {
      productId: this.selectedService?.ProductId,
      accountId: this.referenceValues['Reference1'],
      amount: this.selectedPrice.toString(),
      referenceData: this.referenceValues
    };
  
    if (this.selectedService?.FlowType === 'A') {
      this.emidaService.doRecharge(paymentData).subscribe(
        (response: any) => {
          console.log('Respuesta de recarga:', response);
          if (response.PinDistSaleResponse) {
            const pinResponse = response.PinDistSaleResponse;
            if (pinResponse.ResponseCode === "00" && pinResponse.H2H_RESULT_CODE === "0") {
              this.showAlertMessage(pinResponse.ResponseMessage || 'Recarga realizada con éxito.', true);
              this.cancelPayment();
            } else {
              this.showAlertMessage(pinResponse.ResponseMessage || 'Error al procesar la recarga.', false);
            }
          } else {
            this.showAlertMessage('Error inesperado al procesar la recarga.', false);
          }
        },
        error => {
          console.error('Error en la recarga:', error);
          this.showAlertMessage('Error al procesar la recarga. Por favor, intente de nuevo.', false);
        }
      );
    } else if (this.selectedService?.FlowType === 'B') {
      this.emidaService.doBillPayment(paymentData).subscribe(
        (response: any) => {
          console.log('Respuesta de pago de servicio:', response);
          if (response.BillPaymentUserFeeResponse) {
            const billResponse = response.BillPaymentUserFeeResponse;
            if (billResponse.ResponseCode === "00" && billResponse.H2H_RESULT_CODE === "0") {
              const successMessage = `${billResponse.ResponseMessage}\nMonto: $${billResponse.Amount}\nTransacción: ${billResponse.TRANSACTION}`;
              this.showAlertMessage(successMessage, true);
              this.cancelPayment();
            } else {
              this.showAlertMessage(billResponse.ResponseMessage || 'Error al procesar el pago de servicio.', false);
            }
          } else {
            this.showAlertMessage('Error inesperado al procesar el pago de servicio.', false);
          }
        },
        error => {
          console.error('Error en el pago de servicio:', error);
          this.showAlertMessage('Error al procesar el pago. Por favor, intente de nuevo.', false);
        }
      );
    }
  }

  validateReferences(): boolean {
    if (!this.selectedService) return false;

    const { Reference1, Reference2 } = this.selectedService.ReferenceParameters;
    
    if (!this.validateReference(Reference1, 'Reference1')) return false;

    if (Reference2) {
      if (Array.isArray(Reference2)) {
        if (!this.validateReference(Reference2[0], 'Reference2')) return false;
      } else {
        if (!this.validateReference(Reference2, 'Reference2')) return false;
      }
    }

    return true;
  }

  validateReference(ref: ReferenceParameter | ReferenceParameter[], key: string): boolean {
    const value = this.referenceValues[key];
    if (!value) return false;
    
    const referenceParameter = Array.isArray(ref) ? ref[0] : ref;
    const length = value.length;
    return length >= parseInt(referenceParameter.LengthMin) && length <= parseInt(referenceParameter.LengthMax);
  }

  getReferenceParameter(service: Service, key: string): ReferenceParameter | undefined {
    const ref = service.ReferenceParameters[key];
    return Array.isArray(ref) ? ref[0] : ref;
  }

  getURLImage(service: Service): string | undefined {
    return this.getReferenceParameter(service, 'Reference1')?.URLImage;
  }

  getToolTip(service: Service): string | undefined {
    return this.getReferenceParameter(service, 'Reference1')?.ToolTip;
  }

  isArray(ref: ReferenceParameter | ReferenceParameter[]): ref is ReferenceParameter[] {
    return Array.isArray(ref);
  }

  showAlertMessage(message: string, isSuccess: boolean) {
    this.alertMessage = message;
    this.isSuccess = isSuccess;
    this.showAlert = true;
    setTimeout(() => {
      this.showAlert = false;
    }, 5000);
  }
}