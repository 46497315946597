import { Component, OnInit } from '@angular/core';
import { RechargeRequestService } from 'src/app/_services/Rechargues/rechargue-request.service';

@Component({
  selector: 'app-display-refills',
  templateUrl: './display-refills.component.html',
  styleUrls: ['./display-refills.component.scss']
})
export class DisplayRefillsComponent implements OnInit {
  rechargeRequests: any[] = [];
  searchTerm: string = '';
  currentPage: number = 1;
  totalPages: number = 1;
  itemsPerPage: number = 10;
  totalRequests: number = 0;
  loading: boolean = false;
  
  showProofDialog: boolean = false;
  currentProofImage: string = '';
  Math = Math;
  showApproveDialog: boolean = false;
  currentApproveRequest: any = null;

  showRejectDialog: boolean = false;
  currentRejectId: string = '';
  rejectionReason: string = '';
  currentRejectRequest: any = null;

  showToast: boolean = false;
  toastMessage: string = '';
  toastType: 'success' | 'error' = 'success';

  constructor(private rechargeRequestService: RechargeRequestService) {}

  ngOnInit(): void {
    this.loadRechargeRequests();
    this.loadRequest();
  }

  showToastMessage(message: string, type: 'success' | 'error' = 'success'): void {
    this.toastMessage = message;
    this.toastType = type;
    this.showToast = true;
    setTimeout(() => {
      this.showToast = false;
    }, 3000);
  }

  loadRequest(){
    this.rechargeRequestService.getRechargues().subscribe(
      (res) => {
        console.log('Solcitudes de recarga', res.data);
        
      }
    )
  }

  loadRechargeRequests(): void {
    this.loading = true;
    this.rechargeRequestService.getRechargeRequests(this.currentPage, this.itemsPerPage, this.searchTerm).subscribe(
      (response: any) => {
        this.loading = false;
        if (response.success) {
          this.rechargeRequests = response.data.requests;
          this.currentPage = response.data.currentPage;
          this.totalPages = response.data.totalPages;
          this.totalRequests = response.data.totalRequests;
        } else {
          console.error('Error loading recharge requests:', response.message);
          this.showToastMessage('Error al cargar las solicitudes de recarga', 'error');
        }
      },
      (error) => {
        this.loading = false;
        console.error('Error loading recharge requests:', error);
        this.showToastMessage('Error al cargar las solicitudes de recarga', 'error');
      }
    );
  }

  search(): void {
    this.currentPage = 1;
    this.loadRechargeRequests();
  }

  changePage(newPage: number): void {
    if (newPage >= 1 && newPage <= this.totalPages && newPage !== this.currentPage) {
      this.currentPage = newPage;
      this.loadRechargeRequests();
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.changePage(this.currentPage + 1);
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.changePage(this.currentPage - 1);
    }
  }

  calculateDisplayedItems(): { start: number, end: number } {
    const start = (this.currentPage - 1) * this.itemsPerPage + 1;
    const end = Math.min(this.currentPage * this.itemsPerPage, this.totalRequests);
    return { start, end };
  }

  viewProof(rechargeId: string): void {
    const request = this.rechargeRequests.find(r => r._id === rechargeId);
    if (request && request.proofImage) {
      this.currentProofImage = `data:image/jpeg;base64,${request.proofImage}`;
      this.showProofDialog = true;
    } else {
      console.error('Comprobante no encontrado');
    }
  }

  closeProofDialog(): void {
    this.showProofDialog = false;
    this.currentProofImage = '';
  }

  openApproveDialog(request: any): void {
    this.currentApproveRequest = request;
    this.showApproveDialog = true;
  }

  closeApproveDialog(): void {
    this.showApproveDialog = false;
    this.currentApproveRequest = null;
  }

  confirmApproveRequest(): void {
    if (this.currentApproveRequest) {
      this.rechargeRequestService.approveRechargeRequest(this.currentApproveRequest._id).subscribe(
        (response: any) => {
          if (response.success) {
            this.showToastMessage(`Recarga de ${this.getRechargeTypeLabel(this.currentApproveRequest.rechargeType)} aprobada con éxito`);
            this.loadRechargeRequests(); // Recargar la lista
          } else {
            this.showToastMessage('Error al aprobar la recarga: ' + response.message, 'error');
          }
          this.closeApproveDialog();
        },
        (error) => {
          this.showToastMessage('Error al aprobar la recarga: ' + error.message, 'error');
          this.closeApproveDialog();
        }
      );
    }
  }

  rejectRequest(): void {
    if (this.rejectionReason.trim()) {
      this.rechargeRequestService.rejectRechargeRequest(this.currentRejectId, this.rejectionReason).subscribe(
        (response: any) => {
          if (response.success) {
            this.showToastMessage('Recarga rechazada con éxito');
            this.loadRechargeRequests(); // Recargar la lista
            this.closeRejectDialog();
          } else {
            this.showToastMessage('Error al rechazar la recarga: ' + response.message, 'error');
          }
        },
        (error) => {
          this.showToastMessage('Error al rechazar la recarga: ' + error.message, 'error');
        }
      );
    } else {
      this.showToastMessage('Se requiere un motivo de rechazo', 'error');
    }
  }

  openRejectDialog(request: any): void {
    this.currentRejectId = request._id;
    this.currentRejectRequest = request;
    this.showRejectDialog = true;
  }

  closeRejectDialog(): void {
    this.showRejectDialog = false;
    this.currentRejectId = '';
    this.currentRejectRequest = null;
    this.rejectionReason = '';
  }

  getRechargeTypeLabel(type: string): string {
    switch (type) {
      case 'envios':
        return 'Envíos';
      case 'servicios':
        return 'Servicios';
      case 'recargas':
        return 'Recargas';
      default:
        return 'Desconocido';
    }
  }
}