<div class="flex flex-col justify-start items-center min-h-screen bg-white relative p-4">
    <div *ngIf="successMessage" class="fixed top-4 right-4 bg-green-500 text-white p-3 rounded-lg shadow-lg z-50">
        {{ successMessage }}
    </div>
    
    <div *ngIf="errorMessage" class="fixed top-4 right-4 bg-red-500 text-white p-3 rounded-lg shadow-lg z-50">
        {{ errorMessage }}
    </div>

    <div class="w-full max-w-[1131px] mt-[224.96px]">        
        <div class="flex justify-center mb-[53.95px]">
            <img src="../../../assets/images/LogotipoA.png" alt="DagPacket Logo" class="w-[530.09px]">
        </div>

        <form #registerForm="ngForm" (submit)="signUp(registerForm)" class="space-y-4">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input name="name" type="text" placeholder="Nombre(s)" 
                       class="w-full px-4 py-3 border-2 rounded-full border-gray-400 text-gray-600 focus:outline-none focus:border-dagpk" 
                       ngModel required />
                <input name="surname" type="text" placeholder="Apellido(s)" 
                       class="w-full px-4 py-3 border-2 rounded-full border-gray-400 text-gray-600 focus:outline-none focus:border-dagpk" 
                       ngModel required />
                <input name="phone" type="tel" placeholder="Teléfono" 
                       class="w-full px-4 py-3 border-2 rounded-full border-gray-400 text-gray-600 focus:outline-none focus:border-dagpk" 
                       ngModel required />
                <input name="email" type="email" placeholder="E-mail" 
                       class="w-full px-4 py-3 border-2 rounded-full border-gray-400 text-gray-600 focus:outline-none focus:border-dagpk" 
                       ngModel required />
            </div>
            <div class="relative">
                <input name="password" [type]="showPassword ? 'text' : 'password'" placeholder="Contraseña" 
                       class="w-full px-4 py-3 border-2 rounded-full border-gray-400 text-gray-600 focus:outline-none focus:border-dagpk" 
                       ngModel required />
                <button type="button" (click)="togglePassword()" 
                        class="absolute top-1/2 right-3 text-gray-400 transform -translate-y-1/2">
                    <svg *ngIf="!showPassword" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                    </svg>
                    <svg *ngIf="showPassword" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                    </svg>
                </button>
            </div>

            <button type="submit" class="w-full py-3 rounded-full bg-dagpk text-white font-bold text-lg hover:bg-opacity-90">
                CREAR CUENTA
            </button>
        </form>

        <div class="mt-6 text-center">
            <p class="text-sm text-gray-700 text-right">¿Ya tienes cuenta?</p>
            <button [routerLink]="['/login']" 
                    class="mt-4 w-full py-3 border-2 border-dagpk rounded-full text-dagpk font-bold text-lg hover:bg-dagpk hover:text-white transition duration-200">
                INICIA SESIÓN
            </button>
        </div>

        <div class="mt-8 flex justify-start">
            <button [routerLink]="['/login']" class="text-dagblue flex items-center justify-center w-16 h-16 border-2 hover:bg-dagblue hover:text-white duration-200 border-dagblue rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                  </svg>
                  
            </button>
        </div>  
    </div>
</div>