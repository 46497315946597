import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private shipmentData = new BehaviorSubject<any>(null);
  currentShipmentData = this.shipmentData.asObservable();

  constructor() {
    const storedShipmentData = localStorage.getItem('shipmentData');
    if (storedShipmentData) {
      this.shipmentData.next(JSON.parse(storedShipmentData));
    }
  }

  updateShipmentData(data: any) {
    const currentData = this.shipmentData.getValue() || {};
    const updatedData = {
      ...currentData,
      ...data,
      apiProvider: data.apiProvider || currentData.apiProvider,
      cost: data.cost || currentData.cost,
      price: data.price || currentData.price,
      dagpacket_profit: data.dagpacket_profit || currentData.dagpacket_profit,
      provider: data.apiProvider || currentData.provider, // Usamos apiProvider como provider
      paqueteria_details: {
        ...currentData.paqueteria_details,
        ...data.paqueteria_details,
        proveedor: data.paqueteria?.proveedor || currentData.paqueteria_details?.proveedor
      }
    };

    // Eliminamos campos redundantes
    delete updatedData.proveedor;
    delete updatedData.apiProvider;
    delete updatedData.dagpacketProfit;
    delete updatedData.shippingType;

    // Aseguramos que shipment_type y shippingType sean consistentes
    updatedData.shipment_type = updatedData.shipment_type || updatedData.paqueteria_details?.shippingType;

    this.shipmentData.next(updatedData);
    localStorage.setItem('shipmentData', JSON.stringify(updatedData));
    console.log('Datos de envío actualizados:', updatedData);
  }

  updateFromData(data: any) {
    this.updateShipmentData({ from: data });
  }

  updateToData(data: any) {
    this.updateShipmentData({ to: data });
  }

  updatePaqueteriaData(data: any) {
    this.updateShipmentData({
      shipment_type: data.shippingType,
      provider: data.apiProvider, // Proveedor de la API (Superenvios o FedEx)
      idService: data.idServicio,
      cost: data.precio,
      price: data.precioConComision,
      shipment_data: {
        height: data.dimensiones.alto,
        width: data.dimensiones.ancho,
        length: data.dimensiones.largo,
        package_weight: data.dimensiones.peso,
      },
      paqueteria_details: {
        proveedor: data.proveedor,         
      }
    });
  }

  updatePaymentData(data: any) {
    this.updateShipmentData({ payment: data });
  }

  getShipmentData() {
    return this.shipmentData.getValue();
  }

  clearData() {
    this.shipmentData.next(null);
    localStorage.removeItem('shipmentData');
  }
}