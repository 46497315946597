<app-sidebar></app-sidebar>

<div class="ml-36 pt-20 w-auto h-[100vh]">
  <div class="flex p-6 ml-36">
    <div class="bg-[#F0F0F0] rounded-3xl w-[70vw] shadow-lg p-10">
      <div class="grid grid-cols-2 gap-10">
        <!-- Formulario del Remitente -->
        <div>
          <h2 class="font-extrabold text-3xl text-[#332D69] text-center mb-6">REMITENTE</h2>
          <form #fromForm="ngForm">
            <!-- Campo de autocompletado para la dirección -->
            <div class="relative group mb-4">
              <input #fromAddressInput type="text" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Dirección" />
            </div>
        
            <!-- Nombre completo y correo -->
            <div class="flex gap-4">
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="fromName" [(ngModel)]="from.name" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Nombre Completo" required />
              </div>          
              <div class="relative w-1/2 group mb-4">
                <input type="email" name="fromEmail" [(ngModel)]="from.email" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="E-mail" required />
              </div>
            </div>
        
            <!-- Teléfono -->
            <div class="relative group mb-4">
              <input type="tel" name="fromPhone" [(ngModel)]="from.phone" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Teléfono" required />
            </div>
        
            <!-- País y código postal -->
            <div class="flex gap-4">
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="fromCountry" [(ngModel)]="from.country" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="País" required />
              </div>          
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="fromZipCode" [(ngModel)]="from.zip_code" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="C.P." required />
              </div>
            </div>
        
            <!-- Estado y ciudad -->
            <div class="flex gap-4">
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="fromState" [(ngModel)]="from.state" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Estado" required />
              </div>
          
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="fromCity" [(ngModel)]="from.city" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Ciudad" required />
              </div>
            </div>
        
            <!-- Colonia y calle -->
            <div class="flex gap-4">
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="fromSettlement" [(ngModel)]="from.settlement" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Colonia" required />
              </div>
          
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="fromStreet" [(ngModel)]="from.street" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Calle" required />
              </div>
            </div>
        
            <!-- Número exterior e interior -->
            <div class="flex gap-4 mb-4">
              <div class="w-1/2">
                <input type="text" name="fromExternalNumber" [(ngModel)]="from.external_number" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Número Exterior" required />
              </div>
              <div class="w-1/2">
                <input type="text" name="fromInternalNumber" [(ngModel)]="from.internal_number" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Número Interior" />
              </div>
            </div>
            
            <!-- Referencia -->
            <div class="relative group mb-4">
              <input type="text" name="fromReference" [(ngModel)]="from.reference" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Referencia" />
            </div>
          </form>

          <!-- Botones -->
          <div class="flex flex-col justify-center mt-6 gap-4">
            <button (click)="saveAddress('remitente')" type="button" class="bg-[#D6542B] text-white font-bold py-3 rounded-full w-full">
              GUARDAR DIRECCIÓN
            </button>
            <button (click)="openAddressDialog('remitente')" type="button" class="border-2 border-[#D6542B] text-[#D6542B] font-bold py-3 rounded-full w-full">
              SELECCIONAR DIRECCIÓN
            </button>
          </div>
        </div>

        <!-- Formulario del Destinatario -->        
        <div>
          <h2 class="font-extrabold text-3xl text-[#332D69] text-center mb-6">DESTINATARIO</h2>
          <form #toForm="ngForm">
            <div class="relative group mb-4">
              <input #toAddressInput type="text" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Dirección" />                
            </div>
            <!-- Nombre completo y correo -->
            <div class="flex gap-4">
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="toName" [(ngModel)]="to.name" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Nombre Completo" required />
              </div>          
              <div class="relative w-1/2 group mb-4">
                <input type="email" name="toEmail" [(ngModel)]="to.email" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="E-mail" required />
              </div>
            </div>
          
            <!-- Teléfono -->
            <div class="relative group mb-4">
              <input type="tel" name="toPhone" [(ngModel)]="to.phone" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Teléfono" required />
            </div>
          
            <!-- País y código postal -->
            <div class="flex gap-4">
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="toCountry" [(ngModel)]="to.country" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="País" required />
              </div>          
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="toZipCode" [(ngModel)]="to.zip_code" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="C.P." required />
              </div>
            </div>
          
            <!-- Estado y ciudad -->
            <div class="flex gap-4">
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="toState" [(ngModel)]="to.state" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Estado" required />
              </div>
            
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="toCity" [(ngModel)]="to.city" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Ciudad" required />
              </div>
            </div>
          
            <!-- Colonia y calle -->
            <div class="flex gap-4">
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="toSettlement" [(ngModel)]="to.settlement" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Colonia" required />
              </div>
            
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="toStreet" [(ngModel)]="to.street" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Calle" required />
              </div>
            </div>
          
            <!-- Número exterior e interior -->
            <div class="flex gap-4 mb-4">
              <div class="w-1/2">
                <input type="text" name="toExternalNumber" [(ngModel)]="to.external_number" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Número Exterior" required />
              </div>
              <div class="w-1/2">
                <input type="text" name="toInternalNumber" [(ngModel)]="to.internal_number" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Número Interior" />
              </div>
            </div>
            
            <!-- Referencia -->
            <div class="relative group mb-4">
              <input type="text" name="toReference" [(ngModel)]="to.reference" class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]" placeholder="Referencia" />
            </div>
          </form>

          <!-- Botones -->
          <div class="flex flex-col justify-center mt-6 gap-4">
            <button (click)="saveAddress('destinatario')" type="button" class="bg-[#D6542B] text-white font-bold py-3 rounded-full w-full">
              GUARDAR DIRECCIÓN
            </button>
            <button (click)="openAddressDialog('destinatario')" type="button" class="border-2 border-[#D6542B] text-[#D6542B] font-bold py-3 rounded-full w-full">
              SELECCIONAR DIRECCIÓN
            </button>
          </div>          
        </div>
      </div>   
    </div>    
  </div>
  
  
  <!-- Botón de Continuar Fuera del Formulario -->
  <div class="flex justify-center mt-8">
    <button (click)="onContinue()" type="button" class="bg-[#2C2851] text-white font-bold py-3 px-8 rounded-full">
      CONTINUAR
    </button>
  </div>
</div>

  <div class="dagfriend-container">
        <button 
          (click)="toggleBotInstructions()" 
          class="dagfriend-button"
        >
          DAGFRIEND
        </button>
      
    <div *ngIf="showBotInstructions" class="mt-4">
      <img src="assets/bot-instructions.png" alt="Bot Instructions" class="max-w-xs">
    </div>
  </div>




  <div *ngIf="isAddressDialogOpen" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
    <div class="bg-[#F0F0F0] p-6 rounded-3xl shadow-xl w-full max-w-lg">
      <div class="flex justify-between items-center mb-6">
        <h2 class="text-2xl font-extrabold text-[#2C2851]">DIRECCIONES GUARDADAS</h2>
        <button (click)="closeAddressDialog()" class="text-[#2C2851] hover:text-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
  
      <div class="mb-4">
        <input 
          type="text" 
          [(ngModel)]="searchTerm" 
          (ngModelChange)="filterAddresses()"
          placeholder="Nombre" 
          class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]"
        >
      </div>
  
      <div class="max-h-96 overflow-y-auto">
        <ul *ngIf="filteredAddresses.length > 0; else noAddresses">
          <li *ngFor="let address of filteredAddresses" class="mb-4">
            <button (click)="selectAddress(address)" class="w-full text-left p-2">
              <div class="font-bold text-[#2C2851]">{{ address.name }}</div>
              <div class="text-sm text-gray-600">
                {{ address.street }}, {{ address.city }}, {{ address.state }}, CP {{ address.zip_code }}
              </div>
            </button>
          </li>
        </ul>
        <ng-template #noAddresses>
          <p class="text-center text-gray-500">No se encontraron direcciones.</p>
        </ng-template>
      </div>
    </div>
  </div>
  