import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthLoginService } from './users/auth-login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthRoutesService implements CanActivate {

  constructor(
    private authService: AuthLoginService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.isLogged()) {
      this.router.navigate(['/login']);
      return false;
    }
    const requiredRole = route.data['role'] as string;
    if (requiredRole && !this.authService.hasRole(requiredRole)) {
      this.router.navigate(['/access-denied']);
      return false;
    }
    return true;
  }
  
}