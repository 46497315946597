<div class="h-full ml-64 mt-14 bg-gray-50">
    <div class="p-6 flex justify-between items-center bg-white shadow-sm">
        <div class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-dagblue">
                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0l-3-3m3 3l3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
            </svg>
            <span class="font-bold uppercase text-3xl text-dagblue"> Lockers </span>
        </div>    
        <button (click)="openAddLocker()" class="flex gap-2 rounded-lg bg-dagblue p-3 text-white hover:bg-dagblue-dark transition duration-300 shadow-md">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>          
            Nuevo locker        
        </button>
    </div>
    <div class="p-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
     
          
          

        <!-- Locker activo 1 -->
        <div  *ngFor="let locker of lockers" class="bg-white border-4  {{ locker.status ? 'border-emerald-500': ' border-red-500'}}  gap-6 flex flex-col hover:bg-gradient-to-tr hover:cursor-pointer justify-between p-6 rounded-[30px] shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105">
            <div class="flex justify-between">
                <span class="font-bold bg-gradient-to-tr {{ locker.status ? 'from-emerald-500 to-emerald-700' : 'from-red-500 to-red-700' }} text-white px-3 py-1 text-sm flex justify-center items-center rounded-full shadow">
                    {{ locker.status ? 'Online' : 'Offline' }}
                </span>
                <button [routerLink]="['/details-locker', locker._id]" class="p-2 flex items-center rounded-full hover:bg-emerald-100 transition duration-300">
                    <span class="px-2 font-semibold {{ locker.status ? 'text-emerald-700' : 'text-red-700'}}"> Detalles </span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 {{ locker.status ? 'text-emerald-700' : 'text-red-700'}}">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>                                 
                </button>
            </div>
            <div class="flex gap-2 pl-2">                
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-600">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                </svg>
                <span class="font-bold text-lg text-gray-800">{{ locker.ubication }}</span>                
            </div>
            <div class="grid grid-cols-3 gap-4">        
                <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg {{locker.status ? ' hover:bg-emerald-50' :' hover:bg-red-50'}} transition duration-300">
                    <div class="flex items-center {{locker.status ? 'text-emerald-600':'text-red-600'}}">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                        </svg>                      
                        {{ locker.capacity }}
                    </div>
                    <span class="text-xs font-semibold  {{locker.status ? 'bg-emerald-100 text-emerald-800' :'bg-red-100 text-red-800'}}  rounded-full px-2 py-1"> 
                        Disponibles
                    </span>
                </button>                                  
                <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg {{locker.status ? 'hover:bg-yellow-50':'hover:bg-red-50'}} transition duration-300">
                    <div class="flex items-center {{locker.status ? 'text-yellow-600' :' text-red-600'}}">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0-3-3m3 3 3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                        </svg>                                                
                        {{ locker.saturation }}
                    </div>
                    <span class="text-xs font-semibold {{locker.status ? 'bg-yellow-100 text-yellow-800': 'bg-red-100 text-red-800'}} rounded-full px-2 py-1"> Ocupadas </span>
                </button>  
                <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg {{locker.status ? 'hover:bg-gray-50': 'hover:bg-red-50'}} transition duration-300">
                    <div class="flex items-center {{locker.status ? 'text-gray-600' : 'text-red-600'}}">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                        </svg>                      
                        {{ locker.quant_gabetas }}
                    </div>
                    <span class="text-xs font-semibold {{locker.status ? 'bg-gray-100 text-gray-800':'bg-red-100 text-red-800'}} rounded-full px-2 py-1"> Total </span>
                </button>  
            </div>
        </div>
     
  
    
              
         

 <!-- Modal para agregar nuevo locker -->
<!-- Modal para agregar nuevo locker -->
<div *ngIf="showAddLocker" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
    <div class="relative top-20 mx-auto p-5 border w-fit shadow-lg rounded-md bg-white">
        <h3 class="text-lg font-bold mb-4">Agregar Nuevo Locker</h3>
        <form #lockerForm="ngForm" class=" gap-2 w-full">
            <!-- Locker ID -->
      <div class="grid grid-cols-3 gap-5 w-auto">
        <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="newIdLocker">
                Locker ID
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newIdLocker" type="text" [(ngModel)]="newLocker.id_locker" name="id_locker" required #idLocker="ngModel">
                <p *ngIf="idLocker.invalid && (idLocker.dirty || idLocker.touched)" class="text-red-500 text-xs italic">Por favor, llena este campo</p>

        </div>

        <!-- Ubicación -->
        <div class="mb-4  ">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="newUbication">
                Ubicación
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newUbication" type="text" [(ngModel)]="newLocker.ubication" name="ubication" required #ubication="ngModel">
                <p *ngIf="ubication.invalid && (ubication.dirty || ubication.touched)" class="text-red-500 text-xs italic">Por favor, llena este campo</p>

        </div>

        <!-- Paquete -->
        <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="newPackage">
                Paquete
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newPackage" type="text" [(ngModel)]="newLocker.package" name="package">
        </div>

        <!-- Cantidad de Gabetas -->
        <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="newQuantGabetas">
                Cantidad de Gabetas
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newQuantGabetas" type="number" [(ngModel)]="newLocker.quant_gabetas" name="quant_gabetas" required #cantidad_gabetas="ngModel">
                <p *ngIf="cantidad_gabetas.invalid && (cantidad_gabetas.dirty || cantidad_gabetas.touched)" class="text-red-500 text-xs italic">Por favor, llena este campo</p>
        </div>
        <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="newLatitud">
                latitud
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newLatitud" type="number" [(ngModel)]="newLocker.lat" name="latitud"
                required #latitud="ngModel"
                [min]="0.000001"
                [max]="180"
                >
                <p *ngIf="latitud.invalid && (latitud.dirty || latitud.touched)" class="text-red-500 text-xs italic">Por favor, llena este campo</p>
        </div>
        <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="newLongitud">
                longitud
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newLongitud" type="number" [(ngModel)]="newLocker.long" name="longitud"
                required #longitud="ngModel"
                [min]="0.000001"
                [max]="180"
                >
                <p *ngIf="longitud.invalid && (longitud.dirty || longitud.touched )" class="text-red-500 text-xs italic">Por favor, llena este campo</p>
        </div>

        <!-- Estado -->
        <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="newStatus">
            Estatus
            </label>
            <select class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="newStatus" [(ngModel)]="newLocker.status" name="status"
            required #status="ngModel"
            >
            <option value="true">Activo</option>
            <option value="false">Apagado</option>
            </select>
            <p *ngIf="status.invalid && (status.dirty || status.touched)" class="text-red-500 text-xs italic">Por favor, llena este campo</p>
        </div>

        <!-- Capacidad -->
        <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="newCapacity">
                Capacidad
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newCapacity" type="number" [(ngModel)]="newLocker.capacity" name="capacity"
                required #capacity="ngModel"
                [min]="1"
                [max]="100"
                >
               
                <p *ngIf="capacity.invalid && (capacity.dirty || capacity.touched)" class="text-red-500 text-xs italic">Por favor, llena este campo</p>
                <p *ngIf="capacity.errors?.['max'] && (capacity.dirty || capacity.touched)" 
       class="text-red-500 text-xs italic">La capacidad no puede exceder de 100</p>
        </div>

        <!-- Saturación -->
        <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="newSaturation">
                Saturación
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newSaturation" type="number" [(ngModel)]="newLocker.saturation" name="saturation"
                required #saturation="ngModel"
                [min]="0"
                [max]="100"
                >
                <p *ngIf="saturation.invalid && (saturation.dirty || saturation.touched)" class="text-red-500 text-xs italic">Por favor, llena este campo</p>
                <p *ngIf="capacity.errors?.['max'] && (capacity.dirty || capacity.touched)" 
                class="text-red-500 text-xs italic">La capacidad no puede exceder de 100</p>
        </div>
        <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="newStreet">
                Calle
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newStreet" type="text" [(ngModel)]="newLocker.street" name="street"
                required #street="ngModel"
                >
                <p *ngIf="street.invalid && (street.dirty || street.touched)" class="text-red-500 text-xs italic">Por favor, llena este campo</p>
        </div>
        <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="newZipCode">
                Codigo Postal   
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newZipCode" type="number" [(ngModel)]="newLocker.cp" name="zipCode"
                required #zipCode="ngModel"
                >
                <p *ngIf="zipCode.invalid && (zipCode.dirty || zipCode.touched)" class="text-red-500 text-xs italic">Por favor, llena este campo</p>
        </div>
        <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="newCity">
                Ciudad   
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newCity" type="text" [(ngModel)]="newLocker.city" name="city"
                required #city="ngModel"
                >
                <p *ngIf="city.invalid && (city.dirty || city.touched)" class="text-red-500 text-xs italic">Por favor, llena este campo</p>
        </div>
        <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="newState">
                Estado   
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newState" type="text" [(ngModel)]="newLocker.state" name="state"
                required #state="ngModel"
                >
                <p *ngIf="state.invalid && (state.dirty || state.touched)" class="text-red-500 text-xs italic">Por favor, llena este campo</p>
        </div>
        <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="newCountry">
                Pais   
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newCountry" type="text" [(ngModel)]="newLocker.country" name="state"
                required #country="ngModel"
                >
                <p *ngIf="country.invalid && (country.dirty || country.touched)" class="text-red-500 text-xs italic">Por favor, llena este campo</p>
        </div>
        <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="newNumExt">
                Numero Exterior
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newNumExt"type="text" [(ngModel)]="newLocker.num_ext" name="num_ext"
                required #num_ext="ngModel"
                min="0"
                
                >
                <p *ngIf="num_ext.invalid && (num_ext.dirty || num_ext.touched)" class="text-red-500 text-xs italic">Por favor, llena este campo</p>
        </div>

        
      </div>

            <!-- Botones de acción -->
            <div class="flex items-center justify-between">
                <button class="bg-dagblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" (click)="addLocker(lockerForm)">
                    Agregar
                </button>
                <button class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" (click)="closeAddLocker()">
                    Cancelar
                </button>
            </div>
        </form>
    </div>
</div>








                

    <!-- Lockers Inactivos 
    <div class="mt-12 p-6 bg-gray-100 w-{}">
        <div class="flex items-center gap-2 mb-6">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-7 text-gray-600">
                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
            </svg>
            <span class="font-bold uppercase text-2xl text-gray-700"> Lockers inactivos </span>    
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
    -->
