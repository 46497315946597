<app-sidebar></app-sidebar>
<div class="ml-64 mt-14">
    <div class="p-6">
        <div class="font-bold text-dagblue">                                      
            <div class="flex justify-between gap-2  items-center">
                <div class="flex">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                    </svg>
                    <span> Plaza del Sol </span>                  
                </div>                    
                <div>                    
                    <!-- toggle-switch.component.html -->
                    <label class="toggle-switch">
                        <input type="checkbox" [checked]="isChecked" (change)="onToggle()">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>            
        </div>
        <div class="mt-6 w-full">
            <div class="flex justify-between items-center">
                <div class="flex flex-col">
                    <span class="text-dagpk font-bold"> Dimensiones </span>
                    <span class="font-bold"> Ultima actualizacion hace 2 meses </span>
                </div>
                <div class="">
                    <button class="flex rounded-lg text-white bg-dagblue p-2">
                        Editar
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                            </svg>                          
                    </button>
                </div>
            </div>
            <div class="w-full pt-6 flex justify-between">
                <div class="flex gap-2">
                    <span class="font-bold"> Largo: </span>
                    <span> 0.00cm </span>
                </div>
                <div class="flex gap-2">
                    <span class="font-bold"> Alto: </span>
                    <span> 0.00cm </span>
                </div>
                <div class="flex gap-2">
                    <span class="font-bold"> Ancho: </span>
                    <span> 0.00cm </span>
                </div>
                <div class="flex gap-2">
                    <span class="font-bold"> Peso: </span>
                    <span> 0.00 </span>
                </div>
            </div>
            <div class="flex justify-between gap-4 pt-6">
                <div class="flex justify-start gap-4">
                    <button class="border bg-gray-200 rounded-lg hover:bg-dagpk duration-200 hover:text-white w-40"> Control </button>
                    <button class="border bg-gray-200 rounded-lg hover:bg-dagpk duration-200 hover:text-white w-40"> Ver MQTT </button>
                    <button class="border bg-gray-200 rounded-lg hover:bg-dagpk duration-200 hover:text-white w-40"> Ver Gavetas </button>
                    <button class="border bg-gray-200 rounded-lg hover:bg-dagpk duration-200 hover:text-white w-40"> Ver Usuarios </button>
                    <button class="border bg-gray-200 rounded-lg hover:bg-dagpk duration-200 hover:text-white w-40"> Registro de Servicios </button>
                </div>                
                <div class="flex justify-end items-center">
                    <select name="" id="" class="p-2 border-2 rounded-lg w-52">
                        <option value="">Selecciona una opcion </option>
                        <option value=""> Todos</option>
                        <option value=""> Disponibles </option>
                        <option value=""> Ocupadas </option>                        
                    </select>
                </div>
            </div>           
                <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
                        <thead class="text-xs uppercase bg-dagblue text-white">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Cajón
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Alto
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Ancho
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Fondo
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Conexión
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Disponibilidad
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Compuerta
                                </th>                                
                                <th scope="col" class="px-6 py-3">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white border-b  hover:bg-gray-50 ">
                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                    A1
                                </th>
                                <td class="px-6 py-4">
                                    451mm
                                </td>
                                <td class="px-6 py-4">
                                    530 mm
                                </td>
                                <td class="px-6 py-4">
                                    470 mm
                                </td>
                                <td class="px-6 py-4">
                                    <span class="text-emerald-700 bg-emerald-100 font-bold px-2 rounded-lg"> Conectado </span>
                                </td>
                                <td class="px-6 py-4">
                                    <span class="text-emerald-700 bg-emerald-100 font-bold px-2 rounded-lg"> Disponible </span>
                                </td>
                                <td class="px-6 py-4">
                                    <span class="text-red-700 bg-red-100 font-bold px-2 rounded-lg"> Cerrada </span>
                                </td>                               
                                <td class="px-6 py-4 text-center">
                                    <button class="p-2 bg-dagblue text-white rounded-lg w-24">
                                        Abrir
                                    </button>
                                </td>
                            </tr>
                            <tr class="bg-white border-b  hover:bg-gray-50 ">
                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                    A1
                                </th>
                                <td class="px-6 py-4">
                                    451mm
                                </td>
                                <td class="px-6 py-4">
                                    530 mm
                                </td>
                                <td class="px-6 py-4">
                                    470 mm
                                </td>
                                <td class="px-6 py-4">
                                    <span class="text-emerald-700 bg-emerald-100 font-bold px-2 rounded-lg"> Conectado </span>
                                </td>
                                <td class="px-6 py-4">
                                    <span class="text-emerald-700 bg-emerald-100 font-bold px-2 rounded-lg"> Disponible </span>
                                </td>
                                <td class="px-6 py-4">
                                    <span class="text-red-700 bg-red-100 font-bold px-2 rounded-lg"> Cerrada </span>
                                </td>                               
                                <td class="px-6 py-4 text-center">
                                    <button class="p-2 bg-dagblue text-white rounded-lg w-24">
                                        Abrir
                                    </button>
                                </td>
                            </tr>
                            <tr class="bg-white border-b  hover:bg-gray-50 ">
                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                    A1
                                </th>
                                <td class="px-6 py-4">
                                    451mm
                                </td>
                                <td class="px-6 py-4">
                                    530 mm
                                </td>
                                <td class="px-6 py-4">
                                    470 mm
                                </td>
                                <td class="px-6 py-4">
                                    <span class="text-emerald-700 bg-emerald-100 font-bold px-2 rounded-lg"> Conectado </span>
                                </td>
                                <td class="px-6 py-4">
                                    <span class="text-emerald-700 bg-emerald-100 font-bold px-2 rounded-lg"> Disponible </span>
                                </td>
                                <td class="px-6 py-4">
                                    <span class="text-red-700 bg-red-100 font-bold px-2 rounded-lg"> Cerrada </span>
                                </td>                               
                                <td class="px-6 py-4 text-center">
                                    <button class="p-2 bg-dagblue text-white rounded-lg w-24">
                                        Abrir
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

        </div>
    </div>
</div>