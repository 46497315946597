<app-sidebar></app-sidebar>
<div class="ml-64 pt-20">
    <div class="container mx-auto">
        <h1 class="text-3xl font-bold mb-6 text-dagblue">Historial de Cortes de Caja</h1>
      
        <!-- Filtros de fecha -->
        <div class="mb-6 bg-white p-4 rounded-lg shadow-md">
          <div class="flex flex-wrap items-end space-x-4">
            <div>
              <label for="startDate" class="block text-sm font-medium text-gray-700 mb-1">Fecha Inicio</label>
              <input type="date" id="startDate" [(ngModel)]="startDate" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            </div>
            <div>
              <label for="endDate" class="block text-sm font-medium text-gray-700 mb-1">Fecha Fin</label>
              <input type="date" id="endDate" [(ngModel)]="endDate" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            </div>
            <button (click)="applyDateFilter()" class="px-4 py-2 bg-dagpk text-white rounded-md hover:bg-orange transition duration-300">Filtrar</button>
            <button (click)="clearDateFilter()" class="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-300">Limpiar</button>
          </div>
        </div>
      
        <!-- Lista de cortes de caja -->
        <div *ngIf="loading" class="text-center py-4">
          <div class="spinner"></div>
          <p class="mt-2 text-gray-600">Cargando registros...</p>
        </div>
      
        <div *ngIf="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <p>{{ error }}</p>
        </div>
      
        <div *ngIf="!loading && cashRegisters.length === 0" class="text-center py-4 text-gray-600">
          No se encontraron registros de caja para el período seleccionado.
        </div>
      
        <div *ngIf="!loading && cashRegisters.length > 0" class="space-y-6">
          <div *ngFor="let register of cashRegisters" class="bg-white rounded-lg shadow-md overflow-hidden">
            <div class="px-6 py-4 bg-dagblue border-b border-gray-200 flex justify-between">
              <div>
                <h2 class="text-xl font-semibold text-white">
                  Corte de Caja #{{ register._id }}
                </h2>
                <p class="text-sm text-white">
                  Abierto el: {{ register.opened_at | date:'medium' }}
                  <span *ngIf="register.closed_at"> - Cerrado el: {{ register.closed_at | date:'medium' }}</span>
                </p>
              </div>
              <p class="text-lg font-medium text-white mt-2">
                Total de Ventas: {{ formatCurrency(register.total_sales) }}
              </p>
            </div>
            <div class="px-6 py-4">
              <h3 class="text-lg font-semibold text-gray-700 mb-3">Transacciones</h3>
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Método de Pago</th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Monto</th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fecha</th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr *ngFor="let transaction of register.transactions" class="hover:bg-gray-50">
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{{ transaction.payment_method }}</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      <span [ngClass]="{'text-green-600': transaction.amount.$numberDecimal > 0, 'text-red-600': transaction.amount.$numberDecimal < 0}">
                        {{ formatCurrency(transaction.amount.$numberDecimal) }}
                      </span>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ transaction.createdAt | date:'short' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      
        <!-- Paginación -->
        <div *ngIf="totalPages > 1" class="mt-6 flex justify-between items-center bg-white p-4 rounded-lg shadow">
          <div class="text-sm text-gray-700">
            Mostrando <span class="font-medium">{{ (currentPage - 1) * itemsPerPage + 1 }}</span> a 
            <span class="font-medium">{{ Math.min(currentPage * itemsPerPage, totalItems) }}</span> de 
            <span class="font-medium">{{ totalItems }}</span> registros
          </div>
          <div class="flex space-x-2">
            <button 
              (click)="onPageChange(currentPage - 1)" 
              [disabled]="currentPage === 1"
              class="px-3 py-1 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition duration-300 ease-in-out"
            >
              Anterior
            </button>
            <button 
              (click)="onPageChange(currentPage + 1)" 
              [disabled]="currentPage === totalPages"
              class="px-3 py-1 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition duration-300 ease-in-out"
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
</div>