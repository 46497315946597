import { Component, OnInit } from '@angular/core';
import { TtsService } from 'src/app/_services/tts.service';

@Component({
  selector: 'app-shipping-kiosk',
  templateUrl: './shipping-kiosk.component.html',
  styleUrls: ['./shipping-kiosk.component.scss']
})
export class ShippingKioskComponent implements OnInit {
  currentStep: number = 1;
  packageWeight: number = 0;
  packageDimensions = { length: 0, width: 0, height: 0 };
  origin: string = '';
  destination: string = '';
  estimatedCost: number = 0;
  selectedCourier: string = '';

  stepInstructions: { [key: number]: string } = {
    1: 'Por favor, coloque el paquete dentro de la báscula.',
    2: 'Ahora, use el escáner lateral para medir las dimensiones del paquete. verifique las medidas e ingrese un codigo postal de destino',
    3: 'Seleccione la paqueteria de su preferencia.',
    4: 'Seleccione el destino de su envío.',
    5: 'Elija la paquetería de su preferencia.'    
  };

  constructor(private ttsService: TtsService) {}

  ngOnInit() {
    this.speakInstructions();
  }

  speakInstructions() {
    const instruction = this.stepInstructions[this.currentStep];
    if (instruction) {
      this.ttsService.speak(instruction);
    }
  }

  nextStep() {
    if (this.currentStep < Object.keys(this.stepInstructions).length) {
      this.currentStep++;
      this.speakInstructions();
    } else {
      this.finalize();
    }
  }

  previousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
      this.speakInstructions();
    }
  }

  updatePackageDetails(weight: number, dimensions: { length: number; width: number; height: number }) {
    this.packageWeight = weight;
    this.packageDimensions = dimensions;
  }

  updateDestination(dest: string) {
    this.destination = dest;
    this.calculateEstimatedCost();
  }

  calculateEstimatedCost() {
    // Aquí iría la lógica para calcular el costo estimado
    // Por ahora, usamos un valor de ejemplo
    this.estimatedCost = 100 + (this.packageWeight * 2);
  }

  selectCourier(courier: string) {
    this.selectedCourier = courier;
  }

  finalize() {
    console.log('Envío finalizado', this.getShipmentDetails());

  }

  private getShipmentDetails() {
    return {
      weight: this.packageWeight,
      dimensions: this.packageDimensions,
      origin: this.origin,
      destination: this.destination,
      courier: this.selectedCourier,
      cost: this.estimatedCost
    };
  }
}