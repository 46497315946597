<app-sidebar></app-sidebar>
<div class="ml-64 mt-14">
  
    <div class="p-6">
      
        <span class="font-bold text-dagblue uppercase text-2xl">Detalles del Envío</span>
        <!--Seccion de arriba-->
        <div *ngIf="showSuccessAlert || showErrorAlert" class="mt-4">
          <app-success-alert *ngIf="showSuccessAlert" [message]="alertMessage"></app-success-alert>
          <app-error-alert *ngIf="showErrorAlert" [message]="alertMessage"></app-error-alert>
        </div>
        <div class="bg-white shadow-md rounded-lg p-4 mb-6 flex items-center justify-between">
          
            <div class="flex items-center">
                <div class="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center mr-4">
                    <span class="text-red-500 font-bold text-2xl">RK</span>
                </div>
                <div>
                    <h3 class="font-bold text-xl">{{shipmentDetails.provider}}</h3>
                    <p class="text-2xl font-bold"> ${{ shipmentDetails.price.$numberDecimal }}</p>
                    <p class="text-sm text-gray-600">Dagpacket Code: {{shipmentDetails.payment.transaction_id}}</p>
                    <p class="text-sm text-gray-600">Tracking N°: {{shipmentDetails.guide_number}}</p>
                </div>
            </div>
            <div class="flex flex-col items-end ml-auto">
              <button class="bg-dagblue text-white px-4 py-2 rounded mb-2">
                  Pedir Factura
              </button>             
              <button class="bg-red-500 text-white px-4 py-2 rounded" (click)="openCancelDialog()">
                  Solicitar Cancelación
              </button>
          </div>
        </div>
        <!--Seccion de enmedio-->
        <div class="overflow-x-auto shadow-md sm:rounded-lg w-full my-4">
          <table class="w-full text-sm text-left text-gray-500">
              <tbody class="text-gray-900 text-medium">
                  <tr class="bg-white border-b">
                      <td class="px-6 py-4 font-medium">Tipo de envío:</td>
                      <td class="px-6 py-4">{{shipmentDetails.shipment_type}}</td>
                  </tr>
                  <!-- Nuevas filas para los datos del paquete -->
                  <tr class="bg-gray-50 border-b">
                      <td class="px-6 py-4 font-medium">Peso del paquete:</td>
                      <td class="px-6 py-4">{{shipmentDetails.shipment_data.package_weight}} kg</td>
                  </tr>
                  <tr class="bg-white border-b">
                      <td class="px-6 py-4 font-medium">Dimensiones (alto x ancho x largo):</td>
                      <td class="px-6 py-4">
                          {{shipmentDetails.shipment_data.height}} x 
                          {{shipmentDetails.shipment_data.width}} x 
                          {{shipmentDetails.shipment_data.length}} cm
                      </td>
                  </tr>
                  <tr class="bg-gray-50 border-b">
                    <td class="px-6 py-4 font-medium">Peso volumentrico:</td>
                    <td class="px-6 py-4">{{shipmentDetails.shipment_data.volumetric_weight}}</td>
                </tr>
                  <tr class="bg-white border-b">
                      <td class="px-6 py-4 font-medium">Empaquetado:</td>
                      <td class="px-6 py-4">{{shipmentDetails.packing.answer}}</td>
                  </tr>
                  <!-- El resto de las filas se mantienen igual -->
                  <tr class="bg-gray-50 border-b">
                      <td class="px-6 py-4 font-medium">Precio total:</td>
                      <td class="px-6 py-4 font-bold">${{ shipmentDetails.price.$numberDecimal }}</td>
                  </tr>
                  <tr class="bg-white border-b">
                      <td class="px-6 py-4 font-medium">Seguro:</td>
                      <td class="px-6 py-4" *ngIf="shipmentDetails.insurance.$numberDecimal == 0"> No asegurado </td>
                      <td class="px-6 py-4" *ngIf="shipmentDetails.insurance.$numberDecimal > 0"> {{shipmentDetails.insurance.$numberDecimal }} </td>
                  </tr>
                  <tr class="bg-gray-50 border-b">
                      <td class="px-6 py-4 font-medium">Estado de pago:</td>
                      <td class="px-6 py-4">
                          <span [ngClass]="getPaymentStatusClass(shipmentDetails.payment.status)"
                                class="text-white px-2 font-bold rounded-lg">
                              {{shipmentDetails.payment.status}}
                          </span>
                      </td>
                  </tr>
                  <tr class="bg-white border-b">
                      <td class="px-6 py-4 font-medium">Método de pago:</td>
                      <td class="px-6 py-4">{{shipmentDetails.payment.method}}</td>
                  </tr>
                  <tr class="bg-gray-50 border-b">
                    <td class="px-6 py-4 font-medium">Estado de la guía:</td>
                    <td class="px-6 py-4">
                        <span [ngClass]="{'text-green-500': shipmentDetails.guide, 'text-yellow-500': !shipmentDetails.guide}">
                            {{ shipmentDetails.guide ? 'Generada' : 'No generada' }}
                        </span>
                    </td>
                </tr>
              </tbody>
          </table>
      </div>
        <div class="flex gap-2 mt-4">                   
          <!-- Botón para ver/imprimir la guía (visible solo cuando la guía está generada) -->
          <a 
             [href]="shipmentDetails.guide" 
             target="_blank" 
             class="bg-green-500 text-white px-4 py-2 rounded inline-block">
              Ver/Imprimir Guía
          </a>          
          <!-- Otros botones... -->
          <button (click)="generateReceipt()" class="bg-orange-500 text-white px-4 py-2 rounded">
            Recibo
        </button>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-6">
            <div class="border p-4 rounded">
                <h3 class="font-bold mb-2">Remitente</h3>
                <p>Nombre: {{shipmentDetails.from.name}}</p>
                <p>Calle: {{shipmentDetails.from.street}}</p>
                <p>Colonia: {{shipmentDetails.from.settlement}}</p>
                <p>Ciudad: {{shipmentDetails.from.city}}</p>
                <p>C.p.: {{shipmentDetails.from.zip_code}}</p>
                <p>Teléfono: {{shipmentDetails.from.phone}}</p>
                <p>Email: {{shipmentDetails.from.email}}</p>
            </div>
            <div class="border p-4 rounded">
                <h3 class="font-bold mb-2">Destinatario</h3>
                <p>Nombre: {{shipmentDetails.to.name}}</p>
                <p>Calle: {{shipmentDetails.to.street}}</p>
                <p>Colonia: {{shipmentDetails.to.settlement}}</p>
                <p>Ciudad: {{shipmentDetails.to.city}}</p>
                <p>C.p.: {{shipmentDetails.to.zip_code}}</p>
                <p>Teléfono: {{shipmentDetails.to.phone}}</p>
                <p>Email: {{shipmentDetails.to.email}}</p>
            </div>
        </div>
    </div>
</div>
<div class="ml-64 mt-14">
    <div class="p-6">

 <!-- Sección de Rastreo -->
 <div class="mt-0">
    <span class="font-bold text-dagblue uppercase text-2xl">Rastreo</span>
    <div class="overflow-x-auto shadow-md sm:rounded-lg w-full my-4">
      <table class="w-full text-sm text-left text-gray-500">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" class="px-6 py-3">Título</th>
            <th scope="col" class="px-6 py-3">Fecha</th>
            <th scope="col" class="px-6 py-3">Área</th>
            <th scope="col" class="px-6 py-3">Descripción</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="trackingDetails?.length > 0; else noResults">
            <tr class="bg-white border-b" *ngFor="let tracking of paginatedTrackingDetails">
              <td class="px-6 py-4">{{tracking.title}}</td>
              <td class="px-6 py-4">{{tracking.date | date:'dd/MM/yy'}}</td>
              <td class="px-6 py-4">{{tracking.area}}</td>
              <td class="px-6 py-4">{{tracking.description}}</td>
            </tr>
          </ng-container>
          <ng-template #noResults>
            <tr class="bg-white border-b">
              <td colspan="4" class="px-6 py-4 text-center">
                Not found
                <br> No results found for "". Try checking for typos or using complete words.
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
    <div class="flex items-center justify-end mt-4">
      <span class="mr-4">{{(currentPage - 1) * itemsPerPage + 1}}-{{math.min(currentPage * itemsPerPage, trackingDetails.length)}} of {{trackingDetails.length}}</span>
      <button class="px-2 py-1 border rounded mr-2" [disabled]="currentPage === 1" (click)="prevPage()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
      </button>
      <button class="px-2 py-1 border rounded" [disabled]="currentPage * itemsPerPage >= trackingDetails.length" (click)="nextPage()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
      </button>
    </div>
  </div>
</div>
</div>

<div *ngIf="showCancelDialog" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto flex justify-center items-center h-full w-full">
  <div class="relative p-5 border w-96 shadow-lg rounded-md bg-white">
      <h3 class="text-lg font-bold mb-4">Solicitar Cancelación</h3>
      
      <app-success-alert *ngIf="showSuccessAlert" [message]="alertMessage"></app-success-alert>
      <app-error-alert *ngIf="showErrorAlert" [message]="alertMessage"></app-error-alert>

      <form #cancellationForm="ngForm" (ngSubmit)="createCancellationRequest(cancellationForm)">
        <p class="mb-2">Dagpacket Code: {{shipmentDetails.payment.transaction_id}}</p>
        <input type="hidden" [ngModel]="shipmentDetails.user_id" name="user_id" required>
        <input type="hidden" [ngModel]="shipmentDetails._id" name="shipment_id" required>
        <div class="mb-4">
            <label for="cancellationReason" class="block text-gray-700 text-sm font-bold mb-2">Motivo de cancelación:</label>
            <textarea id="cancellationReason" name="motive" rows="4" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" ngModel required></textarea>
        </div>
        <div class="flex items-center justify-between">
            <button class="bg-dagblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" [disabled]="!cancellationForm.form.valid">
                Enviar
            </button>
            <button class="bg-red-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" (click)="closeCancelDialog()">
                Cancelar
            </button>
        </div>
      </form>
  </div>
</div>