import { Component, OnInit } from '@angular/core';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import { Users } from 'src/app/_models/user-model';
import { UserServiceService } from 'src/app/_services/users/user-service.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit{
  
  constructor(
    private router: Router,
    private userService: UserServiceService,
    private authService: AuthLoginService
  ){}

  user: any = {};
  selectedFile: File | null = null;
  success: boolean = false;
  error: boolean = false;
  message: string = '';
  id: any;

  ngOnInit(): void {
    this.authService.userProfile().subscribe(
      (res) =>{
        this.user = res.data;
      }
    )
  }


  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0] as File;

    if (this.selectedFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
          this.user.image = e.target.result; // Guarda la imagen en base64
      };
      reader.readAsDataURL(this.selectedFile);
      console.log('Archivo seleccionado:', this.selectedFile); // Verifica el archivo seleccionado
  } else {
      console.error('No se ha seleccionado ningún archivo.');
  }
  }

  

 
  

  updateProfilePicture(): void {
    if (this.selectedFile) {
      this.id = this.authService.getId();
      this.userService.updateProfilePicture(this.id, this.selectedFile).subscribe(
        response => {
          console.log('Foto de perfil actualizada', response);
          this.success = true;
          this.message = 'Foto de perfil actualizada con éxito';  
          setTimeout(() => {
            this.success = false;
            window.location.reload();
          },1800);       
        },
        error => {
          console.error('Error al actualizar la foto de perfil', error);
          this.error = true;
          this.message = 'Error al actualizar la foto de perfil';
        }
      );
    } else {
      console.log('No se ha seleccionado ningún archivo');
    }
  }

  updateInfo(updateForm: NgForm) {
    this.authService.updateUser(updateForm.value).subscribe(
      (res) => {
        if (res.success === true) {
          this.message = res.message;
          this.success = true;
          setTimeout(() => {
            this.success = false;
            window.location.reload();
          },1800);
        } else {
          this.message = res.message;
        }
      },
      (error) => {
        console.log('Error al actualizar la información del usuario:', error);
      }
    );
  }
}