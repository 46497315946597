import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';

@Component({
  selector: 'app-shipment-status',
  templateUrl: './shipment-status.component.html',
  styleUrls: ['./shipment-status.component.scss']
})
export class ShipmentStatusComponent implements OnInit {
  packages: any[] = [];
  currentPage: number = 1;
  pageSize: number = 10;
  totalItems: number = 0;
  totalPages: number = 0;
  Math: any = Math;
  sortBy: string = 'distribution_at';
  sortOrder: 'asc' | 'desc' = 'desc';

  startDate: string = '';
  endDate: string = '';
  statusFilter: string = '';

  constructor(
    private shipmentsService: ShipmentsService,
    private authService: AuthLoginService,
    private router: Router
  ) {
    this.Math = Math;
  }

  ngOnInit(): void {
    this.loadShipments();
  }

  loadShipments() {
    this.shipmentsService.getUserShipments(
      this.currentPage,
      this.pageSize,
      this.sortBy,
      this.sortOrder
    ).subscribe(
      response => {
        if (response.success) {
          this.packages = response.data.shipments;
          this.totalItems = response.data.totalShipments;
          this.totalPages = response.data.totalPages;
        } else {
          console.error('Error loading shipments:', response.message);
        }
      },
      error => console.error('Error', error)
    );
  }

  changePage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.loadShipments();
    }
  }

  changeSort(column: string) {
    if (this.sortBy === column) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortBy = column;
      this.sortOrder = 'asc';
    }
    this.currentPage = 1;
    this.loadShipments();
  }

  applyFilters() {
    // Nota: Esta funcionalidad de filtrado debe implementarse en el backend
    // Por ahora, solo recargamos los envíos
    this.currentPage = 1;
    this.loadShipments();
  }

  getPaymentStatusClass(status: string): string {
    switch (status) {
      case 'Pagado':
        return 'bg-green-500';
      case 'Pendiente':
        return 'bg-orange-500';
      case 'Cancelado':
        return 'bg-red-600';
      case 'Reembolsado':
        return 'bg-blue-500';
      default:
        return 'bg-gray-500';
    }
  }

  getStatus(status: string): string {
    switch (status) {
      case 'En recolección':
        return 'bg-blue-500';
      case 'En reparto':
        return 'bg-orange-500';
      case 'Cancelado':
        return 'bg-red-600';      
      default:
        return 'bg-gray-500';
    }
  }

  pageRange(): number[] {
    const range = 2;
    const start = Math.max(1, this.currentPage - range);
    const end = Math.min(this.totalPages, this.currentPage + range);
    return Array.from({length: (end - start + 1)}, (_, i) => start + i);
  }

  viewDetails(shipmentId: string) {
    console.log('Navigating to details for shipment:', shipmentId);
    this.router.navigate(['/shipment-details', shipmentId]);
  }

  updateStartDate(event: Event) {
    this.startDate = (event.target as HTMLInputElement).value;
  }
  
  updateEndDate(event: Event) {
    this.endDate = (event.target as HTMLInputElement).value;
  }
  
  updateStatusFilter(event: Event) {
    this.statusFilter = (event.target as HTMLSelectElement).value;
  }

  clearDateFilter(): void {
    this.startDate = '';
    this.endDate = '';
    this.statusFilter = '';
    this.currentPage = 1;
    this.loadShipments();
  }
}