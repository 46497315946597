import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inversor',
  templateUrl: './inversor.component.html',
  styleUrls: ['./inversor.component.scss']
})
export class InversorComponent implements OnInit {
  totalClientes: number = 0;
  clientesHoy: number = 0;
  clientesAyer: number = 0;
  clientesSemanaPasada: number = 0;
  lockerInfo: string = '';
  lockers: { name: string, status: string }[] = [];

  constructor() { }

  ngOnInit(): void {
    // Aquí puedes hacer la llamada a tu backend para obtener los datos
    this.totalClientes = 3462;
    this.clientesHoy = 5;
    this.clientesAyer = -2;
    this.clientesSemanaPasada = 10;
    this.lockerInfo = 'Disponible';
    this.lockers = [
      { name: 'Locker 1', status: 'En operación' },
      { name: 'Locker 2', status: 'En operación' },
      { name: 'Locker 3', status: 'En operación' }
    ];
  }
}