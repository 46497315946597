<div class="grid grid-cols-5 w-full h-16">
    <div onclick="stepChange(1)" class="stepBtn1 bg-gray-100 flex justify-center items-center cursor-pointer active">
        <h3 class="text-xl">1. CP de Destino</h3>
    </div>
    <div onclick="stepChange(2)" class="stepBtn2 bg-gray-200 flex justify-center items-center cursor-pointer">
        <h3 class="text-xl">2. Pesa tu paquete</h3>
    </div>
    <div onclick="stepChange(3)" class="stepBtn3 bg-gray-300 flex justify-center items-center cursor-pointer">
        <h3 class="text-xl">3. Elige tu paquetería</h3>
    </div>
    <div onclick="stepChange(4)" class="stepBtn4 bg-gray-400 flex justify-center items-center cursor-pointer">
        <h3 class="text-xl">4. Checkout</h3>
    </div>
    <div onclick="stepChange(5)" class="stepBtn5 bg-gray-500 flex justify-center items-center cursor-pointer">
        <h3 class="text-xl">5. Ingresa tu paquete</h3>
    </div>
</div>

<main class="fixed h-screen w-screen flex justify-center items-center">
    <img src="../../../assets/locker_images/logo.webp" alt="DagPacket Logo" class="fixed top-16 w-1/6 mt-4">
    <a onclick="finalizarPesado()" href="locker-screen" class="fixed top-16 left-4 z-40 w-1/6 mt-4 bg-gray-300 w-auto px-6 py-2 rounded-full cursor-pointer hover:bg-gray-400">Cancelar</a>

    <div class="step1 flex flex-col justify-center items-center gap-16 w-full absolute h-full">
        <ng-lottie [options]="map_lottie" background="transparent" speed="1" style="width: 300px; height: 300px;" loop autoplay></ng-lottie>
        <h1 class="text-4xl font-semibold mx-8 text-center">Ingresa el <span class="text-orange-500">Código Postal</span> del destino</h1>
        <form>
            <input type="text" name="destinationCP" id="destinationCP" placeholder="Ej. 47260" class="text-4xl text-center p-2 p-4" maxlength="5" required>
        </form>
    </div>
</main>
