<div class="fixed inset-0 flex justify-center items-center w-full h-full bg-white z-50">
    <div class="relative w-full h-full max-w-screen-xl max-h-screen-xl">
        <video 
            class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full object-cover"
            src="../../../assets/icons/Animacion_Superior_Dashboard_DagPack_v02.mp4" 
            type="video/mp4" 
            poster="Animacion_Superior_Dashboard_DagPack_v02_PosterImage.png" 
            muted 
            loop 
            autoplay 
            playsinline
        >
            Sorry, your browser doesn't support HTML5 video tag.
        </video>
    </div>
</div>