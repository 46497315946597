import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { AuthLoginService } from './auth-login.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {

  constructor(
    private http: HttpClient,
    private authService: AuthLoginService
  ) { }

  updateProfilePicture(userId: string, image: File): Observable<any> {
    const formData = new FormData();
    formData.append('image', image);
    return this.http.patch(`${environment.apiUrl}/users/profile-picture/${userId}`, formData);
  }

  shipmentProfit(userId: string): Observable<any> {    
    return this.http.get(`${environment.apiUrl}/shipments/profit/${userId}`);
  }

  updateUser(id: string, userData: any): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/users/update-user/${id}`, userData);
  }

  getProfitPacking(userId: string): Observable<any> {    
    return this.http.get<any>(`${environment.apiUrl}/shipments/packing-profit/${userId}`);
  }

  getUserPercentage(): Observable<any> {
    const userId = this.authService.getId();
    return this.http.get(`${environment.apiUrl}/users/percentage/${userId}`);
  }

  listUsers(page: number, limit: number, search?: string): Observable<any> {
      let params = new HttpParams()
        .set('page', page.toString())
        .set('limit', limit.toString());

      if (search) {
        params = params.set('search', search);
      }
      return this.http.get(`${environment.apiUrl}/users/list-users`, { params });
    }
  
  setPin(pin: string): Observable<any>{
    const userId = this.authService.getId();
    return this.http.patch(`${environment.apiUrl}/users/set-pin/${userId}`, { pin });
  }

  activateAccount(userId: string): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/users/activate/${userId}`, {});
  }
  
  requestPasswordReset(email: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/users/request-reset`, { email });
  }

  resetPassword(token: string, newPassword: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/users/reset-password`, { token, newPassword });
  }

  assignParentUser(cajeroId: string, parentUserId: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/users/assign-parent/${cajeroId}`, { parentUserId });
  }

  getPotentialParentUsers(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/potential-parents`);
  }

  assignRole(userId: string, role: string): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/users/${userId}/role`, { role });
  }

  updateUserPercentages(userId: string, percentages: {
    dagpacketPercentaje?: number,
    servicesPercentaje?: number,
    recharguesPercentage?: number,
    packingPercentage?: number
  }): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/users/${userId}/percentages`, percentages);
  }
  
}
