<app-sidebar></app-sidebar>
<div class="ml-64">
  <div class="p-6"> 
    <div class="flex justify-end items-center mb-4 space-x-2">
      <div class="flex items-center">
        <label for="start-date" class="mr-2 font-semibold">Desde:</label>
        <input type="date" id="start-date" class="border-2 border-gray-400 rounded-full p-2 text-gray-700 font-semibold" >
      </div>
      <div class="flex items-center">
        <label for="end-date" class="mr-2 font-semibold">Hasta:</label>
        <input type="date" id="end-date" class="border-2 border-gray-400 rounded-full p-2 text-gray-700 font-semibold" >
      </div>
      <select id="status-filter" class="border-2 border-gray-400 rounded-full p-2 text-gray-700 font-semibold">
        <option value="">Todos</option>
        <option value="En reparto">En reparto</option>
        <option value="En Recolección">En Recolección</option>
        <option value="Problema">Problema</option>
        <option value="Entregado">Entregado</option>
      </select>
      <button class="bg-[#D6542B] text-white px-4 py-2 rounded-full font-bold" >
        FILTRAR
      </button>
      <button class="bg-gray-300 text-gray-700 px-4 py-2 rounded-full" >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
        </svg>
      </button>
    </div>

    <div class="overflow-x-auto shadow-md sm:rounded-lg w-full my-4 bg-[#F0F0F0]">            
      <table class="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead class="text-lg uppercase bg-[#F0F0F0] text-dagblue font-bold">
          <tr>
            <th scope="col" class="px-6 py-3">Paquetería</th>
            <th scope="col" class="px-6 py-3">Fecha</th>
            <th scope="col" class="px-6 py-3">Costo</th>
            <th scope="col" class="px-6 py-3">Utilidad</th>
            <th scope="col" class="px-6 py-3">Precio</th>
            <th scope="col" class="px-6 py-3">Tu utilidad</th>
            <th scope="col" class="px-6 py-3">Origen</th>
            <th scope="col" class="px-6 py-3">Destino</th>
            <th scope="col" class="px-6 py-3 text-center">Estado de pago</th>
            <th scope="col" class="px-6 py-3 text-center">Estado de envío</th>            
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          <tr *ngFor="let envio of shipments" class="hover:bg-gray-50">
            <td class="px-6 py-4 uppercase">{{ envio.provider }}</td>
            <td class="px-6 py-4">{{ envio.distribution_at | date:'dd/MM/yy'}}</td>
            <td class="px-6 py-4">${{ envio.cost.$numberDecimal }}</td>
            <td class="px-6 py-4">${{ envio.dagpacket_profit.$numberDecimal | number:'1.2-2'}}</td>
            <td class="px-6 py-4">${{ envio.price.$numberDecimal }}</td>
            <td class="px-6 py-4">${{ envio.extra_price.$numberDecimal }}</td>
            <td class="px-6 py-4">{{ envio.from.city }}</td>
            <td class="px-6 py-4">{{ envio.to.city }}</td>
            <td class="px-6 py-4 text-center">
              <span class="font-bold text-lg"
                [ngClass]="{
                  'text-green-600': envio.payment.status.toLowerCase() === 'pagado',
                  'text-yellow-500': envio.payment.status.toLowerCase() === 'pendiente'
                }">
                {{ envio.payment.status }}
              </span>
            </td>
            <td class="px-6 py-4 text-center font-bold divide-gray-200">
              {{ envio.status }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="flex justify-center items-center space-x-4 py-4">
      <button (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1"
              class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
      </button>
      <span class="text-xl font-bold">{{ currentPage }}</span>
      <button (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages"
              class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
      </button>
    </div>
  </div>
</div>