import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthLoginService } from './_services/users/auth-login.service';
import { CatchTokenService } from './_services/users/catch-token.service';
import { AuthRoutesService } from './_services/auth-routes.service';
import { BaseChartDirective } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { JwtModule } from '@auth0/angular-jwt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SignupComponent } from './pages/signup/signup.component';
import { LoaderComponent } from './components/loader/loader.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { SettingsComponentComponent } from './components/settings-component/settings-component.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { AccountComponent } from './components/Users/account/account.component';
import { AddressComponent } from './components/address/address.component';
import { PinComponent } from './components/Users/pin/pin.component';
import { PasswordComponent } from './components/Users/password/password.component';
import { QuoteComponent } from './components/Cotizaciones/quote/quote.component';
import { QuotePageComponent } from './pages/quote-page/quote-page.component';
import { LockerScreenComponent } from './components/Lockers/locker-screen/locker-screen.component';
import { LicenseesDashboardComponent } from './components/Licensees/licensees-dashboard/licensees-dashboard.component';
import { ShipmentStatusComponent } from './components/Envios/shipment-status/shipment-status.component';
import { ShipmentsComponent } from './pages/shipments/shipments.component';
import { HistoryQuoteComponent } from './components/history-quote/history-quote.component';
import { AdminLockersComponent } from './components/Admin/admin-lockers/admin-lockers.component';
import { ViewLockersPageComponent } from './pages/view-lockers-page/view-lockers-page.component';
import { DetailsLockerComponent } from './components/details-locker/details-locker.component';
import { GabetasLogComponent } from './components/gabetas-log/gabetas-log.component';
import { ShipmentsSendedComponent } from './components/shipments-sended/shipments-sended.component';
import { PackingComponent } from './components/packing/packing.component';
import { ServicesComponent } from './components/services/services.component';
import { RechargesComponent } from './components/recharges/recharges.component';
import { AdminResumeComponent } from './components/Admin/admin-resume/admin-resume.component';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { AdminShipmentsComponent } from './components/Admin/admin-shipments/admin-shipments.component';
import { AdminPackingComponent } from './components/Admin/admin-packing/admin-packing.component';
import { ErrorAlertComponent } from './toast/error-alert/error-alert.component';
import { SuccessAlertComponent } from './toast/success-alert/success-alert.component';
import { CheckoutComponent } from './components/Cotizaciones/checkout/checkout.component';
import { FromToComponent } from './components/Cotizaciones/from-to/from-to.component';
import { UserPackingComponent } from './components/Cotizaciones/user-packing/user-packing.component';
import { CotizarComponent } from './components/Lockers/cotizar/cotizar.component';
import { ShippingKioskComponent } from './components/Lockers/shipping-kiosk/shipping-kiosk.component';
import { CashiersComponent } from './components/Licensees/cashiers/cashiers.component';
import { EnviosComponent } from './components/Packages/envios/envios.component';
import { OlimpoComponent } from './components/Olimpo/olimpo/olimpo.component';
import { CajaComponent } from './components/Licensees/caja/caja.component';
import { TicketsComponent } from './components/Licensees/tickets/tickets.component';
import { PayShipmentComponent } from './components/Cotizaciones/pay-shipment/pay-shipment.component';
import { ShipmentsDetailsComponent } from './components/Envios/shipments-details/shipments-details.component';
import { DisplayRefillsComponent } from './components/Admin/display-refills/display-refills.component';
import { EditUserComponent } from './components/Olimpo/edit-user/edit-user.component';
import { AdministracionComponent } from './components/Funciones/administracion/administracion.component';
import { ChartComponentComponent } from './chart-component/chart-component.component';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { LottieModule } from 'ngx-lottie';
import { ViewGavetasLogPageComponent } from './pages/gavetas-log-page/view-gaveta-log-page.component';
import player from 'lottie-web';
import { LockerScreenv2Component } from './components/Lockers/locker-screenv2/locker-screenv2.component';
import { LockerDeliveryComponent } from './components/Lockers/locker-delivery/locker-delivery.component';
import { LockerReciveComponent } from './components/Lockers/locker-recive/locker-recive.component';
import { RequestResetComponent } from './components/Users/request-reset/request-reset.component';
import { ResetPasswordComponent } from './components/Users/reset-password/reset-password.component';
import { CashRegisterHistoryComponent } from './components/Licensees/cash-register-history/cash-register-history.component';
import { NewPackingFormComponent } from './components/Olimpo/new-packing-form/new-packing-form.component';
import { HomeComponent } from './components/home/home.component';
import { ScanComponent } from './components/scan/scan.component';
import { InversorComponent } from './components/Inversor/inversor.component'; 

export function playerFactory() {
  return player;
}

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    SidebarComponent,
    SignupComponent,
    LoaderComponent,
    DashboardPageComponent,
    SettingsComponentComponent,
    SettingsComponent,
    AccountComponent,
    AddressComponent,
    PinComponent,
    PasswordComponent,
    QuoteComponent,
    QuotePageComponent,
    LockerScreenComponent,
    LicenseesDashboardComponent,
    ShipmentStatusComponent,
    ShipmentsComponent,
    HistoryQuoteComponent,
    AdminLockersComponent,
    ViewLockersPageComponent,
    DetailsLockerComponent,
    ViewGavetasLogPageComponent,
    GabetasLogComponent,
    ShipmentsSendedComponent,
    PackingComponent,
    ServicesComponent,
    RechargesComponent,
    AdminResumeComponent,
    AdminPageComponent,
    AdminShipmentsComponent,
    AdminPackingComponent,
    ErrorAlertComponent,
    SuccessAlertComponent,
    CheckoutComponent,
    FromToComponent,
    UserPackingComponent,
    CotizarComponent,
    ShippingKioskComponent,
    CashiersComponent,
    EnviosComponent,
    OlimpoComponent,
    CajaComponent,
    TicketsComponent,
    PayShipmentComponent,
    ShipmentsDetailsComponent,
    DisplayRefillsComponent,
    LockerScreenv2Component,
    LockerDeliveryComponent,
    LockerReciveComponent,
    EditUserComponent,
    AdministracionComponent,
    ChartComponentComponent,
    DoughnutChartComponent,
    RequestResetComponent,
    ResetPasswordComponent,
    CashRegisterHistoryComponent,
    NewPackingFormComponent,
    HomeComponent,
    ScanComponent,
    InversorComponent  
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    BaseChartDirective,
    LottieModule.forRoot({ player: playerFactory }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:4200"],
        disallowedRoutes: ["http://example.com/api/auth"]
      }
    })
  ],
  providers: [
    AuthLoginService, 
    AuthRoutesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CatchTokenService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
