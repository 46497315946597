import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Rechargues } from 'src/app/_models/rechargues';

@Injectable({
  providedIn: 'root'
})
export class RechargeRequestService {  

  constructor(private http: HttpClient) { }

  createRechargeRequest(rechargeData: any): Observable<any> {
    const formData = new FormData();
    formData.append('amount', rechargeData.amount);
    formData.append('referenceNumber', rechargeData.referenceNumber);
    formData.append('paymentMethod', rechargeData.paymentMethod);
    formData.append('rechargeType', rechargeData.rechargeType); // Nuevo campo
    if (rechargeData.proofImage) {
      formData.append('proofImage', rechargeData.proofImage, rechargeData.proofImage.name);
    }
    return this.http.post(`${environment.apiUrl}/rechargues/request`, formData);
  }

  getRechargeRequests(page: number, limit: number, searchTerm: string, rechargeType?: string): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }

    if (rechargeType) {
      params = params.set('rechargeType', rechargeType);
    }

    return this.http.get<any>(`${environment.apiUrl}/rechargues/requests`, { params });
  }

  getRechargues(): Observable<any>{
    return this.http.get(`${environment.apiUrl}/rechargues/requests`);
  }

  approveRechargeRequest(requestId: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/rechargues/approve/${requestId}`, {});
  }

  rejectRechargeRequest(requestId: string, rejectionReason: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/rechargues/reject/${requestId}`, { rejectionReason });
  }

}