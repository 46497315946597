import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-shipments',
  templateUrl: './admin-shipments.component.html',
  styleUrls: ['./admin-shipments.component.scss']
})
export class AdminShipmentsComponent {

}
