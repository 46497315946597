<app-sidebar></app-sidebar>
<div class="ml-64 mt-16">
    <div class="container mx-auto px-4 py-8">
        <h2 class="text-3xl font-bold mb-6 text-gray-800">Pagar Envíos Pendientes</h2>

        <div *ngIf="errorMessage" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6">
            {{ errorMessage }}
        </div>

        <div *ngIf="successMessage" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-6">
            {{ successMessage }}
        </div>

        <div *ngIf="pendingShipments.length === 0" class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6">
            <p class="font-bold">No hay envíos pendientes de pago.</p>
        </div>
      
        <div *ngFor="let shipment of pendingShipments" class="bg-white shadow-md rounded-lg mb-6 overflow-hidden">
          <div class="bg-gray-100 px-6 py-4 border-b border-gray-200">
            <h3 class="text-xl font-semibold text-gray-800">Envío #{{ shipment.trackingNumber }}</h3>
          </div>
          
          <div class="p-6">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div class="address-section">
                <h4 class="text-lg font-semibold mb-2 text-gray-700">Desde:</h4>
                <p class="text-gray-600">{{ shipment.from.name }}</p>
                <p class="text-gray-600">{{ shipment.from.street }} {{ shipment.from.external_number }}</p>
                <p class="text-gray-600">{{ shipment.from.city }}, {{ shipment.from.state }} {{ shipment.from.zip_code }}</p>
                <p class="text-gray-600">{{ shipment.from.country }}</p>
              </div>
              
              <div class="address-section">
                <h4 class="text-lg font-semibold mb-2 text-gray-700">Hacia:</h4>
                <p class="text-gray-600">{{ shipment.to.name }}</p>
                <p class="text-gray-600">{{ shipment.to.street }} {{ shipment.to.external_number }}</p>
                <p class="text-gray-600">{{ shipment.to.city }}, {{ shipment.to.state }} {{ shipment.to.zip_code }}</p>
                <p class="text-gray-600">{{ shipment.to.country }}</p>
              </div>
            </div>
      
            <div class="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p class="text-gray-700"><span class="font-semibold">Tipo de envío:</span> {{ shipment.shipment_type }}</p>
                <p class="text-gray-700"><span class="font-semibold">Estado:</span> {{ shipment.status }}</p>
                <p class="text-gray-700"><span class="font-semibold">Proveedor:</span> {{ shipment.provider }}</p>
                <p class="text-gray-700"><span class="font-semibold">Api Proveedor:</span> {{ shipment.apiProvider }}</p>
                <p class="text-gray-700"><span class="font-semibold">Descripcion del paquete:</span> {{ shipment.description }}</p>                
                <p class="text-gray-700"><span class="font-semibold">Fecha de distribución:</span> {{ shipment.distribution_at | date:'medium' }}</p>
              </div>
      
              <div *ngIf="shipment.packing.answer === 'Si'" class="bg-blue-50 p-4 rounded-md">
                <h4 class="text-lg font-semibold mb-2 text-blue-800">Empaque:</h4>
                <p class="text-blue-700">Tipo: {{ shipment.packing.packing_type }}</p>
                <p class="text-blue-700">Costo: {{ shipment.packing.packing_cost.$numberDecimal | currency:'MXN' }}</p>
              </div>
            </div>
      
            <div class="mt-6 bg-gray-50 p-4 rounded-md">                           
              <p class="text-xl font-bold text-gray-800 mt-2"><span class="font-semibold">Precio total:</span> {{ shipment.price.$numberDecimal | currency:'MXN' }}</p>
            </div>
          </div>
        </div>
      
        <div class="mt-8">
          <h3 class="text-2xl font-bold mb-4 text-gray-800">Método de Pago</h3>
          <div class="flex space-x-4 mb-6">
            <button 
              (click)="selectPaymentMethod('saldo')" 
              [ngClass]="{'bg-blue-600 text-white': selectedPaymentMethod === 'saldo', 'bg-gray-200 text-gray-700': selectedPaymentMethod !== 'saldo'}"
              class="px-6 py-2 rounded-md transition duration-300 ease-in-out">
              Saldo de Cuenta
            </button>
            <button 
              (click)="selectPaymentMethod('td-debito')" 
              [ngClass]="{'bg-blue-600 text-white': selectedPaymentMethod === 'td-debito', 'bg-gray-200 text-gray-700': selectedPaymentMethod !== 'td-debito'}"
              class="px-6 py-2 rounded-md transition duration-300 ease-in-out">
              TD/Debito
            </button>
            <button 
              (click)="selectPaymentMethod('td-credito')" 
              [ngClass]="{'bg-blue-600 text-white': selectedPaymentMethod === 'td-credito', 'bg-gray-200 text-gray-700': selectedPaymentMethod !== 'td-credito'}"
              class="px-6 py-2 rounded-md transition duration-300 ease-in-out">
              TD/Credito
            </button>
          </div>
        
          <!-- Campo de entrada para el número de transacción -->
          <div *ngIf="selectedPaymentMethod === 'td-debito' || selectedPaymentMethod === 'td-credito'" class="mb-6">
            <label for="transactionNumber" class="block text-sm font-medium text-gray-700 mb-2">Número de Transacción</label>
            <input 
              type="text" 
              id="transactionNumber" 
              [(ngModel)]="transactionNumber" 
              class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Ingrese el número de transacción"
            >
          </div>
        
          <div class="bg-gray-100 p-6 rounded-lg mb-6">
            <h3 class="text-2xl font-bold text-gray-800">Total a pagar: {{ calculateTotal() | currency:'MXN' }}</h3>
          </div>
        
          <button 
            (click)="processPaymentAndGenerateGuides()" 
            [disabled]="isProcessing || generatingGuides || !selectedPaymentMethod || pendingShipments.length === 0 || (isCardPayment() && !transactionNumber)"
            [ngClass]="{'bg-green-600 hover:bg-green-700': !isProcessing && !generatingGuides && selectedPaymentMethod && pendingShipments.length > 0 && (!isCardPayment() || transactionNumber), 'bg-gray-400 cursor-not-allowed': isProcessing || generatingGuides || !selectedPaymentMethod || pendingShipments.length === 0 || (isCardPayment() && !transactionNumber)}"
            class="w-full py-3 text-white font-bold rounded-md transition duration-300 ease-in-out">
            {{ isProcessing ? 'Procesando...' : (generatingGuides ? 'Generando guías...' : 'Generar Guías y Procesar Pago') }}
        </button>
        </div>

        <div *ngIf="generatingGuides" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
          <app-loader></app-loader>
        </div>
    </div>

    <div *ngIf="showToast" class="fixed bottom-5 right-5 bg-green-500 text-white p-4 rounded-lg shadow-lg">
      {{ successMessage }}
    </div>
</div>