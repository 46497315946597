import { Component } from '@angular/core';

@Component({
  selector: 'app-details-locker',
  templateUrl: './details-locker.component.html',
  styleUrls: ['./details-locker.component.scss']
})
export class DetailsLockerComponent {

  isChecked = false;

  onToggle() {
    this.isChecked = !this.isChecked;
    // Aquí puedes agregar la lógica adicional que deseas ejecutar cuando el toggle cambia de estado
    console.log('Toggle state:', this.isChecked);
  }


}
