<div class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
  <div class="bg-white rounded-lg shadow-lg w-11/12 md:w-3/4 lg:w-3/4 p-6">
    <h2 class="text-2xl font-bold mb-4">Detalle del usuario</h2>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div class="md:col-span-1">
        <div class="flex flex-col space-y-4">
          <div class="p-4 rounded-lg cursor-pointer"
               [ngClass]="{'bg-gray-200': selectedOption === 'Abonos, cargos y wallet'}"
               (click)="selectOption('Abonos, cargos y wallet')">
            <i class="fa fa-wallet"></i>
            <span>Abonos, cargos y wallet</span>
          </div>
          <div class="p-4 rounded-lg cursor-pointer"
               [ngClass]="{'bg-gray-200': selectedOption === 'Datos del usuario'}"
               (click)="selectOption('Datos del usuario')">
            <i class="fa fa-user"></i>
            <span>Datos del usuario</span>
          </div>
          <div class="p-4 rounded-lg cursor-pointer"
               [ngClass]="{'bg-gray-200': selectedOption === 'Editar rol'}"
               (click)="selectOption('Editar rol')">
            <i class="fa fa-edit"></i>
            <span>Editar rol</span>
          </div>
          <div class="p-4 rounded-lg cursor-pointer"
               [ngClass]="{'bg-gray-200': selectedOption === 'Asignar Usuario'}"
               (click)="selectOption('Asignar Usuario')">
            <i class="fa fa-user-check"></i>
            <span>Asignar Usuario</span>
          </div>
          <div class="p-4 rounded-lg cursor-pointer"
               [ngClass]="{'bg-gray-200': selectedOption === 'Contratos'}"
               (click)="selectOption('Contratos')">
            <i class="fa fa-file-contract"></i>
            <span>Contratos</span>
          </div>
          <div class="p-4 rounded-lg cursor-pointer"
               [ngClass]="{'bg-gray-200': selectedOption === 'Utilidades'}"
               (click)="selectOption('Utilidades')">
            <i class="fa fa-tools"></i>
            <span>Utilidades</span>
          </div>
        </div>
      </div>

      <div class="md:col-span-2">
        <div *ngIf="selectedOption === 'Datos del usuario'; else otherContent">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="bg-white bg-opacity-30 rounded-lg p-4">
              <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">Foto</label>
                <div class="mt-1 flex items-center">
                  <img [src]="user.image || 'path/to/default-image.png'" alt="User Photo" class="rounded-full w-20 h-20 object-cover">
                  <button type="button" class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Upload photo</button>
                </div>
              </div>
              <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">Permiso</label>
                <p class="mt-1 text-sm text-gray-500">.jpeg, .jpg, .png, .gif (max size of 3.1 MB)</p>
              </div>
              <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">Perfil Publico</label>
                <input type="checkbox" class="mt-1">
              </div>
            </div>
            <div class="bg-white bg-opacity-30 rounded-lg p-4">
              <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">Nombre</label>
                <input type="text" [(ngModel)]="user.name" class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
              </div>
              <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">Correo Electronico</label>
                <input type="email" [(ngModel)]="user.email" class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
              </div>
              <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">Teléfono</label>
                <input type="text" [(ngModel)]="user.phone" class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
              </div>
              <!-- <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">Dirección</label>
                <input type="text" [(ngModel)]="user.address.street" class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
              </div>
              <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">Ciudad</label>
                <input type="text" [(ngModel)]="user.address.municipality" class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
              </div>
              <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">Codigo Postal</label>
                <input type="text" [(ngModel)]="user.address.zip_code" class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
              </div> -->
            </div>
          </div>
          <div class="mt-6 flex justify-end">
            <button (click)="onSaveChanges()" class="bg-dagblue hover:bg-dagblue text-white font-bold py-2 px-4 rounded">Guardar Cambios</button>
            <button (click)="close.emit()" class="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cerrar</button>
          </div>
        </div>
        <ng-template #otherContent>
          <div *ngIf="selectedOption === 'Utilidades'">
            <div class="space-y-4">
              <div *ngFor="let utilidad of utilidades" class="flex items-center justify-between p-4 bg-white rounded-lg shadow-sm">
                <span>{{utilidad.nombre}}</span>
                <div class="flex items-center space-x-2">
                  <input type="text" class="w-24 p-2 border rounded" placeholder="Utilidad" [(ngModel)]="utilidad.utilidad">
                  <input type="text" class="w-16 p-2 border rounded" placeholder="%" [(ngModel)]="utilidad.porcentaje">
                  <button class="bg-dagblue text-white font-bold py-2 px-4 rounded">Guardar</button>
                </div>
              </div>
            </div>
            <div class="mt-6 flex justify-end">
              <button (click)="close.emit()" class="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cerrar</button>
            </div>
          </div>          
          <div *ngIf="selectedOption === 'Editar rol'">
            <div class="space-y-4">
              <div class="flex flex-col p-4 bg-white rounded-lg shadow-sm">
                <h3 class="text-lg font-bold mb-4">Roles</h3>
                <div *ngFor="let rol of roles" class="mb-2">
                  <label class="inline-flex items-center">
                    <input type="radio" [(ngModel)]="selectedRole" [value]="rol" class="form-radio">
                    <span class="ml-2">{{rol}}</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="mt-6 flex justify-end">
              <button (click)="close.emit()" class="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cerrar</button>
            </div>
          </div>
          <div *ngIf="selectedOption === 'Asignar Usuario'">
            <div class="space-y-4">
              <div class="flex flex-col p-4 bg-white rounded-lg shadow-sm">
                <h3 class="text-lg font-bold mb-4">Usuarios asignados</h3>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">Lista de usuarios</label>
                  <textarea placeholder="Buscar usuario" class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md resize" [(ngModel)]="searchText" (input)="onSearch()"></textarea>
                </div>
                <div class="mt-4">
                  <ul *ngIf="searchText && filteredUsers.length > 0; else noUsers">
                    <li *ngFor="let user of filteredUsers" class="py-2 border-b border-gray-200">{{ user.name }}</li>
                  </ul>
                  <ng-template #noUsers>
                    <p *ngIf="searchText" class="text-gray-500">Todavía no hay usuarios registrados.</p>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="mt-6 flex justify-end">
              <button (click)="close.emit()" class="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cerrar</button>
            </div>
          </div> 
          <div *ngIf="selectedOption === 'Contratos'">
            <div class="space-y-4">
              <div class="flex flex-col p-4 bg-white rounded-lg shadow-sm">
                <h3 class="text-lg font-bold mb-4 text-center">Tipo Contrato</h3>
                <div *ngFor="let contrato of contratos" class="mb-2">
                  <label class="inline-flex items-center">
                    <input type="radio" [(ngModel)]="selectedContrato" [value]="contrato" class="form-radio" (change)="showModal()">
                    <span class="ml-2">{{contrato}}</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="mt-6 flex justify-end">
              <button (click)="close.emit()" class="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cerrar</button>
            </div>
          </div>
          <div *ngIf="isModalVisible" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
            <div class="bg-white rounded-lg shadow-lg w-1/3 p-6">
              <h3 class="text-lg font-bold mb-4 text-center">Confirmar Contrato</h3>
              <input type="text" placeholder="NIP" class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
              <div class="mt-6 flex justify-end">
                <button (click)="confirmContract()" class="bg-dagblue hover:bg-dagblue text-white font-bold py-2 px-4 rounded">Guardar</button>
                <button (click)="hideModal()" class="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Atrás</button>
              </div>
            </div>
          </div>          
          <div *ngIf="selectedOption === 'Abonos, cargos y wallet'">
            <div class="space-y-4">
              <div class="flex flex-col p-4 bg-white rounded-lg shadow-sm">
                <h3 class="text-lg font-bold mb-4 text-center">Aplica abonos y cargos para este usuario</h3>
                <div class="flex justify-center space-x-4">
                  <button class="bg-dagblue text-white font-bold py-2 px-4 rounded">Abono</button>
                  <button class="bg-gray-500 text-white font-bold py-2 px-4 rounded">Cargo</button>
                </div>
              </div>
              <div class="flex flex-col p-4 bg-white rounded-lg shadow-sm">
                <h3 class="text-lg font-bold mb-4 text-center">Información de la wallet</h3>
                <div class="flex justify-center space-x-4">
                  <button class="bg-dagblue text-white font-bold py-2 px-4 rounded">Ver Wallet</button>
                  <button class="bg-dagblue text-white font-bold py-2 px-4 rounded">Ver Wallet</button>
                </div>
              </div>
              <div class="flex flex-col p-4 bg-white rounded-lg shadow-sm">
                <h3 class="text-lg font-bold mb-4 text-center">Historial de empaques</h3>
                <div class="flex justify-center">
                  <button class="bg-dagblue text-white font-bold py-2 px-4 rounded">Ver Historial De Empaque</button>
                </div>
              </div>
            </div>
            <div class="mt-6 flex justify-end">
              <button (click)="close.emit()" class="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cerrar</button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
