import { Component, OnInit } from '@angular/core';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import { Users } from 'src/app/_models/user-model';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})

export class AddressComponent implements OnInit{

  constructor(
    private router: Router,
    private authService: AuthLoginService
  ){}
  
  message!: string;
  success = false;
  error = false;
  user!: Users;

  ngOnInit(): void {
    this.authService.userProfile().subscribe(
      (res) =>{
        this.user = res.data;
      }
    )
  }


  updateAddressA(updateFormA: NgForm) {
    this.authService.addAddress(updateFormA.value).subscribe(
      (res) => {
        if (res.success === true) {
          this.message = res.message;
          this.success = true;
          setTimeout(() => {
            this.success = false;
            window.location.reload();
          },1800);
        } else {
          this.message = res.message;
        }
      },
      (error) => {
        console.log('Error al actualizar la información del usuario:', error);
      }
    );
  }

}