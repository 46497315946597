import { Component } from '@angular/core';

@Component({
  selector: 'app-history-quote',
  templateUrl: './history-quote.component.html',
  styleUrls: ['./history-quote.component.scss']
})
export class HistoryQuoteComponent {

}
