<div class="px-6 ml-36 relative">
    <div class="bg-gray-100 rounded-lg w-[60vw] shadow-lg">
        <div class="p-4 flex justify-between">
            <div class="p-6">                
                <div class="flex items-center gap-2">
                    <div class="flex flex-col justify-start items-center gap-2 pt-3">
                        <img [src]="user.image" alt="" class="rounded-full w-40 h-40">
                        <input type="file" (change)="onFileSelected($event)" accept="image/*" style="display: none;" #fileInput>
                        <button (click)="fileInput.click()" class="bg-dagblue text-white p-2 rounded">Seleccionar imagen</button>
                        <button (click)="updateProfilePicture()" [disabled]="!selectedFile" class="bg-dagblue text-white p-2 rounded" [ngClass]="{'opacity-50': !selectedFile}">
                            Actualizar foto de perfil
                        </button>
                    </div>
                    <div class="p-2 flex flex-col text-xl">                        
                        <span class="font-bold"> {{ user.name }} {{ user.surname }} </span>
                        <span> {{ user.email }} </span>
                        <span> {{ user.phone }} </span>
                    </div>
                </div>
            </div>
            
        </div>
        <form #updateForm="ngForm" (submit)="updateInfo(updateForm)">
            <div class="px-4 py-4 flex justify-start w-full gap-6">
                <div class="relative z-0 mb-5 group w-1/2">
                    <input type="text" name="name" id="name" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder="" required [(ngModel)]="user.name" #name="ngModel"/>
                    <label for="name" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-dagblue peer-focus:dark:text-dagblue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nombre</label>
                    <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-red-500 text-sm">
                        Nombre es obligatorio.
                    </div>
                </div>
                <div class="relative z-0 mb-5 group w-1/2">
                    <input type="text" name="surname" id="surname" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " required [(ngModel)]="user.surname" #surname="ngModel"/>
                    <label for="surname" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-dagblue peer-focus:dark:text-dagblue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Apellidos</label>
                    <div *ngIf="surname.invalid && (surname.dirty || surname.touched)" class="text-red-500 text-sm">
                        Apellidos son obligatorios.
                    </div>
                </div>
            </div>
            <div class="px-4 flex justify-start w-full gap-6">
                <div class="relative z-0 mb-5 group w-1/2">
                    <input type="text" name="phone" id="phone" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " required [(ngModel)]="user.phone" #phone="ngModel"/>
                    <label for="phone" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-dagblue peer-focus:dark:text-dagblue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Teléfono</label>
                    <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="text-red-500 text-sm">
                        Teléfono es obligatorio.
                    </div>
                </div>
                <div class="relative z-0 mb-5 group w-1/2">
                    <input type="email" name="email" id="email" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " [value]="user.email" disabled />
                    <label for="email" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-dagblue peer-focus:dark:text-dagblue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Correo Electrónico</label>
                </div>
            </div> 
            <div class="p-4 flex justify-end">
                <button type="submit" [disabled]="updateForm.invalid" class="bg-dagblue p-3 rounded-lg text-white font-bold duration-200 hover:bg-blue-700 disabled:opacity-50">
                    Guardar Cambios
                </button>
            </div>
        </form>                   
    </div>
    <app-success-alert *ngIf="success" [message]="message"></app-success-alert>
    <app-success-alert *ngIf="error"></app-success-alert>
</div>