<div class="h-full ml-64 mt-14 bg-gray-50">
    <div class="p-6 flex justify-between items-center bg-white shadow-sm">
        <div class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-dagblue">
                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0l-3-3m3 3l3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
            </svg>
            <span class="font-bold uppercase text-3xl text-dagblue"> Lockers </span>
        </div>    
        <button (click)="openAddLocker()" class="flex gap-2 rounded-lg bg-dagblue p-3 text-white hover:bg-dagblue-dark transition duration-300 shadow-md">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>          
            Nuevo locker        
        </button>
    </div>
    <div class="p-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">

        <!-- Locker activo 1 -->
        <div class="bg-white border-4 border-emerald-400 gap-6 flex flex-col hover:bg-gradient-to-tr hover:cursor-pointer justify-between p-6 rounded-[30px] shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105">
            <div class="flex justify-between">
                <span class="font-bold bg-gradient-to-tr from-emerald-500 to-emerald-700 text-white px-3 py-1 text-sm flex justify-center items-center rounded-full shadow"> Online </span>
                <button [routerLink]="['/details-locker']" class="p-2 flex items-center rounded-full hover:bg-emerald-100 transition duration-300">
                    <span class="px-2 font-semibold text-emerald-700"> Detalles </span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-emerald-700">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>                                 
                </button>
            </div>
            <div class="flex gap-2 pl-2">                
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-600">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                </svg>
                <span class="font-bold text-lg text-gray-800"> Plaza del Sol</span>                
            </div>
            <div class="grid grid-cols-3 gap-4">        
                <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg hover:bg-emerald-50 transition duration-300">
                    <div class="flex items-center text-emerald-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                        </svg>                      
                        20
                    </div>
                    <span class="text-xs font-semibold bg-emerald-100 text-emerald-800 rounded-full px-2 py-1"> Online </span>
                </button>                                  
                <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg hover:bg-yellow-50 transition duration-300">
                    <div class="flex items-center text-yellow-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0-3-3m3 3 3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                        </svg>                                                
                        10
                    </div>
                    <span class="text-xs font-semibold bg-yellow-100 text-yellow-800 rounded-full px-2 py-1"> Ocupadas </span>
                </button>  
                <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg hover:bg-red-50 transition duration-300">
                    <div class="flex items-center text-red-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                        </svg>                      
                        20
                    </div>
                    <span class="text-xs font-semibold bg-red-100 text-red-800 rounded-full px-2 py-1"> Offline </span>
                </button>  
            </div>
        </div>
                  
        <!-- Locker 2 -->
        <div class="bg-white border-4 border-emerald-400 gap-6 flex flex-col hover:bg-gradient-to-tr hover:cursor-pointer justify-between p-6 rounded-[30px] shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105">
            <div class="flex justify-between">
                <span class="font-bold bg-gradient-to-tr from-emerald-500 to-emerald-700 text-white px-3 py-1 text-sm flex justify-center items-center rounded-full shadow"> Online </span>
                <button [routerLink]="['/details-locker']" class="p-2 flex items-center rounded-full hover:bg-emerald-100 transition duration-300">
                    <span class="px-2 font-semibold text-emerald-700"> Detalles </span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-emerald-700">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>                                 
                </button>
            </div>
            <div class="flex gap-2 pl-2">                
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-600">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                </svg>
                <span class="font-bold text-lg text-gray-800"> Plaza del Sol</span>                
            </div>
            <div class="grid grid-cols-3 gap-4">        
                <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg hover:bg-emerald-50 transition duration-300">
                    <div class="flex items-center text-emerald-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                        </svg>                      
                        20
                    </div>
                    <span class="text-xs font-semibold bg-emerald-100 text-emerald-800 rounded-full px-2 py-1"> Online </span>
                </button>                                  
                <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg hover:bg-yellow-50 transition duration-300">
                    <div class="flex items-center text-yellow-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0-3-3m3 3 3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                        </svg>                                                
                        10
                    </div>
                    <span class="text-xs font-semibold bg-yellow-100 text-yellow-800 rounded-full px-2 py-1"> Ocupadas </span>
                </button>  
                <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg hover:bg-red-50 transition duration-300">
                    <div class="flex items-center text-red-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                        </svg>                      
                        20
                    </div>
                    <span class="text-xs font-semibold bg-red-100 text-red-800 rounded-full px-2 py-1"> Offline </span>
                </button>  
            </div>
        </div>

        <!-- Locker 3 -->
        <div class="bg-white border-4 border-emerald-400 gap-6 flex flex-col hover:bg-gradient-to-tr hover:cursor-pointer justify-between p-6 rounded-[30px] shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105">
            <div class="flex justify-between">
                <span class="font-bold bg-gradient-to-tr from-emerald-500 to-emerald-700 text-white px-3 py-1 text-sm flex justify-center items-center rounded-full shadow"> Online </span>
                <button [routerLink]="['/details-locker']" class="p-2 flex items-center rounded-full hover:bg-emerald-100 transition duration-300">
                    <span class="px-2 font-semibold text-emerald-700"> Detalles </span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-emerald-700">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>                                 
                </button>
            </div>
            <div class="flex gap-2 pl-2">                
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-600">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                </svg>
                <span class="font-bold text-lg text-gray-800"> Plaza del Sol</span>                
            </div>
            <div class="grid grid-cols-3 gap-4">        
                <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg hover:bg-emerald-50 transition duration-300">
                    <div class="flex items-center text-emerald-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                        </svg>                      
                        20
                    </div>
                    <span class="text-xs font-semibold bg-emerald-100 text-emerald-800 rounded-full px-2 py-1"> Online </span>
                </button>                                  
                <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg hover:bg-yellow-50 transition duration-300">
                    <div class="flex items-center text-yellow-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0-3-3m3 3 3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                        </svg>                                                
                        10
                    </div>
                    <span class="text-xs font-semibold bg-yellow-100 text-yellow-800 rounded-full px-2 py-1"> Ocupadas </span>
                </button>  
                <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg hover:bg-red-50 transition duration-300">
                    <div class="flex items-center text-red-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                        </svg>                      
                        20
                    </div>
                    <span class="text-xs font-semibold bg-red-100 text-red-800 rounded-full px-2 py-1"> Offline </span>
                </button>  
            </div>
        </div>

        
    
                <!-- Locker Inactivo 1-->
                <div class="bg-white border-4 border-gray-300 gap-6 flex flex-col hover:bg-gradient-to-tr hover:cursor-pointer justify-between p-6 rounded-[30px] shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105">
                    <div class="flex justify-between">
                        <span class="font-bold bg-gradient-to-tr from-gray-400 to-gray-600 text-white px-3 py-1 text-sm flex justify-center items-center rounded-full shadow"> Offline </span>
                        <button class="p-2 flex items-center rounded-full hover:bg-gray-100 transition duration-300">
                            <span class="px-2 font-semibold text-gray-700"> Detalles </span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-gray-700">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>                                 
                        </button>
                    </div>
                    <div class="flex gap-2 pl-2">                
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-500">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                        </svg>
                        <span class="font-bold text-lg text-gray-700"> Plaza del Sol</span>                
                    </div>
                    <div class="grid grid-cols-3 gap-4">        
                        <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg hover:bg-gray-50 transition duration-300">
                            <div class="flex items-center text-gray-500">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                                </svg>                      
                                20
                            </div>
                            <span class="text-xs font-semibold bg-gray-200 text-gray-700 rounded-full px-2 py-1"> Total </span>
                        </button>                                   
                        <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg hover:bg-yellow-50 transition duration-300">
                            <div class="flex items-center text-yellow-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0-3-3m3 3 3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                                </svg>                                                
                                10
                            </div>
                            <span class="text-xs font-semibold bg-yellow-100 text-yellow-800 rounded-full px-2 py-1"> Ocupadas </span>
                        </button>  
                        <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg hover:bg-red-50 transition duration-300">
                            <div class="flex items-center text-red-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                                </svg>                      
                                20
                            </div>
                            <span class="text-xs font-semibold bg-red-100 text-red-800 rounded-full px-2 py-1"> Offline </span>
                        </button>  
                    </div>
                </div>
        
                 <!-- Locker Inactivo 2-->
                 <div class="bg-white border-4 border-gray-300 gap-6 flex flex-col hover:bg-gradient-to-tr hover:cursor-pointer justify-between p-6 rounded-[30px] shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105">
                    <div class="flex justify-between">
                        <span class="font-bold bg-gradient-to-tr from-gray-400 to-gray-600 text-white px-3 py-1 text-sm flex justify-center items-center rounded-full shadow"> Offline </span>
                        <button class="p-2 flex items-center rounded-full hover:bg-gray-100 transition duration-300">
                            <span class="px-2 font-semibold text-gray-700"> Detalles </span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-gray-700">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>                                 
                        </button>
                    </div>
                    <div class="flex gap-2 pl-2">                
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-500">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                        </svg>
                        <span class="font-bold text-lg text-gray-700"> Plaza del Sol</span>                
                    </div>
                    <div class="grid grid-cols-3 gap-4">        
                        <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg hover:bg-gray-50 transition duration-300">
                            <div class="flex items-center text-gray-500">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                                </svg>                      
                                20
                            </div>
                            <span class="text-xs font-semibold bg-gray-200 text-gray-700 rounded-full px-2 py-1"> Total </span>
                        </button>                                   
                        <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg hover:bg-yellow-50 transition duration-300">
                            <div class="flex items-center text-yellow-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0-3-3m3 3 3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                                </svg>                                                
                                10
                            </div>
                            <span class="text-xs font-semibold bg-yellow-100 text-yellow-800 rounded-full px-2 py-1"> Ocupadas </span>
                        </button>  
                        <button class="flex flex-col gap-2 justify-center items-center p-2 rounded-lg hover:bg-red-50 transition duration-300">
                            <div class="flex items-center text-red-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                                </svg>                      
                                20
                            </div>
                            <span class="text-xs font-semibold bg-red-100 text-red-800 rounded-full px-2 py-1"> Offline </span>
                        </button>  
                    </div>
                </div>

<!-- Modal para agregar nuevo locker -->
<div *ngIf="showAddLocker" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
    <div class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h3 class="text-lg font-bold mb-4">Agregar Nuevo Locker</h3>
        <form>
            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="newNombre">
                    Locker ID
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="newNombre" type="text" [(ngModel)]="newLocker.nombre" name="nombre">
            </div>
            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="newApellido">
                    Numero De Locker
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="newApellido" type="text" [(ngModel)]="newLocker.apellido" name="apellido">
            </div>
            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="newTelefono">
                    Medida de locker
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="newTelefono" type="text" [(ngModel)]="newLocker.telefono" name="telefono">
            </div>
            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="newCorreo">
                    Precio de Locker
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="newCorreo" type="email" [(ngModel)]="newLocker.correo" name="correo">
            </div>
            <div class="flex items-center justify-between">
                <button class="bg-dagblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" (click)="addLocker()">
                    Agregar
                </button>
                <button class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" (click)="closeAddLocker()">
                    Cancelar
                </button>
            </div>
        </form>
    </div>
</div>









                

    <!-- Lockers Inactivos 
    <div class="mt-12 p-6 bg-gray-100 w-{}">
        <div class="flex items-center gap-2 mb-6">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-7 text-gray-600">
                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
            </svg>
            <span class="font-bold uppercase text-2xl text-gray-700"> Lockers inactivos </span>    
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
    -->
