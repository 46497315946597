import { Component, OnInit } from '@angular/core';
import { ChartData } from 'chart.js/auto';
import { UserServiceService } from 'src/app/_services/users/user-service.service';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { CancellationService } from 'src/app/_services/cancellations/cancellation.service';

@Component({
  selector: 'app-administracion',
  templateUrl: './administracion.component.html',
  styleUrls: ['./administracion.component.scss']
})
export class AdministracionComponent implements OnInit {
  reportes = [
    {
      folio: '4026',
      usuario: 'gtoleon-001 Fabian Gonzalez Villanueva',
      usuarioImagen: 'ruta_a_la_imagen_del_usuario',
      cajero: 'gtoleon-001 Fabian Gonzalez Villanueva',
      fechaPago: '23 July 2024',
      fechaCreacion: '23 Jul 2024 09:21',
      fechaAutorizacion: '-',
      monto: 410,
      estado: 'Pendiente'
    },
  ];

  showDownloadForm = false;
  showUserModal = false;
  showCancellationModal = false;
  showReporteModal = false;
  showRembolsoModal = false;
  showPedidoModal = false;
  showEmpaques = false;
  showPagoS = false;
  showPagoR = false;
  showRepartoM = false;
  showCupones = false;
  showDirecciones = false;
  showPMercado = false;
  showRecotizciones = false;
  selectedDate: string = '';
  users: any[] = [];
  shipments: any[] = [];
  cancellations: any[] = []; 
  currentPage: number = 1;
  totalPages: number = 1;
  itemsPerPage: number = 10;
  totalCancellations: number = 0;
  showEnviosModal: boolean = false;
  totalShipments: number = 0;


  chartData: ChartData<'bar'> = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
    datasets: [
      {
        label: 'Series A',
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      },
      {
        label: 'Series B',
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1
      }
    ]
  };

  showDetallesModal: boolean = false;
  reporteSeleccionado: any = null;

  globalProfit: any;
  shipmentDetails: any; 

  constructor(private userService: UserServiceService, private shipmentsService: ShipmentsService, private cancellationService: CancellationService) {}
 //carga al iniciar
  ngOnInit(): void {
    this.loadGlobalProfit();
    this.loadShipments(1, 10, 'date', 'asc');
    this.loadCancellations(this.currentPage, this.itemsPerPage, 'requested_at', 'desc', '');
    this.updateChartData(); 
  }

  loadGlobalProfit(): void {
    this.shipmentsService.getGlobalProfit().subscribe(
      (data) => {
        this.globalProfit = data.monthlyProfit; 
      },
      (error) => {
        console.error('Error al cargar los datos de ganancias:', error);
      }
    );
  }

  loadShipments(page: number, limit: number, sortBy: string, sortOrder: 'asc' | 'desc'): void {
    this.shipmentsService.getAllShipments(page, limit, sortBy, sortOrder).subscribe(
      data => {
        this.shipments = data.data.shipments;
        this.totalShipments = data.data.totalShipments;
        this.totalPages = data.data.totalPages;
      },
      error => {
        console.error('Error al cargar los envíos:', error);
      }
    );
  } 
   
  onApply() {
    if (this.selectedDate) {
      this.updateChartData();
    }
  }

  onDateChange(event: any) {
    this.selectedDate = event.target.value;
  }

  toggleDownloadForm() {
    this.showDownloadForm = !this.showDownloadForm;
  }

  updateChartData() {
    this.chartData = {
      labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
      datasets: [
        {
          label: 'Series A',
          data: [65, 59, 80, 81, 56, 55, 40],
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        },
        {
          label: 'Series B',
          data: [28, 48, 40, 19, 86, 27, 90],
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1
        }
      ]
    };
  }

  openUserModal() {
    this.showUserModal = true;
    this.getUsers(this.currentPage);
  }

  closeUserModal() {
    this.showUserModal = false;
  }

  getUsers(page: number): void {
    this.users = [
      { id: 1, name: 'Usuario 1', email: 'usuario1@example.com' },
      { id: 2, name: 'Usuario 2', email: 'usuario2@example.com' }
    ];
  }

  onPageChange(page: number): void {
    if (page > 0 && page <= this.totalPages) {
      this.currentPage = page;
      this.getUsers(this.currentPage);
      this.loadShipments(this.currentPage, this.itemsPerPage, 'status', 'asc');
    }
  }

  onItemsPerPageChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    this.itemsPerPage = Number(target.value);
    this.currentPage = 1; 
    this.loadShipments(this.currentPage, this.itemsPerPage, 'status', 'asc');
  }


  openCancellationModal(): void {
    this.showCancellationModal = true;
    this.loadCancellations(this.currentPage, this.itemsPerPage, 'requested_at', 'desc', '');
  }

  openReporteModal() {
    this.showReporteModal = true;
  }

  openRembolsoModal() {
    this.showRembolsoModal = true;
  }

  openPedidoModal() {
    this.showPedidoModal = true;
  }
 
  closeEnviosModal(): void {
    this.showEnviosModal = false;
  }

  openEnviosModal(): void {
    this.showEnviosModal = true;
  }
  
  loadShipmentDetails(shipmentId: string): void {
    this.shipmentsService.getShipmentDetails(shipmentId).subscribe(
      (data) => {
        console.log('Datos de cancelaciones:', data); 
        this.shipmentDetails = data; 
      },
      (error) => {
        console.error('Error al cargar los detalles del envío:', error);
      }
    );
  }
  
  openEmpaquesModal() {
    this.showEmpaques = true;
  }

  openPagoSModal() {
    this.showPagoS = true;
  }

  openPagoRModal() {
    this.showPagoR = true;
  }

  openRepartoMModal() {
    this.showRepartoM = true;
  }

  openCuponesModal() {
    this.showCupones = true;
  }

  openDireccionesModal() {
    this.showDirecciones = true;
  }

  openPMercadoModal() {
    this.showPMercado = true;
  }

  openRecotizcionesModal() {
    this.showRecotizciones = true;
  }

  closeCancellationModal() {
    this.showCancellationModal = false;
  }

  closeReporteModal() {
    this.showReporteModal = false;
  }

  closeRembolsoModal() {
    this.showRembolsoModal = false;
  }

  closePedidoModal() {
    this.showPedidoModal = false;
  }

  closeEmpaquesModal() {
    this.showEmpaques = false;
  }

  closePagosSModal() {
    this.showPagoS = false;
  }

  closePagoRModal() {
    this.showPagoR = false;
  }

  closeRepartoMModal() {
    this.showRepartoM = false;
  }

  closeCuponesModal() {
    this.showCupones = false;
  }

  closeDireccionesModal() {
    this.showDirecciones = false;
  }

  closePMercadoModal() {
    this.showPMercado = false;
  }

  closeRecotizcionesModal() {
    this.showRecotizciones = false;
  }

  verDetalles(solicitud: any): void {
    this.showDetallesModal = true;
  }

  aceptarSolicitud(solicitud: any): void {
    console.log('Aceptar solicitud:', solicitud);
  }

  rechazarSolicitud(solicitud: any): void {
    const motivoRechazo = prompt('Ingrese el motivo de rechazo:');
    if (motivoRechazo) {
      console.log('Rechazar solicitud:', solicitud, 'Motivo:', motivoRechazo);
    }
  }

  closeDetallesModal(): void {
    this.showDetallesModal = false;
  }

  aplicarReporte(reporte: any): void {
    console.log('Aplicar reporte:', reporte);
  }

  rechazarReporte(reporte: any): void {
    const motivoRechazo = prompt('Ingrese el motivo de rechazo del reporte:');
    if (motivoRechazo) {
      console.log('Rechazar reporte:', reporte, 'Motivo:', motivoRechazo);
    }
  }

  loadCancellations(page: number = 1, limit: number = 10, sortBy: string = 'requested_at', sortOrder: 'desc', search: string = ''): void {
    this.cancellationService.getAllCancellations(page, limit, sortBy, sortOrder, search).subscribe(
      (data) => {
        this.cancellations = data.data.cancellations; 
        this.totalCancellations = data.data.totalCancellations; 
        this.totalPages = data.totalPages;
      },
      (error) => {
        console.error('Error al cargar los envíos:', error);
      }
    );
  }
}
