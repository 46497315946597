<div class="ml-64 mt-14">
    <div class="p-6">
        <span class="font-bold text-2xl"> Resumen </span>
        <div class="py-3 flex justify-end">
            <input type="date" class="rounded-lg p-2 border mx-2">
            <button class="bg-dagpk text-white rounded-lg py-2 px-3 font-bold hover:bg-orange-700 duration-200">
                Filtrar
            </button>
        </div>
        <div class="flex justify-between gap-3">
            <div class="bg-slate-100 w-full p-3 rounded-lg shadow-md">
                <span class="font-bold"> Cancelación de envios </span>                
                <p class="font-bold text-xl"> 0 <span class="text-red-600 font-sm">Pendientes</span> </p>

            </div>
            <div class="bg-slate-100 w-full p-3 rounded-lg shadow-md">
                <span class="font-bold"> Reportes de pago </span>
                <p class="font-bold text-xl"> 0 <span class="text-red-600 font-sm">Pendientes</span> </p>

            </div>
            <div class="bg-slate-100 w-full p-3 rounded-lg shadow-md">
                <span class="font-bold"> Reembolsos por CLIP </span>
                <p class="font-bold text-xl"> 0 <span class="text-red-600 font-sm">Pendientes</span> </p>

            </div>
            <div class="bg-slate-100 w-full p-3 rounded-lg shadow-md">
                <span class="font-bold"> Cancelación de envios </span>
                <p class="font-bold text-xl"> 0 <span class="text-red-600 font-sm">Pendientes</span> </p>
            </div>
        </div>
        <div class="flex w-full gap-3 py-3">            
            <div class="w-full gap-3 bg-slate-100 p-3 shadow-lg rounded-lg">                
                <div class="flex gap-3">
                    <div>
                        <button (click)="showShipments()" [ngClass]="{ 'bg-dagblue': isShipmentsOpen, 'text-white': isShipmentsOpen }" class="flex gap-2 border hover:bg-dagblue hover:text-white border-dagblue p-2 rounded-lg font-bold px-6 min-w-40 justify-center duration-200">                        
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                            </svg>                          
                            Envios
                        </button>
                    </div>
                    <div>
                        <button (click)="showPacking()" [ngClass]="{ 'bg-dagblue': isPackingOpen, 'text-white': isPackingOpen }" class="flex gap-2 border hover:bg-dagblue hover:text-white border-dagblue p-2 rounded-lg font-bold px-6 min-w-40 justify-center duration-200">                        
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                            </svg>                          
                            Empaques
                        </button>
                    </div>
                </div>
                <hr class="bg-dagpk w-full my-3 p-[0.5px]">
                <app-admin-shipments *ngIf="isShipmentsOpen"></app-admin-shipments>     
                <app-admin-packing *ngIf="isPackingOpen"></app-admin-packing>           
            </div>                        
        </div>
    </div>
</div>