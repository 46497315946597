import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class GavetaService {
  constructor(private http: HttpClient) {}

  createGaveta(gaveta: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/gabeta/create`, gaveta);
  }


  getGaveta(id_locker: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/gabeta/list-by-locker`, id_locker);
  }

  getSizeGaveta(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/gabeta/list-size`);
  }

  updateGavetaStatus(id_gabeta: string, status: boolean): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/gabeta/update-status/${id_gabeta}`, { status });
  }

  getLogsGaveta(id_gabeta: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/gaveta-log/list/${id_gabeta}`);
  }
  
  deleteGavetas(selectedGavetasIds: string[]): Observable<any> {
    const url = `${environment.apiUrl}/gabeta/delete`; // Use environment.apiUrl here
    return this.http.post(url, { ids: selectedGavetasIds });
  }



  

}
