import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Packing } from 'src/app/_models/packing';
import { CancellationService } from 'src/app/_services/cancellations/cancellation.service';
import { RefillRequestService } from 'src/app/_services/Refills/refill-request.service';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { UserServiceService } from 'src/app/_services/users/user-service.service';
import { ServicesService } from 'src/app/_services/Proveedores/services.service';
import { WalletService } from 'src/app/_services/Wallets/wallet.service';

interface Utility {
  $numberDecimal: string;
}

interface User {
  _id: string;
  dagpacketPercentaje?: Utility;
  servicesPercentaje?: Utility;
  recharguesPercentage?: Utility;
  packingPercentage?: Utility;
  [key: string]: any;
}

interface NewUtilities {
  dagpacketPercentaje: number | null;
  servicesPercentaje: number | null;
  recharguesPercentage: number | null;
  packingPercentage: number | null;
  [key: string]: number | null;
}

@Component({
  selector: 'app-olimpo',
  templateUrl: './olimpo.component.html',
  styleUrls: ['./olimpo.component.scss']
})
export class OlimpoComponent implements OnInit {
  @Output() closeModal = new EventEmitter<boolean>();

  newUtilities: NewUtilities = {
    dagpacketPercentaje: null,
    servicesPercentaje: null,
    recharguesPercentage: null,
    packingPercentage: null
  };

  showRoleConfirmation: boolean = false;
  newRole: string = '';
  isUpdating: boolean = false;

  // User properties
  users: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 5;
  totalItems: number = 0;
  totalPages: number = 0;
  searchTerm: string = '';
  Math = Math;
  isInitializing: boolean = false;

  showDeleteModal: boolean = false;

  // Cancellation properties
  cancellationRequests: any[] = [];
  cancellationCurrentPage: number = 1;
  cancellationItemsPerPage: number = 10;
  cancellationTotalPages: number = 1;
  cancellationTotalItems: number = 0;
  cancellationSearchTerm: string = '';
  cancellationSortBy: string = 'requested_at';
  cancellationSortOrder: 'asc' | 'desc' = 'desc';

  // Packing properties
  packings: any[] = [];
  packingCurrentPage: number = 1;
  packingTotalPages: number = 1;
  packingTotalItems: number = 0;
  packingItemsPerPage: number = 5;
  packingSearchTerm: string = '';

  // Refill properties
  refillRequests: any[] = [];
  refillCurrentPage: number = 1;
  refillTotalPages: number = 1;
  refillTotalItems: number = 0;
  refillItemsPerPage: number = 5;
  refillSearchTerm: string = '';

  // Modal properties
  showContainer: { [key: string]: boolean } = {};
  showEditUserDialog: boolean = false;
  showDetallesModal: boolean = false;
  showRechazarModal: boolean = false;
  showAprobarModal: boolean = false;
  showNewPackingModal: boolean = false;
  showUserModal: boolean = false;

  // Poveedores de servicios
  providers: any[] = [];
  showUtilityModal = false;
  selectedService: any;
  utilityPercentage: number = 0;

  // Other properties
  selectedUser: any = null;
  solicitudSeleccionada: any = null;
  solicitudParaAccion: any = null;
  razonRechazo: string = '';
  imageFile: File | null = null;
  alertMessage: string = '';
  isError: boolean = false;
  isLoading: boolean = false;
  showToast: boolean = false;
  toastMessage: string = '';
  toastType: 'success' | 'error' = 'success';
  selectedOption: string = '';
  fundAmount: number = 0;  

  showEditModal: boolean = false;
  editingPacking: any = {};
  selectedImage: File | null = null;
  showAlert: boolean = false;
  showAlertPacking: boolean = false;

  selectedPacking: any;
  selectedPackingId: string = '';

  // Nuevas propiedades para el modal de usuario
  selectedUserOption: string = 'info'; // Opción por defecto

  // Nuevas propiedades para la asignación de usuario padre
  potentialParentUsers: any[] = [];
  selectedParentUserId: string = '';

  constructor(
    private userService: UserServiceService,
    private cancellationService: CancellationService,
    private packingService: ShipmentsService,
    private refillRequestService: RefillRequestService,
    private servicesService: ServicesService,
    private walletService: WalletService
  ) {}

  ngOnInit(): void {
    this.loadUsers();
    this.loadCancellationRequests();
    this.loadPackings();
    this.loadRefillRequests();
    this.loadProviderServices();
    this.initializeUtilities();
  }

  getProviderLogo(providerName: string): string {
    switch(providerName.toLowerCase()) {
      case 'fedex':
        return '../../../../assets/paqueterias/Fedex.png';
      case 'superenvios':
        return '../../../../assets/paqueterias/superenvios.webp';      
      case 'paquete express':
        return '../../../../assets/paqueterias/Paquete Express.png'
      case 'dhl':
        return '../../../../assets/paqueterias/DHL.png'
      default:
        return 'assets/images/default-logo.png';
    }
  }

  loadProviderServices() {
    this.servicesService.getAllServices().subscribe(
      (res: any[]) => {
        this.providers = res.map(provider => ({
          ...provider,
          isExpanded: false
        }));
        console.log(this.providers);
      },
      (error) => {
        console.error('Error loading provider services:', error);
      }
    );
  }

  toggleProvider(provider: any) {
    provider.isExpanded = !provider.isExpanded;
  }

  openUtilityModal(service: any, providerName: string, serviceName: string) {
    this.selectedService = { ...service, providerName, serviceName };
    this.utilityPercentage = service.percentage;
    this.showUtilityModal = true;
  }

  closeUtilityModal() {
    this.showUtilityModal = false;
  }

  saveUtility() {
    this.servicesService.updateServiceUtility(
      this.selectedService.serviceName,
      this.selectedService.providerName,
      this.selectedService.idServicio,
      this.utilityPercentage
    ).subscribe(
      (res) => {
        console.log('Utility updated successfully', res);
        this.loadProviderServices();
        this.showToastMessage('Utilidad actualizada con éxito', 'success');
      },
      (error) => {
        console.error('Error updating utility:', error);
        this.showToastMessage('Error al actualizar la utilidad', 'error');
      }
    );
    this.closeUtilityModal();
  }  

  confirmDelete(){
    this.isLoading = true;
    this.packingService.deletePacking(this.selectedPackingId).subscribe(
      (res) => {
        if(res.success == true){
          this.showAlertPacking = res.message;
          this.showDeleteModal = false;
          this.isLoading = false;   
          this.loadPackings();
        } else {
          this.showAlertPacking = res.message;
          this.showDeleteModal = false;
          this.isLoading = false;   
          this.loadPackings();
        }
        setTimeout(() => {
          this.showAlertPacking = false;
        }, 2000);
      },
      (error) => {
        this.isLoading = false;
      }
    )
  }

  cancelDelete(){
    this.showDeleteModal = false;
  }

  editPacking(packing: any) {
    this.selectedPacking = { ...packing };
    this.selectedPackingId = packing._id;
    this.showEditModal = true;
  }

  openDeletePacking(packing_id: string){
    this.selectedPackingId = packing_id;
    this.showDeleteModal = true;
  }

  cancelEdit() {
    this.showEditModal = false;
    this.editingPacking = {};
    this.selectedImage = null;
  }

  // User methods
  loadUsers() {
    this.userService.listUsers(this.currentPage, this.itemsPerPage, this.searchTerm)
      .subscribe(
        (response) => {
          this.users = response.data.users;
          this.totalItems = response.data.totalUsers;
          this.totalPages = response.data.totalPages;
        },
        (error) => {
          console.error('Error fetching users:', error);
        }
      );
  }

  updatePacking(updatePackingForm: NgForm){        
    this.isLoading = true;
    this.packingService.updatePacking(this.selectedPackingId, updatePackingForm.value).subscribe(
      (res) => {
        if(res.success == true){
          this.showAlertPacking = res.message;
          this.showEditModal = false;
          this.isLoading = false;   
          this.loadPackings();
        } else {
          console.log(res);          
        }
      }
    )
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.loadUsers();
  }

  onSearch() {
    this.currentPage = 1;
    this.loadUsers();
  }

  editUser(user: any): void {
    this.selectedUser = user;
    this.showEditUserDialog = true;
  }

  activateUserAccount(userId: string): void {
    const user = this.users.find(u => u._id === userId);  
    if (!user || user.active) return;

    this.userService.activateAccount(userId).subscribe(
      response => {
        console.log('Cuenta activada:', response);
        if (user) user.active = true;
      },
      error => {
        console.error('Error al activar la cuenta:', error);
      }
    );
  }

  openUserModal(user: any) {
    this.selectedUser = { ...user }; // Copia profunda para evitar modificar el original directamente
    this.showUserModal = true;
    this.selectedUserOption = 'info';
    if (user.role === 'CAJERO') {
      this.loadPotentialParentUsers();
    }
  }

  closeUserModal() {
    this.showUserModal = false;
    this.selectedUser = null;
    this.selectedUserOption = 'info';
  }

  selectUserOption(option: string) {
    this.selectedUserOption = option;
    if (option === 'permissions' && this.selectedUser.role === 'CAJERO') {
      this.loadPotentialParentUsers();
    }
  }

  loadPotentialParentUsers() {
    this.userService.getPotentialParentUsers().subscribe(
      (response) => {
        this.potentialParentUsers = response.data;
      },
      (error) => {
        console.error('Error al cargar usuarios potenciales:', error);
      }
    );
  }

  assignParentUser() {
    if (this.selectedParentUserId && this.selectedUser) {
      this.userService.assignParentUser(this.selectedUser._id, this.selectedParentUserId).subscribe(
        (response) => {
          this.showToastMessage('Usuario padre asignado exitosamente', 'success');
          this.selectedUser.parentUser = this.selectedParentUserId;
        },
        (error) => {
          console.error('Error al asignar usuario padre:', error);
          this.showToastMessage('Error al asignar usuario padre', 'error');
        }
      );
    }
  }

  saveUserInfo() {
    if (this.selectedUser && this.selectedUser._id) {
      this.isLoading = true;
      const userData = {
        name: this.selectedUser.name,
        surname: this.selectedUser.surname,
        email: this.selectedUser.email,
        phone: this.selectedUser.phone,
        contractType: this.selectedUser.contractType,
        role: this.selectedUser.role,
        wallet: this.selectedUser.wallet
      };

      this.userService.updateUser(this.selectedUser._id, userData).subscribe(
        response => {
          this.isLoading = false;
          if (response.success) {
            this.showToastMessage('Usuario actualizado con éxito', 'success');
            // Actualizar el usuario en la lista de usuarios
            const index = this.users.findIndex(u => u._id === this.selectedUser._id);
            if (index !== -1) {
              this.users[index] = { ...this.selectedUser };
            }
          } else {
            this.showToastMessage(response.message, 'error');
          }
        },
        error => {
          this.isLoading = false;
          this.showToastMessage('Error al actualizar el usuario', 'error');
          console.error('Error al actualizar el usuario:', error);
        }
      );
    }
  }

  // Cancellation methods
  loadCancellationRequests(): void {
    this.cancellationService.getAllCancellations(
      this.cancellationCurrentPage,
      this.cancellationItemsPerPage,
      this.cancellationSortBy,
      this.cancellationSortOrder,
      this.cancellationSearchTerm
    ).subscribe(
      response => {
        if (response.success) {
          this.cancellationRequests = response.data.cancellations;
          this.cancellationTotalPages = response.data.totalPages;
          this.cancellationTotalItems = response.data.totalCancellations;
        } else {
          console.error('Error loading cancellation requests:', response.message);
        }
      },
      error => {
        console.error('Error loading cancellation requests:', error);
      }
    );
  }

  onCancellationPageChange(page: number): void {
    if (page >= 1 && page <= this.cancellationTotalPages) {
      this.cancellationCurrentPage = page;
      this.loadCancellationRequests();
    }
  }

  searchCancellationRequests(): void {
    this.cancellationCurrentPage = 1;
    this.loadCancellationRequests();
  }

  verDetalles(solicitud: any): void {
    this.solicitudSeleccionada = solicitud;
    this.showDetallesModal = true;
  }

  abrirModalAccion(solicitud: any, accion: 'aprobar' | 'rechazar'): void {
    this.solicitudParaAccion = solicitud;
    if (accion === 'rechazar') {
      this.showRechazarModal = true;
    } else {
      this.showAprobarModal = true;
    }
  }

  cerrarModalRechazo(): void {
    this.showRechazarModal = false;
    this.solicitudParaAccion = null;
    this.razonRechazo = '';
  }

  cerrarModalAprobacion(): void {
    this.showAprobarModal = false;
    this.solicitudParaAccion = null;
  }

  confirmarAprobacion(): void {
    this.confirmarAccion('Aprobado');
    this.cerrarModalAprobacion();
  }

  confirmarAccion(status: 'Aprobado' | 'Rechazado'): void {
    if (!this.solicitudParaAccion) return;

    let datos: any = { status };
    if (status === 'Rechazado') {
      if (!this.razonRechazo.trim()) {
        alert('Por favor, ingrese una razón para el rechazo.');
        return;
      }
      datos.rejection_reason = this.razonRechazo;
    }

    this.cancellationService.updateCancellationRequest(this.solicitudParaAccion._id, datos).subscribe(
      (response) => {
        if (response.success) {
          console.log(`Solicitud ${status.toLowerCase()}:`, response);
          this.loadCancellationRequests();
          if (status === 'Rechazado') {
            this.cerrarModalRechazo();
          } else {
            this.cerrarModalAprobacion();
          }
          this.closeDetallesModal();
        } else {
          console.error(`Error al ${status.toLowerCase()} la solicitud:`, response.message);
          alert(response.message || 'Ocurrió un error al procesar la solicitud.');
        }
      },
      (error) => {
        console.error(`Error al ${status.toLowerCase()} la solicitud:`, error);
        alert('Ocurrió un error al procesar la solicitud. Por favor, intente nuevamente.');
      }
    );
  }

  closeDetallesModal(): void {
    this.showDetallesModal = false;
    this.solicitudSeleccionada = null;
  }

  // Packing methods
  loadPackings(): void {
    this.packingService.getAllPackingList(this.packingCurrentPage, this.packingItemsPerPage, this.packingSearchTerm)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.packings = response.data.packings;
            this.packingTotalPages = response.data.totalPages;
            this.packingTotalItems = response.data.totalItems;
          } else {
            console.error('Error loading packings:', response.message);
          }
        },
        error => {
          console.error('Error loading packings:', error);
        }
      );
  }

  onPackingPageChange(page: number): void {
    if (page >= 1 && page <= this.packingTotalPages) {
      this.packingCurrentPage = page;
      this.loadPackings();
    }
  }

  searchPackings(): void {
    this.packingCurrentPage = 1;
    this.loadPackings();
  }

  openNewPackingModal() {
    this.showNewPackingModal = true;
  }

  closeNewPackingModal(result: { success: boolean, message: string }) {
    this.showNewPackingModal = false;
    this.alertMessage = result.message;
    this.isError = !result.success;
    if (result.success) {
      this.loadPackings();
    }
    setTimeout(() => {
      this.alertMessage = '';
      this.isError = false;
    }, 5000);
  }

  // Refill methods
  loadRefillRequests(): void {
    this.refillRequestService.getRefillRequests(this.refillCurrentPage, this.refillItemsPerPage, this.refillSearchTerm)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.refillRequests = response.data.requests;
            this.refillTotalPages = response.data.totalPages;
            this.refillTotalItems = response.data.totalItems;
          } else {
            console.error('Error loading refill requests:', response.message);
          }
        },
        error => {
          console.error('Error loading refill requests:', error);
        }
      );
  }

  onRefillPageChange(page: number): void {
    if (page >= 1 && page <= this.refillTotalPages) {
      this.refillCurrentPage = page;
      this.loadRefillRequests();
    }
  }

  searchRefillRequests(): void {
    this.refillCurrentPage = 1;
    this.loadRefillRequests();
  }

  approveRefillRequest(requestId: string): void {
    const adminNotes = ''; // Puedes implementar un modal para capturar estas notas
    this.refillRequestService.approveRefillRequest(requestId, adminNotes)
      .subscribe(
        response => {
          if (response.success) {
            console.log('Refill request approved');
            this.loadRefillRequests();
          } else {
            console.error('Error approving refill request:', response.message);
          }
        },
        error => {
          console.error('Error approving refill request:', error);
        }
      );
  }

  rejectRefillRequest(requestId: string): void {
    const adminNotes = ''; // Puedes implementar un modal para capturar estas notas
    this.refillRequestService.rejectRefillRequest(requestId, adminNotes)
      .subscribe(
        response => {
          if (response.success) {
            console.log('Refill request rejected');
            this.loadRefillRequests();
          } else {
            console.error('Error rejecting refill request:', response.message);
          }
        },
        error => {
          console.error('Error rejecting refill request:', error);
        }
      );
  }

  // Utility methods
  toggleContainer(id: string | number): void {
    this.showContainer[id] = !this.showContainer[id];
  }

  showToastMessage(message: string, type: 'success' | 'error') {
    this.toastMessage = message;
    this.toastType = type;
    this.showToast = true;
    setTimeout(() => {
      this.showToast = false;
    }, 3000);
  }

  onImageSelected(event: any) {
    this.imageFile = event.target.files[0];
  }

  selectOption(option: string) {
    this.selectedOption = option;
  }

  openRoleConfirmation(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.newRole = selectElement.value;
    this.showRoleConfirmation = true;
  }

  cancelRoleChange() {
    this.showRoleConfirmation = false;
    this.newRole = '';
    // Revertir la selección en el dropdown
    if (this.selectedUser) {
      this.selectedUser.role = this.selectedUser.role;
    }
  }

  confirmRoleChange() {
    if (this.selectedUser && this.selectedUser._id) {
      this.isLoading = true;
      this.userService.assignRole(this.selectedUser._id, this.newRole).subscribe(
        response => {
          this.isLoading = false;
          if (response.success) {
            this.showToastMessage('Rol de usuario actualizado con éxito', 'success');
            if (this.selectedUser) {
              this.selectedUser.role = this.newRole;
            }
            // Actualizar el usuario en la lista de usuarios si es necesario
            const index = this.users.findIndex(u => u._id === this.selectedUser?._id);
            if (index !== -1) {
              this.users[index].role = this.newRole;
            }
          } else {
            this.showToastMessage(response.message || 'Error al actualizar el rol', 'error');
          }
          this.showRoleConfirmation = false;
          this.newRole = '';
        },
        error => {
          this.isLoading = false;
          this.showToastMessage('Error al actualizar el rol del usuario', 'error');
          console.error('Error al actualizar el rol del usuario:', error);
          this.showRoleConfirmation = false;
          this.newRole = '';
        }
      );
    }
  }

  initializeUtilities() {
    if (this.selectedUser) {
      this.selectedUser.dagpacketPercentaje = this.selectedUser.dagpacketPercentaje?.$$numberDecimal || 0;
      this.selectedUser.servicesPercentaje = this.selectedUser.servicesPercentaje?.$$numberDecimal || 0;
      this.selectedUser.recharguesPercentage = this.selectedUser.recharguesPercentage?.$$numberDecimal || 0;
      this.selectedUser.packingPercentage = this.selectedUser.packingPercentage?.$$numberDecimal || 0;
    }
  }

  updateUserUtilities() {
    if (!this.selectedUser || !this.selectedUser._id) {
      console.error('No hay usuario seleccionado o falta el ID');
      return;
    }

    this.isUpdating = true;

    const updatedPercentages = {
      dagpacketPercentaje: parseFloat(this.selectedUser.dagpacketPercentaje) || 0,
      servicesPercentaje: parseFloat(this.selectedUser.servicesPercentaje) || 0,
      recharguesPercentage: parseFloat(this.selectedUser.recharguesPercentage) || 0,
      packingPercentage: parseFloat(this.selectedUser.packingPercentage) || 0
    };

    this.userService.updateUserPercentages(this.selectedUser._id, updatedPercentages)
      .subscribe(
        (response: any) => {
          this.toastType = 'success';
          this.toastMessage = 'Utilidades actualizadas';
          this.showToast = true;
          this.isInitializing = false;   
          this.isUpdating = false;       
        },
        (error) => {
          this.toastType = 'error';
          this.toastMessage = error.error.message || 'No se pudieron actualizar las utilidades';
          this.showToast = true;
          this.isInitializing = false;
          this.isUpdating = false;
        }
      );
  }

  updateUserDiscounts() {
    this.saveUserInfo();
  }

  updateContractType() {
    this.saveUserInfo();
  }

  verGuia(guia: string) {
    console.log('Ver guía:', guia);
    // Implementa la lógica para ver la guía
  }

  deletePacking(packingId: string) {
    this.showDeleteModal = true;
    this.selectedPackingId = packingId;
  }

  initializeWallet(userId: string) {
    this.isInitializing = true;
    this.walletService.initializeWallet(userId).subscribe(
      (res) => {
        this.toastType = 'success';
        this.toastMessage = 'Wallet inicializado correctamente';
        this.showToast = true;
        this.isInitializing = false;
        // Actualizar la información del usuario después de inicializar el wallet
        this.loadUsers();
      },
      (error) => {
        this.toastType = 'error';
        this.toastMessage = error.error.message || 'No se pudo inicializar el wallet';
        this.showToast = true;
        this.isInitializing = false;
      }
    );
  }

  addFunds(type: 'send' | 'recharge' | 'services') {
    if (!this.selectedUser || !this.selectedUser.wallet) {
      this.showToastMessage('El usuario no tiene un wallet inicializado', 'error');
      return;
    }

    // Implementa la lógica para agregar fondos
    console.log(`Agregar fondos de tipo ${type} para el usuario ${this.selectedUser.name}`);
    // Aquí deberías abrir un modal o formulario para capturar la cantidad a agregar
  }

  showTransactionHistory() {
    if (!this.selectedUser || !this.selectedUser.wallet) {
      this.showToastMessage('El usuario no tiene un wallet inicializado', 'error');
      return;
    }

    // Implementa la lógica para mostrar el historial de transacciones
    console.log(`Mostrar historial de transacciones para el usuario ${this.selectedUser.name}`);
    // Aquí deberías abrir un modal o navegar a una nueva vista con el historial de transacciones
  }
}