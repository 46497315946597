import { Component, OnInit } from '@angular/core';
import { LockersService } from 'src/app/_services/Lockers/lockers.service';
import { Lockers } from 'src/app/_models/lockers';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-admin-lockers',
  templateUrl: './admin-lockers.component.html',
  styleUrls: ['./admin-lockers.component.scss'],
})
export class AdminLockersComponent implements OnInit {
  lockers: Lockers[] = [];
  showAddLocker = false;

  newLocker: Lockers = {
    _id: '',
    id_locker: '',
    ubication: '',
    package: '',
    quant_gabetas: 0,
    lat: 0,
    long: 0,
    status: true,
    capacity: 0,
    saturation: 0,
    street: '',
    cp: '',
    city: '',
    state: '',
    country: '',
    num_ext: 0,
  };

  openAddLocker() {
    this.showAddLocker = true;
    this.newLocker = {
      _id: '',
      id_locker: '',
      ubication: '',
      package: '',
      quant_gabetas: 0,
      lat: 0,
      long: 0,
      status: true,
      capacity: 0,
      saturation: 0,
      street: '',
      cp: '',
      city: '',
      state: '',
      country: '',
      num_ext: 0,
      Owner: '',
    };
  }

  closeAddLocker() {
    this.showAddLocker = false;
  }

  
  addLocker(form: NgForm) {
    if (form.invalid) {
      // Highlight all invalid fields
      Object.keys(form.controls).forEach(key => {
        const control = form.controls[key];
        if (control.invalid) {
          control.markAsTouched();
        }
      });

      Swal.fire({
        icon: 'error',
        title: 'Error al añadir el locker',
        text: 'Por favor, llena todos los campos requeridos',
      });
      return;
    }

    this.lockerService.createLocker(this.newLocker).subscribe(
      (data: any) => {
        console.log('Locker añadido', data);
        Swal.fire({
          icon: 'success',
          title: 'Locker añadido',
          text: 'Locker añadido correctamente',
        });

        this.getLockers();
        this.closeAddLocker();
      },
      (error) => {
        console.log('Error al añadir el locker', error);
        Swal.fire({
          icon: 'error',
          title: 'Error al añadir el locker',
          text: 'Por favor, intenta de nuevo',
        });
      }
    );
  }


  constructor(private lockerService: LockersService) {}

  ngOnInit(): void {
    this.getLockers();
  }

  getLockers(): void {
    this.lockerService.getLockers().subscribe(
      (data: any) => {
        // Cambia el tipo a 'any' si no tienes un modelo específico para la respuesta completa
        this.lockers = data.message.map((locker: any) => ({
          _id: locker._id,
          id_locker: locker.id_locker,
          ubication: locker.ubication,
          package: locker.package,
          quant_gabetas: locker.quant_gabetas,
          lat: locker.lat,
          long: locker.long,
          status: locker.status,
          capacity: locker.capacity,
          saturation: locker.saturation,
          street: locker.street,
          cp: locker.cp,
          city: locker.city,
          state: locker.state,
          country: locker.country,
          num_ext: locker.num_ext,
        }));
        console.log('Lockers obtenidos', this.lockers);
      },
      (error) => {
        console.log('Error al obtener los lockers', error);
      }
    );
  }
}
