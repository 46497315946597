import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
  @Input() message!: string;

  showLoader = false;
  errorMessage = '';
  showPassword = false;

  constructor(
    private authService: AuthLoginService,
    private router: Router
  ) {}

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  signIn(loginForm: NgForm) {
    if (loginForm.invalid) {
      this.errorMessage = 'Por favor, complete todos los campos correctamente.';
      return;
    }

    this.showLoader = true;
    this.errorMessage = '';

    this.authService.signInUser(loginForm.value).subscribe(
      (res: any) => {
        this.showLoader = false;
        if (res.success === true) {
          this.router.navigate(['/landing']);
        } else {
          this.errorMessage = res.message || 'Ha ocurrido un error durante el inicio de sesión.';
        }
      },
      (error) => {
        this.showLoader = false;
        this.errorMessage = error.error?.message || 'Ha ocurrido un error en la conexión. Por favor, inténtelo de nuevo.';
      }
    );
  }
}