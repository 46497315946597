// cashiers.component.ts
import { Component, OnInit } from '@angular/core';
import { CashRegisterService } from 'src/app/_services/Cashiers/cash-register.service';

@Component({
  selector: 'app-cashiers',
  templateUrl: './cashiers.component.html',
  styleUrls: ['./cashiers.component.scss']
})
export class CashiersComponent implements OnInit {
  cashiers: any[] = [];
  paginatedCashiers: any[] = [];
  filteredCashiers: any[] = [];
  searchTerm: string = '';
  startDate: string = '';
  endDate: string = '';
  currentPage: number = 1;
  pageSize: number = 10;
  totalItems: number = 0;
  totalPages: number = 0;
  Math: any = Math;

  currentCashRegister: any = null;
  transactions: any[] = [];
  loading: boolean = false;
  error: string | null = null;

  constructor(private cashRegisterService: CashRegisterService) {}

  ngOnInit() {  
    this.checkCurrentCashRegister();
  }

  checkCurrentCashRegister() {
    this.loading = true;
    this.cashRegisterService.getCurrentCashRegister().subscribe(
      (response) => {
        this.currentCashRegister = response.data;
        if (this.currentCashRegister) {
          this.loadTransactions();
        }
        this.loading = false;
      },
      (error) => {
        this.error = 'Error al obtener el estado de la caja';
        this.loading = false;
      }
    );
  }

  loadTransactions() {
    this.loading = true;
    this.cashRegisterService.getCashTransactions(this.currentPage, this.pageSize).subscribe(
      (response) => {
        if (response.success) {
          this.transactions = response.data.transactions;
          this.totalItems = response.data.totalTransactions;
          this.totalPages = response.data.totalPages;
          this.currentPage = response.data.currentPage;
        } else {
          this.error = 'Error al cargar las transacciones';
        }
        this.loading = false;
      },
      (error) => {
        this.error = 'Error al cargar las transacciones';
        this.loading = false;
      }
    );
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.loadTransactions();
  }

  openCashRegister() {
    this.loading = true;
    this.cashRegisterService.openCashRegister().subscribe(
      (data) => {
        this.currentCashRegister = data;
        this.loading = false;
        this.error = null;
        this.loadTransactions(); // Cargar transacciones después de abrir la caja
      },
      (error) => {
        this.error = 'Error al abrir la caja';
        this.loading = false;
      }
    );
  }

  closeCashRegister() {
    this.loading = true;
    this.error = null;
    this.cashRegisterService.closeCashRegister().subscribe(
      (response) => {
        if (response.success) {
          this.currentCashRegister = null;
          this.transactions = [];
          this.totalItems = 0;
          this.totalPages = 0;
          console.log(response.message);
        } else {
          this.error = response.message;
        }
        this.loading = false;
      },
      (error) => {
        this.error = error.message || 'Error al cerrar la caja';
        this.loading = false;
      }
    );
  }
  applyFilters() {
    this.currentPage = 1; // Resetear a la primera página al aplicar filtros
    this.loadTransactions();
  }

  getPaginationRange(): number[] {
    const range = 2; // Número de páginas a mostrar antes y después de la página actual
    const start = Math.max(1, this.currentPage - range);
    const end = Math.min(this.totalPages, this.currentPage + range);
    return Array.from({length: (end - start + 1)}, (_, i) => start + i);
  }
  // En tu cashiers.component.ts

  getPaymentMethodIcon(method: string): string {
    // Retorna la URL del ícono basado en el método de pago
    switch (method) {
      case 'td-debito':
        return '../../../assets/icons/debit-card.png';
      case 'td-credito':
        return '../../../assets/icons/debit-card.png';
      case 'efectivo':
        return '../../../assets/icons/cash.png';
      default:
        return '../../../assets/icons/saldo.png';
    }
  }

  getPaymentMethodName(method: string): string {
    // Retorna un nombre más amigable para el método de pago
    switch (method) {
      case 'credit':
        return 'Tarjeta de Crédito';
      case 'debit':
        return 'Tarjeta de Débito';
      case 'cash':
        return 'Efectivo';
      default:
        return method;
    }
  }

}