<div class="grid grid-cols-3 w-full h-16">
    <div onclick="stepChange(1)" class="stepBtn1 bg-gray-100 flex justify-center items-center cursor-pointer active">
        <h3 class="text-xl">1. Escanear QR</h3>
    </div>
    <div onclick="stepChange(2)" class="stepBtn2 bg-gray-200 flex justify-center items-center cursor-pointer">
        <h3 class="text-xl">2. Recoge tu paquete</h3>
    </div>
    <div onclick="stepChange(3)" class="stepBtn3 bg-gray-300 flex justify-center items-center cursor-pointer">
        <h3 class="text-xl">3. Finalizar</h3>
    </div>
</div>
<main class="fixed h-screen w-screen flex justify-center items-center">
    <img src="../../../assets/locker_images/logo.webp" alt="DagPacket Logo" class="fixed top-16 w-1/6 mt-4">
    <a href="locker-screen" class="fixed top-16 left-4 z-40 w-1/6 mt-4 bg-gray-300 w-auto px-6 py-2 rounded-full cursor-pointer hover:bg-gray-400">Cancelar</a>
    <div class="step1 flex flex-col justify-center items-center gap-16 w-full absolute h-full">
        <div style="width: 300px; height: 300px;">
            <ng-lottie [options]="qrscan_lottie" background="transparent" speed="1" style="width: 300px; height: 300px;" loop autoplay></ng-lottie>
        </div>
        <h1 class="text-4xl font-semibold mx-8 text-center">Escanea tu <span class="text-orange-500">código QR</span></h1>
        <form method="POST" action="">
            <input type="text" name="lockerID" placeholder="ID del locker" class="text-4xl p-2 p-4 text-center" onkeydown="if(event.keyCode === 13) this.form.submit()">
        </form>
    </div>
</main>

       