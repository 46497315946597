<div class="px-6 ml-36">
    <div class="bg-gray-100 rounded-lg w-[60vw] shadow-lg">
        <form #updateFormA="ngForm" (submit)="updateAddressA(updateFormA)" novalidate>
            <div class="p-4 flex justify-between">
                <div class="flex-col p-2">                
                    <span class="font-bold text-lg"> Dirección </span>          
                    <p>{{ user.address.street }}</p>
                    <p>{{ user.address.settlement }}</p>
                </div>
                <div class="p-2">
                    <button [disabled]="updateFormA.invalid" class="bg-dagblue p-3 rounded-lg text-white font-bold duration-200 hover:bg-blue-600 disabled:opacity-50">
                        Guardar Cambios
                    </button>
                </div>
            </div>
            <div class="px-4 py-4 flex justify-start w-full gap-6">
                <div class="relative z-0 mb-5 group w-1/2">
                    <input type="text" name="street" id="street" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " required [(ngModel)]="user.address.street" #street="ngModel"/>
                    <label for="street" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-dagblue peer-focus:dark:text-dagblue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Calle</label>
                    <div *ngIf="street.invalid && (street.dirty || street.touched)" class="text-red-500 text-sm">
                        Calle es obligatoria.
                    </div>
                </div>
                <div class="relative z-0 mb-5 group w-1/2">
                    <input type="text" name="city" id="city" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " required [(ngModel)]="user.address.city" #city="ngModel"/>
                    <label for="city" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-dagblue peer-focus:dark:text-dagblue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Ciudad</label>
                    <div *ngIf="city.invalid && (city.dirty || city.touched)" class="text-red-500 text-sm">
                        Ciudad es obligatoria.
                    </div>
                </div>
            </div>
            <div class="px-4 flex justify-start w-full gap-6">
                <div class="relative z-0 mb-5 group w-1/2">
                    <input type="text" name="state" id="state" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " required [(ngModel)]="user.address.state" #state="ngModel"/>
                    <label for="state" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-dagblue peer-focus:dark:text-dagblue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Estado</label>
                    <div *ngIf="state.invalid && (state.dirty || state.touched)" class="text-red-500 text-sm">
                        Estado es obligatorio.
                    </div>
                </div>
                <div class="relative z-0 mb-5 group w-1/2">
                    <input type="text" name="country" id="country" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " required [(ngModel)]="user.address.country" #country="ngModel"/>
                    <label for="country" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-dagblue peer-focus:dark:text-dagblue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">País</label>
                    <div *ngIf="country.invalid && (country.dirty || country.touched)" class="text-red-500 text-sm">
                        País es obligatorio.
                    </div>
                </div>
            </div>
            <div class="px-4 flex justify-start w-full gap-6">
                <div class="relative z-0 mb-5 group w-1/2">
                    <input type="number" name="zip_code" id="zip_code" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " required [(ngModel)]="user.address.zip_code" #zip_code="ngModel"/>
                    <label for="zip_code" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-dagblue peer-focus:dark:text-dagblue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">C.P</label>
                    <div *ngIf="zip_code.invalid && (zip_code.dirty || zip_code.touched)" class="text-red-500 text-sm">
                        C.P es obligatorio.
                    </div>
                </div>
                <div class="relative z-0 mb-5 group w-1/2">
                    <input type="number" name="external_number" id="external_number" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " required [(ngModel)]="user.address.external_number" #external_number="ngModel"/>
                    <label for="external_number" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-dagblue peer-focus:dark:text-dagblue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Número Exterior</label>
                    <div *ngIf="external_number.invalid && (external_number.dirty || external_number.touched)" class="text-red-500 text-sm">
                        Número Exterior es obligatorio.
                    </div>
                </div>
            </div>
            <div class="px-4 flex justify-start w-full gap-6">
                <div class="relative z-0 mb-5 group w-1/3">
                    <input type="number" name="internal_number" id="internal_number" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " required [(ngModel)]="user.address.internal_number" #internal_number="ngModel"/>
                    <label for="internal_number" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-dagblue peer-focus:dark:text-dagblue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Número Interior</label>
                    <div *ngIf="internal_number.invalid && (internal_number.dirty || internal_number.touched)" class="text-red-500 text-sm">
                        Número Interior es obligatorio.
                    </div>
                </div>
                <div class="relative z-0 mb-5 group w-1/3">
                    <input type="text" name="settlement" id="settlement" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " required [(ngModel)]="user.address.settlement" #settlement="ngModel"/>
                    <label for="settlement" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-dagblue peer-focus:dark:text-dagblue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Colonia</label>
                    <div *ngIf="settlement.invalid && (settlement.dirty || settlement.touched)" class="text-red-500 text-sm">
                        Colonia es obligatoria.
                    </div>
                </div>
                <div class="relative z-0 mb-5 group w-1/3">
                    <input type="text" name="municipality" id="municipality" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " required [(ngModel)]="user.address.municipality" #municipality="ngModel"/>
                    <label for="municipality" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-dagblue peer-focus:dark:text-dagblue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Municipio</label>
                    <div *ngIf="municipality.invalid && (municipality.dirty || municipality.touched)" class="text-red-500 text-sm">
                        Municipio es obligatorio.
                    </div>
                </div>
            </div>
        </form>             
    </div>

    <app-success-alert *ngIf="success" [message]="message"></app-success-alert>
    <app-success-alert *ngIf="error"></app-success-alert>
</div>