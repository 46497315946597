<app-sidebar></app-sidebar>
<div class="ml-64 pt-20 mx-auto p-3">

  <!-- Steps -->
  <div class="flex items-center mb-4">
      <div class="flex items-center space-x-2">
          <div class="bg-dagblue text-white font-bold rounded-full h-8 w-8 flex items-center justify-center">1</div>
          <span>Cart</span>
      </div>
      <div class="flex-grow border-t border-gray-300 mx-2"></div>
      <div class="flex items-center space-x-2">
          <div class="bg-gray-300 text-white font-bold rounded-full h-8 w-8 flex items-center justify-center">2</div>
          <span class="text-gray-500">Pago</span>
      </div>
  </div>

  <!-- Main content -->
  <div class="flex space-x-6">
      <!-- Cart -->
      <div class="bg-white shadow-lg rounded-lg p-4 flex-1">
          <div class="flex justify-between items-center mb-4">
              <div class="flex flex-col items-start space-x-2">
                  <div class="flex gap-2 items-center">
                    <img [src]="'../../../../assets/paqueterias/' + paqueteriaData.proveedor + '.png'" alt="" class="rounded-full w-24 h-16">
                    <span class="text-lg font-bold">{{ paqueteriaData.proveedor }}</span>
                  </div>
                  <span class="text-lg font-bold">{{ fromData.city }} a {{ toData.city }}</span>
              </div>
              <span class="text-dagblue text-xl font-bold">{{ paqueteriaData?.precio | currency }}</span>
          </div>
          <div class="space-y-4">
            <div class="flex">
              <span class="block font-bold">Descripcion del paquete:</span>
              <span class="block">{{ shipmentData.description }}</span>
          </div>
              <div>
                  <span class="block font-bold">De:</span>
                  <span class="block">{{ fromData.name }}</span>
              </div>
              <div>
                  <span class="block font-bold">Para:</span>
                  <span class="block">{{ toData.name }}</span>
              </div>
              <div class="space-y-2">
                  <div>
                      <span class="block font-bold">Origen</span>
                      <div class="border-t border-gray-300 mt-2 mb-2"></div>
                      <span class="block">Ciudad: {{ fromData.city }}</span>
                      <span class="block">C.P.: {{ fromData.zip_code }}</span>
                      <span class="block">Calle: {{ fromData.street }}</span>
                      <span class="block">Nombre: {{ fromData.name }}</span>
                      <span class="block">Email: {{ fromData.email }}</span>
                      <span class="block">Phone: {{ fromData.phone }}</span>
                  </div>
                  <div>
                      <span class="block font-bold">Destino</span>
                      <div class="border-t border-gray-300 mt-2 mb-2"></div>
                      <span class="block">Ciudad: {{ toData.city }}</span>
                      <span class="block">C.P.: {{ toData.zip_code }}</span>
                      <span class="block">Calle: {{ toData.street }}</span>
                      <span class="block">Nombre: {{ toData.name }}</span>
                      <span class="block">Email: {{ toData.email }}</span>
                      <span class="block">Phone: {{ toData.phone }}</span>
                  </div>
              </div>
              <!-- Añadir información de dimensiones -->
              <div>
                  <span class="block font-bold">Dimensiones del paquete</span>
                  <div class="border-t border-gray-300 mt-2 mb-2"></div>
                  <div class="flex gap-2">
                    <span class="block font-bold">Alto: <span class="font-light">{{ dimensionesData.height }}cm</span></span>
                    <span class="block font-bold">Ancho: {{ dimensionesData.width }}</span>
                    <span class="block font-bold">Largo: <span class="font-light">{{ dimensionesData.length }}cm</span></span>
                    <span class="block font-bold">Peso: <span class="font-light">{{ dimensionesData.package_weight }}kg</span></span>
                  </div>
              </div>
              <div class="flex justify-end">
                  <button class="text-red-500">
                      <i class="fa fa-trash"></i>
                  </button>
              </div>
          </div>
          <button class="bg-dagblue text-white p-2 rounded-lg mt-4 w-full">
              Agregar otro paquete
          </button>
      </div>

      <!-- Summary -->      
      <div class="bg-white rounded-lg p-4 w-1/3">
        <h3 class="text-xl font-bold mb-4">Sumario de Compra</h3>
        <div class="space-y-2">
          <div class="flex justify-between">
            <span>Sub Total Paquetes</span>
            <span>{{ subtotal | currency }}</span>
          </div>
          <!-- Dentro del resumen de compra -->
          <div class="flex justify-between">
            <span>Empaque: {{ selectedPackage ? selectedPackage.name : 'Ninguno' }}</span>
            <span>{{ packageCost | currency }}</span>
          </div>
          <div class="flex justify-between" *ngIf="discount !== 0">
            <span>Descuento</span>
            <span [ngClass]="{'text-green-500': discount > 0, 'text-red-500': discount < 0}">
              {{ discount > 0 ? '-' : '+' }}{{ Math.abs(discount) | currency }}
            </span>
          </div>
          <!-- Añadir ganancia de Dagpacket -->
        </div>
        <button class="bg-dagblue text-white p-2 rounded-lg mt-4 w-full" (click)="openDiscountDialog()">
          {{ discount === 0 ? 'Aplicar Descuento' : 'Modificar Descuento' }}
        </button>
        <div class="border-t border-gray-300 mt-4 mb-4"></div>
        <div class="flex justify-between mt-4">
          <span class="text-lg font-bold">Total</span>
          <span class="text-lg font-bold text-dagblue">{{ total | currency }}</span>
        </div>
        <button (click)="createNewShipment()" class="bg-dagblue text-white p-2 rounded-lg mt-4 w-full">
          Pagar Paquetería
        </button>
      </div>
  </div>
</div>

<div *ngIf="isDiscountDialogOpen" class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity" (click)="closeDiscountDialog()">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Aplicar Descuento o Costo Extra
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                Seleccione si desea aplicar un descuento o agregar un costo extra:
              </p>
              <div class="mt-4">
                <label class="inline-flex items-center">
                  <input type="radio" class="form-radio" name="discountType" value="subtract" [(ngModel)]="discountType">
                  <span class="ml-2">Descontar</span>
                </label>
              </div>
              <div class="mt-2">
                <label class="inline-flex items-center">
                  <input type="radio" class="form-radio" name="discountType" value="add" [(ngModel)]="discountType">
                  <span class="ml-2">Agregar costo extra</span>
                </label>
              </div>
              <div class="mt-4">
                <label class="block">
                  <span class="text-gray-700 font-bold">Cantidad:</span>
                  <input type="number" class="form-input p-2 border-2 rounded-lg mt-1 block w-full" [(ngModel)]="discountAmount">
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button (click)="applyDiscount()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dagblue text-base font-medium text-white hover:bg-dagblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dagblue sm:ml-3 sm:w-auto sm:text-sm">
          {{ discountType === 'subtract' ? 'Aplicar Descuento' : 'Agregar Costo Extra' }}
        </button>
        <button (click)="closeDiscountDialog()" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</div>

