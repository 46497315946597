<app-sidebar></app-sidebar>
<div class="container mx-auto p-4 mt-10">
    <div class="bg-white rounded-lg shadow p-6">
      <div class="mb-4 text-center">
        <h1 class="text-2xl font-bold">Envios Hola </h1>
        <hr class="border-gray-300 mt-2">
      </div>
  
      <div class="mb-4 flex justify-between items-center">
        <button class="bg-dagblue text-white py-2 px-4 rounded">Nueva cotizacion</button>
      </div>
  
      <div class="mb-4 flex justify-between items-center">
        <input type="text" placeholder="Buscar Paquete..." class="border rounded p-2 flex-grow mr-2">
        <input type="date" class="border rounded p-2 mr-2">
        <input type="date" class="border rounded p-2 mr-2">
        <button class="bg-gray-200 p-2 rounded mr-2">X</button>
        <button class="bg-green-500 text-white py-2 px-4 rounded mr-2">Excel</button>
        <button class="bg-gray-200 p-2 rounded">Filter</button>
      </div>
  
      <div class="overflow-x-auto">
        <table class="min-w-full bg-white">
          <thead class="bg-gray-200">
            <tr>
              <th class="py-2 px-4">#</th>
              <th class="py-2 px-4">Paqueteria</th>
              <th class="py-2 px-4">Fecha</th>
              <th class="py-2 px-4">Precio</th>
              <th class="py-2 px-4">Origen</th>
              <th class="py-2 px-4">Destino</th>
              <th class="py-2 px-4">Estado de pago</th>
              <th class="py-2 px-4">Estado de envio</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="py-2 px-4 text-center" colspan="8">Not found<br>No results found for "". Try checking for typos or using complete words.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr class="border-gray-300 mt-2">
      <div class="flex justify-between items-center mt-4">
        <div class="flex items-center">
          <span class="mr-2">Rows per page:</span>
          <select class="border rounded p-2">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
        </div>
        <div>1-2 of 2</div>
        <div class="flex space-x-2">
          <button class="bg-gray-200 p-2 rounded">&lt;</button>
          <button class="bg-gray-200 p-2 rounded">&gt;</button>
        </div>
      </div>
    </div>
</div>
  
  
  
  
  
