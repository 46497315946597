<app-sidebar></app-sidebar>
<div class="ml-64 mt-14 bg-gray-100 min-h-screen">
    <div class="p-6">
        <div class="flex justify-between items-center mb-6">
            <span class="font-bold text-dagblue uppercase text-2xl">ALMACÉN</span>
            <button (click)="openRequestStockDialog()" class="bg-dagblue text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                </svg>
                Solicitar Stock
            </button>
        </div>
        
        <div class="bg-white p-4 rounded-lg shadow-md mb-6">
            <div class="flex flex-wrap items-center justify-between">
                <div class="flex items-center space-x-4 mb-4 sm:mb-0">
                    <div>
                        <label for="start-date" class="block text-sm font-medium text-gray-700 mb-1">Desde:</label>
                        <input type="date" id="start-date" class="border rounded-md p-2" [(ngModel)]="startDate">
                    </div>
                    <div>
                        <label for="end-date" class="block text-sm font-medium text-gray-700 mb-1">Hasta:</label>
                        <input type="date" id="end-date" class="border rounded-md p-2" [(ngModel)]="endDate">
                    </div>
                </div>
                <div class="flex space-x-2">
                    <button (click)="applyDateFilter()" class="bg-dagblue text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300">
                        Aplicar Filtro
                    </button>
                    <button (click)="clearDateFilter()" class="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

        <div class="bg-white rounded-lg shadow-md overflow-hidden">
            <table class="w-full">
                <thead class="bg-dagblue text-white">
                    <tr>
                        <th class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Imagen</th>
                        <th class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Nombre</th>
                        <th class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Tipo</th>
                        <th class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Cantidad</th>
                        <th class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Ultimo Restock</th>
                        <th class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Descripción</th>
                        <th class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Precio</th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr *ngIf="!paginatedPacking || paginatedPacking.length === 0">
                        <td colspan="7" class="px-6 py-4 text-center text-gray-500">No hay productos en el almacén</td>
                    </tr>
                    <tr *ngFor="let producto of paginatedPacking">
                        <td class="px-6 py-4 whitespace-nowrap">
                            <img [src]="producto.image" alt="Producto" class="w-16 h-16 rounded-full object-cover">
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap uppercase">{{producto.name}}</td>
                        <td class="px-6 py-4 whitespace-nowrap">{{producto.type}}</td>
                        <td class="px-6 py-4 whitespace-nowrap">{{producto.quantity}}</td>
                        <td class="px-6 py-4 whitespace-nowrap">{{producto.last_restock_date | date:'MMM d, y'}}</td>
                        <td class="px-6 py-4">
                            <p class="text-sm text-gray-900 truncate max-w-xs">{{producto.description}}</p>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">${{producto.price}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
         <!-- Controles de paginación -->
         <div class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div class="flex flex-1 justify-between sm:hidden">
                <button (click)="changePage(currentPage - 1)" [disabled]="currentPage === 1" class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Anterior</button>
                <button (click)="changePage(currentPage + 1)" [disabled]="currentPage === totalPages" class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Siguiente</button>
            </div>
            <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p class="text-sm text-gray-700">
                        Mostrando
                        <span class="font-medium">{{ (currentPage - 1) * pageSize + 1 }}</span>
                        a
                        <span class="font-medium">{{ Math.min(currentPage * pageSize, totalItems) }}</span>
                        de
                        <span class="font-medium">{{ totalItems }}</span>
                        resultados
                    </p>
                </div>
                <div>
                    <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        <button (click)="changePage(1)" [disabled]="currentPage === 1" class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                            <span class="sr-only">Primera</span>
                            <span aria-hidden="true">&laquo;&laquo;</span>
                        </button>
                        <button (click)="changePage(currentPage - 1)" [disabled]="currentPage === 1" class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                            <span class="sr-only">Anterior</span>
                            <span aria-hidden="true">&laquo;</span>
                        </button>
                        <ng-container *ngFor="let page of pageRange()">
                            <button (click)="changePage(page)" 
                                    [class.bg-indigo-600]="currentPage === page" 
                                    [class.text-white]="currentPage === page" 
                                    class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                                {{ page }}
                            </button>
                        </ng-container>
                        <button (click)="changePage(currentPage + 1)" [disabled]="currentPage === totalPages" class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                            <span class="sr-only">Siguiente</span>
                            <span aria-hidden="true">&raquo;</span>
                        </button>
                        <button (click)="changePage(totalPages)" [disabled]="currentPage === totalPages" class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                            <span class="sr-only">Última</span>
                            <span aria-hidden="true">&raquo;&raquo;</span>
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ... (código anterior) ... -->

<!-- Modal para solicitar stock -->
<div *ngIf="showRequestStockModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
    <div class="bg-white p-6 rounded-lg shadow-xl w-full max-w-2xl">
      <h2 class="text-2xl font-bold mb-4">Solicitar Reabastecimiento</h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4 max-h-96 overflow-y-auto">
        <div *ngFor="let product of allAvailablePacking" 
             (click)="selectedProduct = product._id"
             [ngClass]="{'border-blue-500 border-2': selectedProduct === product._id}"
             class="border rounded-lg p-4 cursor-pointer hover:shadow-md transition duration-300">
          <img [src]="'data:image/jpeg;base64,' + product.image" [alt]="product.name" class="w-full h-32 object-cover mb-2 rounded">
          <h3 class="font-semibold">{{product.name}}</h3>
          <p class="text-sm text-gray-600">{{product.type}}</p>
        </div>
      </div>
      <div class="mb-4">
        <label for="quantity" class="block text-sm font-medium text-gray-700 mb-1">Cantidad</label>
        <input type="number" id="quantity" [(ngModel)]="requestQuantity" name="quantity" min="1" 
               class="w-full p-2 border rounded-md">
      </div>
      <div class="flex justify-end space-x-2">
        <button (click)="closeRequestStockModal()" 
                class="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 transition duration-300">
          Cancelar
        </button>
        <button (click)="submitStockRequest()" 
                [disabled]="!selectedProduct || requestQuantity < 1"
                class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed">
          Solicitar
        </button>
      </div>
    </div>
  </div>